import React from 'react'
import {  listScheduledEvents, getScheduledEvent  } from '../graphql/queries'
import {  updateScheduledEvent, deleteScheduledEvent  } from '../graphql/mutations'

import * as subscriptions from '../graphql/subscriptions';

import { API, graphqlOperation } from 'aws-amplify'
import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Collapse,
    ListGroupItem,
    ListGroup,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
  } from "reactstrap";
// import ListPipelines from './listPipelines'
import classnames from "classnames";


class ScheduleList extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            openedCollapses: [""],
            ListSchedulesdata: [],
            selectedSchedule: {},
            selectedScheduleIndex: -1,
            schedulemodal: false,
            deletemodal: false,
            ActivateModalButton: '',
            scheduleTemplateModal: '',
        }
        this.displayList()

        const onCreateScheduledEvent = API.graphql(
            graphqlOperation(subscriptions.onCreateScheduledEvent)
        ).subscribe({
            next: ({ provider, value }) => {
                this.displayList()
                console.log({ provider, value })},
            error: error => console.warn(error)
        });

        const onUpdateScheduledEvent = API.graphql(
            graphqlOperation(subscriptions.onUpdateScheduledEvent)
        ).subscribe({
            next: ({ provider, value }) => {
                this.updateSelectedScheduleFromSub()
                console.log({ provider, value })},
            error: error => console.warn(error)
        });
        const onDeleteScheduledEvent = API.graphql(
            graphqlOperation(subscriptions.onDeleteScheduledEvent)
        ).subscribe({
            next: ({ provider, value }) => {
                this.displayList()
                console.log({ provider, value })},
            error: error => console.warn(error)
        });


    }

    setInput(key, value) {
        this.setState([key], value)
    }

    componentDidMount() {
        this.displayList();
    }



    isCurrent(value){
        if(value){
            return('true')
        } else {
            return('false')
        }
    }

    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: []
            });
        } else {
            this.setState({
                openedCollapses: [collapse]
            });
        }
    };

    displayList = async() => {
        try {
            let Data = await API.graphql(graphqlOperation(listScheduledEvents));

            let Items = Data.data.listScheduledEvents.items;
            this.setState({ rawSchedules: Items})
            console.log(Items)
            this.SetListSchedulesData()
        } catch (err) {
            console.log(err);
        }
    }

    SetListSchedulesData = () =>{
        this.setState({ListSchedulesdata: this.state.rawSchedules.map((ct, index) => (
                    <tr key={ct.id ? ct.id : index}
                        aria-selected={this.state.selectedScheduleIndex === index}
                        className={classnames("table-hover mb-sm-3 mb-md-0", {
                            active: this.state.selectedScheduleIndex === index
                        })}
                        // onClick={e => this.selectSchedule(ct, index)}
                        role="tab">
                        <td>{ct.name}</td>
                        <td>{ct.primaryType}</td>
                        <td><Button className="btn btn-outline-info" onClick = {e => this.setSelected(ct, index)} >Details</Button></td>
                    </tr>
                ))
            }
        )
    }

    OpenScheduleModal = () => {
        this.setState({schedulemodal: true});
        // this.setState({ displayDeleteButton: (<><Button className="btn btn-outline-danger" onClick={ e => this.openDeleteModal(  ) }>Delete</Button></>)})
    }

    CloseScheduleModal = () => {
        // console.log('Close Modal')
        // this.getModal(false);
        this.setState({schedulemodal: false});
    }

    setSelected = (schedule, index) => {
        console.log({schedule})
        // console.log({index})
        this.setState({selectedSchedule: schedule});
        this.setState({selectedScheduleIndex: index});
        this.activateScheduleButton(schedule)
        this.updateScheduleModalContent(schedule)
        this.OpenScheduleModal()
        // this.displayList()
    }

    updateSelectedScheduleFromSub = async() => {
        const selectedSchedule = this.state.selectedSchedule
        let scheduledEventsDataresp = await API.graphql({ query: getScheduledEvent, variables: { id: selectedSchedule.id }})
        const schedule = scheduledEventsDataresp.data.getScheduledEvent
        this.setState({ selectedSchedule: schedule })
        console.log(schedule)
        this.setSelected(schedule, this.state.selectedScheduleIndex)
    }


    updateScheduleModalContent = (schedule) => {
        this.setState({ scheduleTemplateModal: (<>
            <Row>    
                <ul>
                    <li>Name: {schedule.name}</li>
                    <li>Created At: {schedule.createdAt}</li>
                    <li>Status: {schedule.status}</li>
                    <li>Arguments: {schedule.arguments}</li>
                    <li>primaryType: {schedule.primaryType}</li>
                    <li>primaryTypeArr: {schedule.primaryTypeArr}</li>
                    <li>secondaryType: {schedule.secondaryType}</li>
                    <li>secondaryTypeArr: {schedule.secondaryTypeArr}</li>
                    <li>tertiaryType: {schedule.tertiaryType}</li>
                    <li>tertiaryTypeArr: {schedule.tertiaryTypeArr}</li>
                    <li>nextRuntime: {schedule.nextRuntime}</li>
                    <li>lastRuntime: {schedule.lastRuntime}</li>
                    <li>executiontemplateid: {schedule.executiontemplateid}</li>
                    <li>executionType: {schedule.executionType}</li>
                </ul>
            </Row>
        </>)})
    }


    activateScheduleButton = (schedule) => {
        // console.log({schedule})
        if(schedule.status === 'Paused'){
            this.setState({ ActivateModalButton: <><Button className="btn btn-outline-success" onClick={e => this.toggleScheduleActivation(schedule)} >Activate</Button></>})
        } else if(schedule.status === 'Active'){
            this.setState({ ActivateModalButton: <><Button className="btn btn-outline-primary" onClick={e => this.toggleScheduleActivation(schedule)} >Pause</Button></>})
        } else if(schedule.status === 'Completed'){
            this.setState({ ActivateModalButton: <></>})
        }    
        this.updateScheduleModalContent(schedule)
    }

    toggleScheduleActivation = async(schedule) => {
        let tmpstatus = schedule.status
        if(schedule.status === 'Paused'){
            tmpstatus = 'Active'        
            const values = {
                id: schedule['id'],
                status: tmpstatus
            }
            console.log('Submitted Values', values)
            const response = await API.graphql(graphqlOperation(updateScheduledEvent, {input: values}))
        } else if(schedule.status === 'Active'){
            tmpstatus = 'Paused'
            const values = {
                id: schedule['id'],
                status: tmpstatus
            }
            console.log('Submitted Values', values)
            const response = await API.graphql(graphqlOperation(updateScheduledEvent, {input: values}))
        }
        this.activateScheduleButton(schedule)
        
    }

    
    openDeleteModal = () => {
        this.setState({deletemodal: true});
    }

    cancelDeleteButton = () => {
        this.setState({deletemodal: false});
    }

    submitDeleteToGQL = async() => {
        this.setState({deletemodal: false});
        this.setState({schedulemodal: false});
        const values = {
            id: this.state.selectedSchedule['id'],
        }
        console.log('Submitted Values', values)
        await API.graphql(graphqlOperation(deleteScheduledEvent, {input: values}))
    }


    render  () {
        return( 
            
            <Card>
                <CardHeader  role="tab"
                onClick={() => this.collapsesToggle("listPipelines")}
                aria-expanded={this.state.openedCollapses.includes("listPipelines")}>
                    
                    <Row>
                        <Col className='col-10'>
                            <CardTitle className="h2 mb-0">Event Schedules</CardTitle>
                        </Col>
                        <Col className='col-2'>
                            <span className="custom-toggle">
                                <input type="checkbox"  checked={this.state.openedCollapses.includes("listPipelines")} />
                                <span className="custom-toggle-slider rounded-circle"  />
                            </span>
                        </Col>
                    </Row>
                </CardHeader>
                <Modal isOpen={this.state.deletemodal}>
                    <ModalHeader close={<button className="close" onClick={e => this.cancelDeleteButton()}>×</button>}>Confirm Delete</ModalHeader>
                    <ModalBody>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onDoubleClick={e => this.submitDeleteToGQL()}>Delete</Button>{' '}
                        <Button color="secondary" onClick={ e => this.cancelDeleteButton(  ) }>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Modal backdrop="static" isOpen={this.state.schedulemodal} className="modal-xl modal-dialog">
                    <ModalHeader close={<button className="close" onClick={e => this.CloseScheduleModal()}>×</button>}>Schedule Details</ModalHeader>                    <ModalBody>
                        {this.state.scheduleTemplateModal}
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn btn-outline-danger" onClick={ e => this.openDeleteModal(  ) }>Delete</Button>
                        {this.state.ActivateModalButton}
                        <Button color="secondary" onClick={ e => this.CloseScheduleModal(  ) }>Close</Button>
                    </ModalFooter>
                </Modal>
                <Collapse role="tabpanel" isOpen={this.state.openedCollapses.includes("listPipelines")}>
                    <CardBody>
                        <ListGroup>
                            <Row>
                                <Col className=''>
                                    <Table className="align-items-center" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Primary Type</th>
                                                <th scope="col">Info</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {this.state.ListSchedulesdata}

                                        </tbody>
                                    </Table>
                                </Col>
                                <Col className='col-8'>
                                    {this.state.name}
                                </Col>
                            </Row>
                        </ListGroup>
                    </CardBody>
                </Collapse>
            </Card>
        
        )
    }
};

// const mapStateToProps = state => {
//     return {
//         currentQueryID: state.selectedQueryID,


//         // UsersQuerys: state.UsersQuerys, // replacing reqs 
//     };
// }


// export { ListQueryRunHistory };

// export default withAuthenticator(connect(mapStateToProps)(Admin));

export default ScheduleList