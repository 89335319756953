/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getComputeType = /* GraphQL */ `
  query GetComputeType($id: ID!) {
    getComputeType(id: $id) {
      id
      name
      type
      computeResource
      computeSize
      sqsResource
      snsResource
      tags
      createdAt
      updatedAt
    }
  }
`;
export const listComputeTypes = /* GraphQL */ `
  query ListComputeTypes(
    $filter: ModelComputeTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComputeTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        computeResource
        computeSize
        sqsResource
        snsResource
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const computeTypeByType = /* GraphQL */ `
  query ComputeTypeByType(
    $type: String
    $sortDirection: ModelSortDirection
    $filter: ModelComputeTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    computeTypeByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        computeResource
        computeSize
        sqsResource
        snsResource
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getComputeTemplate = /* GraphQL */ `
  query GetComputeTemplate($id: ID!) {
    getComputeTemplate(id: $id) {
      id
      name
      computeType
      s3key
      arguments
      maxRunTime
      type
      tags
      createdAt
      updatedAt
    }
  }
`;
export const listComputeTemplates = /* GraphQL */ `
  query ListComputeTemplates(
    $filter: ModelComputeTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComputeTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        computeType
        s3key
        arguments
        maxRunTime
        type
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const computeTemplateByType = /* GraphQL */ `
  query ComputeTemplateByType(
    $type: String
    $sortDirection: ModelSortDirection
    $filter: ModelComputeTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    computeTemplateByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        computeType
        s3key
        arguments
        maxRunTime
        type
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getComputeExecution = /* GraphQL */ `
  query GetComputeExecution($id: ID!) {
    getComputeExecution(id: $id) {
      id
      computeTemplate
      status
      type
      arguments
      parentType
      parentExId
      taskStr
      logfile
      tags
      createdAt
      updatedAt
    }
  }
`;
export const listComputeExecutions = /* GraphQL */ `
  query ListComputeExecutions(
    $filter: ModelComputeExecutionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComputeExecutions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        computeTemplate
        status
        type
        arguments
        parentType
        parentExId
        taskStr
        logfile
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const execByParentType = /* GraphQL */ `
  query ExecByParentType(
    $parentType: String
    $sortDirection: ModelSortDirection
    $filter: ModelComputeExecutionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    execByParentType(
      parentType: $parentType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        computeTemplate
        status
        type
        arguments
        parentType
        parentExId
        taskStr
        logfile
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const execByStatus = /* GraphQL */ `
  query ExecByStatus(
    $status: String
    $sortDirection: ModelSortDirection
    $filter: ModelComputeExecutionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    execByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        computeTemplate
        status
        type
        arguments
        parentType
        parentExId
        taskStr
        logfile
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const execByTemplate = /* GraphQL */ `
  query ExecByTemplate(
    $computeTemplate: String
    $sortDirection: ModelSortDirection
    $filter: ModelComputeExecutionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    execByTemplate(
      computeTemplate: $computeTemplate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        computeTemplate
        status
        type
        arguments
        parentType
        parentExId
        taskStr
        logfile
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      subjectTable
      subjectid
      docType
      status
      title
      content
      tags
      createdAt
      updatedAt
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelpostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        subjectTable
        subjectid
        docType
        status
        title
        content
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const postByDoctype = /* GraphQL */ `
  query PostByDoctype(
    $docType: String
    $sortDirection: ModelSortDirection
    $filter: ModelpostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postByDoctype(
      docType: $docType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subjectTable
        subjectid
        docType
        status
        title
        content
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const postByTable = /* GraphQL */ `
  query PostByTable(
    $subjectTable: String
    $sortDirection: ModelSortDirection
    $filter: ModelpostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postByTable(
      subjectTable: $subjectTable
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subjectTable
        subjectid
        docType
        status
        title
        content
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const postByStatus = /* GraphQL */ `
  query PostByStatus(
    $status: String
    $sortDirection: ModelSortDirection
    $filter: ModelpostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subjectTable
        subjectid
        docType
        status
        title
        content
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getResource = /* GraphQL */ `
  query GetResource($id: ID!) {
    getResource(id: $id) {
      id
      name
      status
      computeTypeid
      type
      subtype
      sqsurl
      sqsmaxAttempts
      sqsVisibilityTimeout
      sqsdeadLetterurl
      userData
      arn
      tags
      createdAt
      updatedAt
    }
  }
`;
export const listResources = /* GraphQL */ `
  query ListResources(
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResources(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        computeTypeid
        type
        subtype
        sqsurl
        sqsmaxAttempts
        sqsVisibilityTimeout
        sqsdeadLetterurl
        userData
        arn
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const resourceByType = /* GraphQL */ `
  query ResourceByType(
    $type: String
    $sortDirection: ModelSortDirection
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourceByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        computeTypeid
        type
        subtype
        sqsurl
        sqsmaxAttempts
        sqsVisibilityTimeout
        sqsdeadLetterurl
        userData
        arn
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const resourceByStatus = /* GraphQL */ `
  query ResourceByStatus(
    $status: String
    $sortDirection: ModelSortDirection
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourceByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        computeTypeid
        type
        subtype
        sqsurl
        sqsmaxAttempts
        sqsVisibilityTimeout
        sqsdeadLetterurl
        userData
        arn
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPipeline = /* GraphQL */ `
  query GetPipeline($id: ID!) {
    getPipeline(id: $id) {
      id
      name
      status
      tasks
      arguments
      tags
      createdAt
      updatedAt
    }
  }
`;
export const listPipelines = /* GraphQL */ `
  query ListPipelines(
    $filter: ModelpipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPipelines(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        tasks
        arguments
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const pipelineByStatus = /* GraphQL */ `
  query PipelineByStatus(
    $status: String
    $sortDirection: ModelSortDirection
    $filter: ModelpipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pipelineByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        tasks
        arguments
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPipelineExecution = /* GraphQL */ `
  query GetPipelineExecution($id: ID!) {
    getPipelineExecution(id: $id) {
      id
      pipelineid
      name
      status
      parentType
      parentExId
      currentTask
      arguments
      taskStatus
      tags
      createdAt
      updatedAt
    }
  }
`;
export const listPipelineExecutions = /* GraphQL */ `
  query ListPipelineExecutions(
    $filter: ModelpipelineExecutionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPipelineExecutions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pipelineid
        name
        status
        parentType
        parentExId
        currentTask
        arguments
        taskStatus
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const pipelineExecutionByStatus = /* GraphQL */ `
  query PipelineExecutionByStatus(
    $status: String
    $sortDirection: ModelSortDirection
    $filter: ModelpipelineExecutionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pipelineExecutionByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pipelineid
        name
        status
        parentType
        parentExId
        currentTask
        arguments
        taskStatus
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPipelineExecutionHistorical = /* GraphQL */ `
  query GetPipelineExecutionHistorical($id: ID!) {
    getPipelineExecutionHistorical(id: $id) {
      id
      pipelineExid
      pipelineid
      name
      status
      parentType
      parentExId
      currentTask
      arguments
      taskStatus
      tags
      createdAt
      updatedAt
    }
  }
`;
export const listPipelineExecutionHistoricals = /* GraphQL */ `
  query ListPipelineExecutionHistoricals(
    $filter: ModelpipelineExecutionHistoricalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPipelineExecutionHistoricals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pipelineExid
        pipelineid
        name
        status
        parentType
        parentExId
        currentTask
        arguments
        taskStatus
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const pipelineExecutionHistById = /* GraphQL */ `
  query PipelineExecutionHistById(
    $pipelineExid: String
    $sortDirection: ModelSortDirection
    $filter: ModelpipelineExecutionHistoricalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pipelineExecutionHistById(
      pipelineExid: $pipelineExid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pipelineExid
        pipelineid
        name
        status
        parentType
        parentExId
        currentTask
        arguments
        taskStatus
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const pipelineExecutionHistByStatus = /* GraphQL */ `
  query PipelineExecutionHistByStatus(
    $status: String
    $sortDirection: ModelSortDirection
    $filter: ModelpipelineExecutionHistoricalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pipelineExecutionHistByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pipelineExid
        pipelineid
        name
        status
        parentType
        parentExId
        currentTask
        arguments
        taskStatus
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getScheduledEvent = /* GraphQL */ `
  query GetScheduledEvent($id: ID!) {
    getScheduledEvent(id: $id) {
      id
      name
      status
      primaryType
      primaryTypeArr
      secondaryType
      secondaryTypeArr
      tertiaryType
      tertiaryTypeArr
      executionType
      executiontemplateid
      arguments
      lastRuntime
      nextRuntime
      tags
      createdAt
      updatedAt
    }
  }
`;
export const listScheduledEvents = /* GraphQL */ `
  query ListScheduledEvents(
    $filter: ModelscheduledEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScheduledEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        primaryType
        primaryTypeArr
        secondaryType
        secondaryTypeArr
        tertiaryType
        tertiaryTypeArr
        executionType
        executiontemplateid
        arguments
        lastRuntime
        nextRuntime
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
