import React from 'react'
import Amplify, { Auth } from 'aws-amplify'
import { withAuthenticator } from '@aws-amplify/ui-react'


import 'aws-amplify'


// import { UserNavbar } from './nav'
import { NewComputeType } from './computeType'
import { NewComputeTemplate, ListComputeTemplates, ComputeTemplatesCollapse } from './computeTemplates'
import { ListComputeExecutions } from './computeExecutions'
import { SQSReactor } from './sqsReactor'
import { SNSReactor } from './snsReactor'
import { ComputeResource } from './computeResources'
import { NewSchedule, ScheduleList } from './scheduler'
import { NewPipeline, PipelineList, PipelineExecutions} from './pipelines'

import awsmobile from "./aws-exports";

import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Card,
  Collapse,
  NavbarToggler
} from "reactstrap";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

Amplify.configure(awsmobile);




function Home() {
  return(<>
    <Row className='no-gutters'>
      <Col  className='col-4 d-none d-xs-none d-sm-none d-md-none d-lg-block p-2'>
        <NewComputeTemplate />
        <ComputeTemplatesCollapse />
      </Col>
      <Col className='col-8 d-none d-xs-none d-sm-none d-md-none d-lg-block p-2'>
        <ListComputeExecutions />
      </Col>
      <Col  className='d-lg-none d-xl-none p-2'>
        {/* <NewComputeTemplate /> */}
        <ComputeTemplatesCollapse />
        <ListComputeExecutions />
      </Col>
    </Row>
  </>);
}

function Pipelines() {
  return(<>
    <Row className='no-gutters'>
      <Col className='d-none d-sm-block p-2'>
        <NewPipeline />
        <PipelineList />
        <PipelineExecutions />
      </Col>
      <Col className='d-block d-sm-none p-2'>
        {/* <NewPipeline /> */}
        <PipelineList />
        <PipelineExecutions />
      </Col>
    </Row>
  </>);
}

function Configuration() {
  return(<>
    <Row className='no-gutters'>
      <Col className='col-4 p-2'>
        <NewComputeType />
      </Col>
      <Col className='col-8 p-2'>
        <SQSReactor />
        <SNSReactor />
        <ComputeResource />
      </Col>
    </Row>
    </>);
}

function Schedules() {
  return(<>
    <Row className='no-gutters'>
      <Col className='d-none d-sm-block p-2'>
        <NewSchedule />
        <ScheduleList />
      </Col>
      <Col className='d-block d-sm-none p-2'>
        {/* <NewSchedule /> */}
        <ScheduleList />
      </Col>
    </Row>
    </>);
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      current: false,
      currentLoggedInUserGroups: [],
      selectedQuery: [],
      queryBuilder: '',
      admin: '',
      CustomRegionCreator: '',
      navs: '',
      isOpen: false

    }
    this.getUserGroups();
    this.toggle = this.toggle.bind(this);

  }

  logOut(e) {
    e.preventDefault()
    Auth.signOut()
      .then(data => console.log(data))
      .catch(err => console.log(err));
      window.location.reload(false);
  }

  getUserGroups = async() => {
    const token = await Auth.currentAuthenticatedUser()
    // const username = token.username
    // this.setState(currentLoggedInUser: username)

    const groups = token['signInUserSession']['accessToken']['payload']['cognito:groups']
    this.setState({currentLoggedInUserGroups: groups})
    if( groups.includes('Admins')){
      this.setState({ navs: (
        <>
          <NavItem className='d-none d-xs-none d-sm-none d-md-none d-lg-block'>
            <Link className="nav-link nav-link-icon" to="/"><i className="fas fa-home" /></Link>
          </NavItem>
          <NavItem className='d-none d-xs-none d-sm-none d-md-none d-lg-block'>
            <Link className="nav-link nav-link-icon" to="/Pipelines"><i className="fas fa-project-diagram" /></Link>
          </NavItem>
          <NavItem className='d-none d-xs-none d-sm-none d-md-none d-lg-block'>
            <Link className="nav-link nav-link-icon" to="/Schedules"><i class="far fa-clock"></i></Link>
          </NavItem>
          <NavItem className='d-none d-xs-none d-sm-none d-md-none d-lg-block'>
            <Link className="nav-link nav-link-icon" to="/Configuration"><i className="fas fa-cogs" /></Link>
          </NavItem>
          <NavItem className='d-none d-xs-none d-sm-none d-md-none d-lg-block'>
            <NavLink
              className="nav-link-icon"
              onClick={e => this.logOut(e)}
            >
              <i className="fas fa-sign-out-alt"></i>
            </NavLink>
          </NavItem>


          <NavItem className='d-lg-none d-xl-none'>
            <Link className="nav-link nav-link-icon" to="/" onClick={e => this.toggle()}><i className="fas fa-home" /> Home</Link>
          </NavItem>
          <NavItem className='d-lg-none d-xl-none'>
            <Link className="nav-link nav-link-icon" to="/Pipelines" onClick={ e => this.toggle()}><i className="fas fa-project-diagram" /> Pipelines</Link>
          </NavItem>
          <NavItem className='d-lg-none d-xl-none'>
            <Link className="nav-link nav-link-icon" to="/Schedules" onClick={ e => this.toggle()}><i className="far fa-clock" /> Schedules</Link>
          </NavItem>
          {/* <NavItem className='d-lg-none d-xl-none'>
            <Link className="nav-link nav-link-icon" to="/Schedules" onClick={ e => this.toggle()}><i className="fas fa-project-diagram" /> Schedules</Link>
          </NavItem> */}
          {/* <NavItem className='d-lg-none d-xl-none'>
            <Link className="nav-link nav-link-icon" to="/Configuration"><i className="fas fa-cogs" /> Settings</Link>
          </NavItem> */}
          <NavItem className='d-lg-none d-xl-none'>
            <NavLink
              className="nav-link-icon"
              onClick={e => this.logOut(e)}
            >
              <i className="fas fa-sign-out-alt"> Logout</i>
            </NavLink>
          </NavItem>
        </>
      )  })
    }
    else{
      // this.setState(queryBuilder, "")
      this.setState({ navs: (
        <>
          <NavItem className='d-none d-sm-block'>
            <Link className="nav-link nav-link-icon" to="/"><i className="fas fa-home" /></Link>
          </NavItem>
          <NavItem className='d-none d-sm-block'>
            <NavLink
              className="nav-link-icon"
              onClick={e => this.logOut(e)}
            >
              <i className="fas fa-sign-out-alt"></i>
            </NavLink>
          </NavItem>

          <NavItem className='d-block d-sm-none'>
            <Link className="nav-link nav-link-icon" to="/"><i className="fas fa-home" /> Home</Link>
          </NavItem>
          <NavItem className='d-block d-sm-none'>
            <NavLink
              className="nav-link-icon"
              onClick={e => this.logOut(e)}
            >
              <i className="fas fa-sign-out-alt"> Logout</i>
            </NavLink>
          </NavItem>
        </>
      )})
    }
    
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }



  render(){
  return (<>
    <Router>
      <Navbar className="navbar-horizontal navbar-dark bg-default" expand="lg">
          <Container>
            <NavbarBrand href="/">
              PackMoose
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar toggler="#navbar-default">
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    
                  </Col>
                  <Col className="collapse-close" xs="6">
                  <button  onClick={this.toggle} className="navbar-toggler">
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className="ml-lg-auto" navbar>
                {this.state.navs}
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
        <Switch>
          <Route path="/Pipelines">
            <Pipelines />
          </Route>
          <Route path="/Schedules">
            <Schedules />
          </Route>
          <Route path="/Configuration">
            <Configuration />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
      {/* {this.state.page} */}
    </>
  );
}
}

export default withAuthenticator(App);
