import React from 'react'
// import ReactDOM from 'react-dom';
import { API, graphqlOperation } from 'aws-amplify'
// import { connect } from 'react-redux'
// import classnames from "classnames";
// import { onCreateReqHistory } from './../../graphql/subscriptions'
import classnames from "classnames";
import { connect } from 'react-redux'

import { createPipeline, updatePipeline } from '../graphql/mutations'
import { listPipelines, listComputeTemplates } from '../graphql/queries'
import { 
    ListGroup,
    ListGroupItem,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Container,
    Button,
    Input,
    Col,
    Table,
    Modal,
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    UncontrolledButtonDropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem
} from "reactstrap";


class EditPipelineForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            ctNavPills: -1,
            name: '',
            listTaskLists: '',
            selectedTemplate: '',
            listDataArray: [],
            newTaskList: '',
            pipeline: [],
            taskData: [],
            modal: false,
            arguments: '',
            selectedNewTask: -1,
            type: '',
            SelectedType: 'Select Compute Template',
            SelectTypeComponent: '',
            SelectedTaskType: 'Select Task Type',

        }
    }

    handleNameChange = event => {
        this.setState({name: event.target.value})
        // console.log(event.target.value)
    }

    onButtonClick = async(pipeline) => {
        console.log({pipeline})
        let phaseTasks = []
        pipeline.forEach((tasklist, index) => {
            const phasenum = index
            let pipelineTasks = []
            tasklist.forEach( (task, index) => {
                console.log({task})
                task['phasenum'] = phasenum
                task['taskNum'] = index
                pipelineTasks.push(JSON.stringify(task))
            })
            phaseTasks.push(pipelineTasks)
        });
        console.log({phaseTasks})
        const updatedpipelinevalues = {
            id: this.props.pipeline['selectedPipeline']['id'],
            name: this.state.name,
            tasks: phaseTasks,
            // type: this.state.type
        }
        const updatedpipelinevaluesresponse = await API.graphql(graphqlOperation(updatePipeline, {input: updatedpipelinevalues}));
        console.log(updatedpipelinevaluesresponse)
    }



    loadPipeline = () => {
        // const ogpipeline = this.props.pipeline
        // console.log({ogpipeline})

        const ogphases = this.props.pipeline['selectedPipeline']['tasks']
        this.setState({name: this.props.pipeline['selectedPipeline']['name'] })
        // console.log({ogphases})
        let phases = []
        ogphases.map(phase => {
            // console.log({phase})
            let newphase = []
            phase.map(task => {
                newphase.push(JSON.parse(task))
            })
            phases.push(newphase)
        })
        console.log({phases})

        this.setState({ NewPipeline: phases.map((ct, index) => (
            <Row key={ct.id ? ct.id : index} aria-selected={this.state.ctNavPills === index} className={"mb-sm-3 mb-md-0"} role="tab" >
                <Col>
                    <Row>
                        <Col>
                        <h3>Task: {index}</h3> 
                        </Col>
                        <Col xs="1">
                            <Button className="btn btn-sm btn-outline-danger" onClick={ e => this.editPhase(ct, index ) }><i class="fas fa-angle-double-left"></i></Button>
                        </Col>
                        <Col xs="1">
                            <Button className="btn btn-sm btn-outline-danger" onClick={ e => this.removePipelineTask( index ) }><i class="fas fa-times"></i></Button>
                        </Col>
                        <Col xs="1">
                            <Button className="btn btn-sm btn-outline-success" onClick={ e => this.moveUpPipelineRankTask( index ) }><i class="fas fa-angle-double-up"></i></Button>
                        </Col>
                        <Col xs="1">
                            <Button className="btn btn-sm btn-outline-success" onClick={ e => this.moveDownPipelineRankTask( index ) }><i class="fas fa-angle-double-down"></i></Button>
                        </Col>
                    </Row>
                    <Row>
                        <Table>
                            <thead>
                                <tr>
                                <th scope="col">name</th>
                                {/* <th scope="col">arguments</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {this.displayPipelineTaskItems(ct)}
                            </tbody>
                            {/* {ct} */}
                        </Table>
                    </Row>
                </Col>
            </Row>
            ))
        });
        this.setState({ pipeline: phases})

    }

    componentDidMount() {
        this.loadPipeline()

        // this.displayTemplateList()
        // this.displayNewTaskList()
    }
    updateTaskType = (name) => {
        this.setState({SelectedTaskType: name})
        console.log(name)
        if(name == 'ComputeTemplate'){
            this.setState({SelectedType: 'Select Compute Template'})
            this.displayTemplateList()
        } else if (name == 'Pipeline'){
            this.setState({SelectedType: 'Select Pipeline'})
            this.displaypipelineList()
        }
    }



    displayTypeTemplates = (type, SelectedTaskType) => {
        this.setState({SelectedType: type })
        // this.displayTemplateList()
        // this.displaypipelineList()
        if(SelectedTaskType == 'ComputeTemplate'){
            this.displayTemplateList()
        } else if(SelectedTaskType == 'Pipeline'){
            this.displaypipelineList()
        }
    }

    selectType = (types) => {
        this.setState({ typeSelect: types.map((name, index) => (
                <DropdownItem className={classnames("dropdown-item" , { active: this.state.SelectedType === '' })} onClick={(e) => this.displayTypeTemplates(name, this.state.SelectedTaskType)} >
                        {name}
                </DropdownItem>
        ))})}

    displayTemplateList = async() => {
        try {
            let CTData = await API.graphql({ query: listComputeTemplates, variables: { limit: 100,  }});
            let CTItems = CTData.data.listComputeTemplates.items;
            let CTdata = CTData.data.listComputeTemplates
            while(CTdata.nextToken != null ){
                let nextToken = CTdata.nextToken;
                let CTData2 = await API.graphql({ query: listComputeTemplates, variables: { limit: 100, nextToken }});
                CTdata = {}
                CTdata = CTData2.data.listComputeTemplates
                CTItems = CTItems.concat(CTdata.items)
            }
            const types = ['All', 'None', ...new Set(CTItems.map(item => item.type))];
            if (this.state.SelectedType == 'None' | this.state.SelectedType == 'Select Compute Template'){
                this.setState({ ComputeTemplates: []})
            } 
            if (this.state.SelectedType == 'All'){
                this.setState({ ComputeTemplates: [ ...CTItems.sort( function (a,b){ return new Date(b.createdAt) - new Date(a.createdAt)})]})
            } else {
                this.setState({ ComputeTemplates: [ ...CTItems.filter( CTItems => CTItems.type === this.state.SelectedType )].sort( function (a,b){ return new Date(b.createdAt) - new Date(a.createdAt)})})
            }
            this.selectType(types)
            this.setState({ SelectTypeComponent: (
                <UncontrolledButtonDropdown>
                    <DropdownToggle color="primary" caret>
                        {this.state.SelectedType }
                    </DropdownToggle>
                    <DropdownMenu>
                        {this.state.typeSelect}
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
                )})
            if (this.state.SelectedType != 'All'){
                this.setState({ ComputeTemplates: [ ...CTItems.filter( CTItems => CTItems.type === this.state.SelectedType )].sort( function (a,b){ return new Date(b.createdAt) - new Date(a.createdAt)})})
            } else {
                this.setState({ ComputeTemplates: [ ...CTItems.sort( function (a,b){ return new Date(b.createdAt) - new Date(a.createdAt)})]})
            }
            let CTs = [ ...this.state.ComputeTemplates ]
            console.log({CTs})
            this.setState({ listTaskLists: CTs.map((ct, index) => (
                <ListGroupItem key={ct.id ? ct.id : index}
                    aria-selected={this.state.ctNavPills === index}
                    className={classnames("mb-sm-3 mb-md-0", {
                        active: this.state.ctNavPills === index
                    })}
                    onClick={e => this.setSelected(ct, index, this.state.SelectedTaskType)}
                    role="tab">
                    {ct.name}
                </ListGroupItem>
            ))
            });
        } catch (err) {
            console.log(err);
        }
    }


    displaypipelineList = async() => {
        try {
            let CTData = await API.graphql({ query: listPipelines, variables: { limit: 100,  }});
            let CTItems = CTData.data.listPipelines.items;
            let CTdata = CTData.data.listPipelines
            while(CTdata.nextToken != null ){
                let nextToken = CTdata.nextToken;
                let CTData2 = await API.graphql({ query: listPipelines, variables: { limit: 100, nextToken }});
                CTdata = {}
                CTdata = CTData2.data.listPipelines
                CTItems = CTItems.concat(CTdata.items)
            }
            const types = ['All', 'None', ...new Set(CTItems.map(item => item.type))];
            if (this.state.SelectedType == 'None' | this.state.SelectedPipelineType == 'Select Pipeline'){
                this.setState({ ComputeTemplates: []})
            } 
            if (this.state.SelectedType == 'All'){
                this.setState({ ComputeTemplates: [ ...CTItems.sort( function (a,b){ return new Date(b.createdAt) - new Date(a.createdAt)})]})
            } else {
                this.setState({ ComputeTemplates: [ ...CTItems.filter( CTItems => CTItems.type === this.state.SelectedPipelineType )].sort( function (a,b){ return new Date(b.createdAt) - new Date(a.createdAt)})})
            }
            this.selectType(types)
            this.setState({ SelectTypeComponent: (
                <UncontrolledButtonDropdown>
                    <DropdownToggle color="primary" caret>
                        {this.state.SelectedType }
                    </DropdownToggle>
                    <DropdownMenu>
                        {this.state.typeSelect}
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
                )})
            if (this.state.SelectedType != 'All'){
                this.setState({ ComputeTemplates: [ ...CTItems.filter( CTItems => CTItems.type === this.state.SelectedPipelineType )].sort( function (a,b){ return new Date(b.createdAt) - new Date(a.createdAt)})})
            } else {
                this.setState({ ComputeTemplates: [ ...CTItems.sort( function (a,b){ return new Date(b.createdAt) - new Date(a.createdAt)})]})
            }
            let CTs = [ ...this.state.ComputeTemplates ]
            console.log({CTs})
            this.setState({ listTaskLists: CTs.map((ct, index) => (
                <ListGroupItem key={ct.id ? ct.id : index}
                    aria-selected={this.state.ctNavPills === index}
                    className={classnames("mb-sm-3 mb-md-0", {
                        active: this.state.ctNavPills === index
                    })}
                    onClick={e => this.setSelected(ct, index, this.state.SelectedTaskType)}
                    role="tab">
                    {ct.name}
                </ListGroupItem>
            ))
            });
        } catch (err) {
            console.log(err);
        }
    }

    setSelected = (req, index, SelectedTaskType) => {
        console.log(req)
        // console.log(index)
        this.setState({ctNavPills: index});
        // this.displayTemplateList()
        if(SelectedTaskType == 'ComputeTemplate'){
            this.displayTemplateList()
        } else if(SelectedTaskType == 'Pipeline'){
            this.displaypipelineList()
        }
        // this.getDataVersion()
        this.setState({selectedTemplate: req})
    }

    addSelectedTemplateToList = () => {
        let template = this.state.selectedTemplate
        if (template != ''){
            let tmpDataarray = this.state.listDataArray
            console.log({tmpDataarray})
            // console.log(tmpDataarray.length)
            // console.log({template})
            // const templateid = template['id']
            template['taskid'] = template['id']
            template['taskType'] = this.state.SelectedTaskType
            // console.log({templateid})
            console.log({template})
            console.log(tmpDataarray.length)
            // template['taskid'] = templateid
            template['id'] = tmpDataarray.length
            tmpDataarray.push(template)
            this.setState({listDataArray: tmpDataarray})
            this.setState({selectedTemplate: ''})
            // this.displayTemplateList()
            this.displayNewTaskList()
        }
    }

    displayNewTaskList = () => {
        this.setState({ newTaskList: this.state.listDataArray.map((ct, index) => (
            <ListGroupItem key={ct.id ? ct.id : index} aria-selected={this.state.ctNavPills === index} className={"mb-sm-3 mb-md-0"} role="tab">
                    <Row>
                        <Col xs="9">
                            {ct.name}
                        </Col>
                        <Col xs="1">
                            <Button className="btn btn-sm btn-outline-primary" onClick={e => this.openTaskEditModal(index)}><i class="far fa-edit"></i></Button>
                        </Col>
                        <Col xs="1">
                            <Button className="btn btn-sm btn-outline-danger" onClick={ e => this.removeListTask( index ) }><i class="fas fa-times"></i></Button>
                        </Col>
                    </Row>
            </ListGroupItem>
            ))
        });
    }




    array_move = (arr, old_index, new_index) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; 
    };

    removeListTask = (index) => {
        let tmpDataarray = this.state.listDataArray
        if (index > -1) {
            tmpDataarray.splice(index, 1);
            this.setState({listDataArray: tmpDataarray})
        }
        this.displayNewTaskList()
    }

    removePipelineTask = (index) => {
        let tmpDataarray = this.state.pipeline
        if (index > -1) {
            tmpDataarray.splice(index, 1);
            this.setState({pipeline: tmpDataarray})
        }
        this.displayNewPipeline()
    }

    moveUpPipelineRankTask = (index) => {
        let tmpDataarray = this.state.pipeline
        if (index > 0) {
            this.array_move(tmpDataarray, index, index - 1)
        }
        this.displayNewPipeline()
    }

    moveDownPipelineRankTask = (index) => {
        let tmpDataarray = this.state.pipeline

        if (index < tmpDataarray.length -1) {
            this.array_move(tmpDataarray, index, index + 1)
        }
        this.displayNewPipeline()
    }

    addTaskToPipeline = () => {
        // const tmppipeline =this.state.pipeline
        console.log(this.state.pipeline)
        let pl = this.state.pipeline
        const length = pl.length
        pl[pl.length] = this.state.listDataArray
        console.log({pl})
        this.setState({pipeline: pl})
        this.setState({listDataArray: []})
        this.displayNewPipeline()
        this.setState({newTaskList: ''})
    }

    displayNewPipeline = () => {
        console.log(this.state)
        console.log(this.state.pipeline)

        this.setState({ NewPipeline: this.state.pipeline.map((ct, index) => (
            <Row key={ct.id ? ct.id : index} aria-selected={this.state.ctNavPills === index} className={"mb-sm-3 mb-md-0"} role="tab" >
                <Col>
                    <Row>
                        <Col>
                        <h3>Task: {index}</h3> 
                        </Col>
                        <Col xs="1">
                            <Button className="btn btn-sm btn-outline-danger" onClick={ e => this.editPhase( ct, index ) }><i class="fas fa-angle-double-left"></i></Button>
                        </Col>
                        <Col xs="1">
                            <Button className="btn btn-sm btn-outline-danger" onClick={ e => this.removePipelineTask( index ) }><i class="fas fa-times"></i></Button>
                        </Col>
                        <Col xs="1">
                            <Button className="btn btn-sm btn-outline-success" onClick={ e => this.moveUpPipelineRankTask( index ) }><i class="fas fa-angle-double-up"></i></Button>
                        </Col>
                        <Col xs="1">
                            <Button className="btn btn-sm btn-outline-success" onClick={ e => this.moveDownPipelineRankTask( index ) }><i class="fas fa-angle-double-down"></i></Button>
                        </Col>
                    </Row>
                    <Row>
                        <Table>
                            <thead>
                                <tr>
                                <th scope="col">name</th>
                                {/* <th scope="col">arguments</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {this.displayPipelineTaskItems(ct)}
                            </tbody>
                            {/* {ct} */}
                        </Table>
                    </Row>
                </Col>
            </Row>
            ))
        });
        this.displayNewTaskList()
    }

    displayPipelineTaskItems = (taskList) => {
        console.log({taskList})
        const tasktable = taskList.map((ct, index) => (
            <tr key={ct.id ? ct.id : index}
                aria-selected={this.state.ctNavPills === index}
                className={"mb-sm-3 mb-md-0"}
                role="tab"
                >
                <td>{ct.name}</td>
                {/* <td>{ct.arguments}</td> */}
                {/* </a> */}
            </tr>
        ));
        return(tasktable)
    }

    openTaskEditModal = (index) => {
        const dataArr = 
        this.setState({modal: true});
        this.setState({selectedNewTask: index})
        this.setState({arguments: this.state.listDataArray[index]['arguments']})
    }

    cancelButton = () => {
        // console.log('Close Modal')
        // this.getModal(false);
        this.setState({modal: false})
    }

    handleArgsChange = event => {
        this.setState({arguments: event.target.value})
        // console.log(event.target.value)
    }

    handleTypeChange = event => {
        this.setState({type: event.target.value})
        // console.log(event.target.value)
    }

    updateTaskArgs = (index) => {

        let tmparr = this.state.listDataArray
        tmparr[index]['arguments'] = this.state.arguments
        this.setState({listDataArray: tmparr })
        console.log({tmparr})
        this.setState({modal: false})
    }
    handleNameChange = event => {
        this.setState({name: event.target.value})
        // console.log(event.target.value)
    }

    editPhase = (phase, index) => {
        let newpipeline = this.state.pipeline
        newpipeline.pop(index)
        console.log(newpipeline)
        this.setState({pipeline: newpipeline})
        this.displayNewPipeline()

        console.log({index})
        this.setState({listDataArray: phase})
        // this.displayNewTaskList()
        this.setState({ newTaskList: phase.map((ct, index) => (
            <ListGroupItem key={ct.id ? ct.id : index} aria-selected={this.state.ctNavPills === index} className={"mb-sm-3 mb-md-0"} role="tab">
                    <Row>
                        <Col xs="9">
                            {ct.name}
                        </Col>
                        <Col xs="1">
                            <Button className="btn btn-sm btn-outline-primary" onClick={e => this.openTaskEditModal(index)}><i class="far fa-edit"></i></Button>
                        </Col>
                        <Col xs="1">
                            <Button className="btn btn-sm btn-outline-danger" onClick={ e => this.removeListTask( index ) }><i class="fas fa-times"></i></Button>
                        </Col>
                    </Row>
            </ListGroupItem>
            ))
        });
        
    }
    render() {
        return(
            <>
                <Modal isOpen={this.state.modal}>
                    <ModalHeader>Confirm</ModalHeader>
                    <ModalBody>
                        <Input placeholder="Script, Args" type="text" value={this.state.arguments} onChange={this.handleArgsChange} /> 
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={e => this.updateTaskArgs( this.state.selectedNewTask)}>Update</Button>{' '}
                        <Button color="secondary" onClick={ e => this.cancelButton(  ) }>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Container>
                    <Row>
                        <Col md="4">
                            <Input placeholder="Name" required="true" type="text" value={this.state.name} onChange={this.handleNameChange} /> 
                        </Col>
                        {/* <Col>
                            <Input placeholder="Type" required="true" type="text" value={this.state.type} onChange={this.handleTypeChange} /> 
                        </Col> */}
                    </Row>
                    <br/>
                    <Row>
                        <Col  md="3">
                            <h3>Compute</h3>
                            <Col>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color="primary" caret>
                                        {this.state.SelectedTaskType }
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem className={classnames("dropdown-item" , { active: this.state.SelectedTaskType === 'ComputeTemplate' })} onClick={(e) => this.updateTaskType('ComputeTemplate')} >
                                            ComputeTemplates
                                        </DropdownItem>
                                        <DropdownItem className={classnames("dropdown-item" , { active: this.state.SelectedTaskType === 'Pipeline' })} onClick={(e) => this.updateTaskType('Pipeline')} >
                                            Pipelines
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                                <br />
                                <br />
                                {this.state.SelectTypeComponent}
                            </Col>
                            <ListGroup>
                                {this.state.listTaskLists}
                            </ListGroup>
                        </Col>
                        <Col md="1">
                            <Button className="btn btn-outline-success" onClick={ e => this.addSelectedTemplateToList( ) }><i class="fas fa-angle-double-right"></i></Button>
                        </Col>
                        <Col md="3">
                            <h3>New Phase</h3>
                            <ListGroup>
                                    {this.state.newTaskList}
                            </ListGroup>
                        </Col>
                        <Col md="1">
                            <Button className="btn btn-outline-success" onClick={ e => this.addTaskToPipeline() }><i class="fas fa-angle-double-right"></i></Button>
                        </Col>
                        <Col md="3">
                            <h3>New Pipeline</h3>
                            {this.state.NewPipeline}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button className="btn btn-outline-danger" onClick={ e => this.onButtonClick( this.state.pipeline ) }>Submit</Button>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}


const mapStateToProps = state => {
    // console.log({state})
    return {
        pipeline: state.selectedPipeline,
        // UsersQuerys: state.UsersQuerys, // replacing reqs 
    };
}

const mapDispatchToProps = dispatch => {
    
    return {
        updateSelectedPipeline: (event) => {
            console.log('updateSelectedPipeline to props event: ', event)
            dispatch({type: 'UPDATESELECTEDPIPELINE', 
                selectedPipeline: event,
            })
            
        },


        
        
    }
}

export default connect( mapStateToProps, mapDispatchToProps)(EditPipelineForm);
