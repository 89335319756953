import React from 'react'
// import ReactDOM from 'react-dom';
// import { listDataVersions, getDataVersion } from '../../graphql/queries.js'
// import { updateDataVersion, CreateDataVersion } from '../../graphql/mutations.js'
// import App from '../../App'
// import { NewComputeTemplateForm } from './index'
import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Collapse
  } from "reactstrap";
import NewSNSForm from './snsForm.js'
import ListSNSTopicsTable from './listSNStopics'


class SQSReactor extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            openedCollapses: [""]
        }
    }

    setInput(key, value) {
        this.setState([key], value)
    }


    componentDidMount() {
        // this.displayList();
    }


    


    isCurrent(value){
        if(value){
            return('true')
        } else {
            return('false')
        }
    }


    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: []
            });
        } else {
            this.setState({
                openedCollapses: [collapse]
            });
            
            
        }
    };





    render  () {


        return( 
            <Card>
                <CardHeader  role="tab"
                onClick={() => this.collapsesToggle("snsReactor")}
                aria-expanded={this.state.openedCollapses.includes("snsReactor")}>
                    
                    <Row>
                        <Col className='col-10'>
                            <CardTitle className="h2 mb-0">SNS</CardTitle>
                        </Col>
                        <Col className='col-2'>
                            <span className="custom-toggle">
                                <input type="checkbox"  checked={this.state.openedCollapses.includes("snsReactor")} />
                                <span className="custom-toggle-slider rounded-circle"  />
                            </span>
                        </Col>
                    </Row>
                </CardHeader>
                <Collapse role="tabpanel" isOpen={this.state.openedCollapses.includes("snsReactor")}>
                    <CardBody>
                        <Row>
                            <NewSNSForm />
                        </Row>
                        <hr></hr>
                        <Row>
                            <ListSNSTopicsTable />
                        </Row>
                    </CardBody>
                </Collapse>
            </Card>
        
        )
    }
};

// const mapStateToProps = state => {
//     return {
//         currentQueryID: state.selectedQueryID,


//         // UsersQuerys: state.UsersQuerys, // replacing reqs 
//     };
// }


// export { ListQueryRunHistory };

// export default withAuthenticator(connect(mapStateToProps)(Admin));

export default SQSReactor