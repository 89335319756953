import React from 'react'
// import ReactDOM from 'react-dom';
import { listComputeExecutions, listComputeTemplates } from '../graphql/queries'
import * as subscriptions from '../graphql/subscriptions';

// import { createComputeExecution } from '../graphql/mutations'
import { API, graphqlOperation, Auth, Storage } from 'aws-amplify'
import { connect } from 'react-redux'
// import { withAuthenticator } from '@aws-amplify/ui-react'
import classnames from "classnames";
import { 
    ListGroup,
    ListGroupItem,
    Card, 
    CardBody, 
    CardTitle, 
    CardHeader,
    Row,
    Col,
    Table,
    Collapse,
    Button,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";



class ListComputeExecutionsTable extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            reqNavPills: -1,
            listUsersReqs: [],
            selectedTemplate: [],
            setActive: false,
            radio: -1,
            selectedQueryID: '',
            selectedQueryName: '',
            computeTemplates: [],
            navPills: 1,
            ModalLogfileDownloadButton: ''
        }
        this.downloadComputeTemplates()
        this.displayList()
        const onUpdateComputeExecution = API.graphql(
            graphqlOperation(subscriptions.onUpdateComputeExecution)
        ).subscribe({
            next: ({ provider, value }) => {
                this.displayList()
                console.log({ provider, value })},
            error: error => console.warn(error)
        });

        const onCreateComputeExecution = API.graphql(
            graphqlOperation(subscriptions.onCreateComputeExecution)
        ).subscribe({
            next: ({ provider, value }) => {
                this.displayList()
                console.log({ provider, value })},
            error: error => console.warn(error)
        });

    }
    toggleNavs = (e, state, index) => {
        e.preventDefault();
        this.setState({[state]: index});
        this.setSelected(e,index)
        // this.displayList()
    };

    toggleNavPills = (e, state, index) => {
        e.preventDefault();
        this.setState({[state]: index});
        this.displayList()
    };

    setInput(key, value) {
        this.setState([key], value)
    }



    componentDidMount() {
        this.displayList();
    }



    setSelected = async(ct, index) => {
        console.log(ct)
        this.setState({selectedTemplate: ct})
        this.setState({reqNavPills: index});
        this.props.updateSelectedTemplate(ct['id'])
        this.displayList()
        // console.log(response)
    }



    getComputeTypeElements = (ct) => {
        // const type = ct
        this.displayList()
        // console.log(type)
    }


    downloadBlob(blob, filename) {
        console.log(blob)
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        console.log(url)
        a.download = filename || 'download';
        const clickHandler = () => {
            setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
            }, 150);
        };
        a.addEventListener('click', clickHandler, false);
        a.click();
        return a;
    }

    getSignedURL = async( logfile ) => {
        let filename = 'server-output.log'
        // console.log(logfile)
        await Storage.get(logfile, { download: true }).then(res => this.downloadBlob(res.Body, filename))
    }

    logfileDownloadButton = (ce) => {
        // console.log(ce)
        if(ce.status === 'Completed'){
            return(
                <Button className="btn btn-outline-success" onClick = { e => this.getSignedURL( ce.logfile + 'server-output.log' ) } >Download</Button>
            )
        } else if(ce.status === 'Failed'){
            return(
                <Button className="btn btn-outline-danger" onClick = { e => this.getSignedURL( ce.logfile + 'server-output.log' ) } >Download</Button>
            )
        } else if(ce.status === 'TimedOut'){
            return(
                <Button className="btn btn-outline-danger" onClick = { e => this.getSignedURL( ce.logfile + 'server-output.log' ) } >Download</Button>
            )
        }    
    }

    




    displayList = async() => {
        try {
            
            let CEData = await API.graphql({ query: listComputeExecutions,  variables: { limit: 100,  } });
            let lcedata = CEData.data.listComputeExecutions
            let CEItems = lcedata.items;
            while(lcedata.nextToken != null ){
                let nextToken = lcedata.nextToken;
                let CEData2 = await API.graphql({ query: listComputeExecutions, variables: { limit: 100, nextToken }});
                lcedata = {}
                lcedata = CEData2.data.listComputeExecutions
                CEItems = CEItems.concat(lcedata.items)
            }
            // console.log(CEItems.length)
            // console.log({CEItems})


            // if(lcedata.hasOwnProperty("nextToken")){
            //     let nextToken = lcedata.nextToken;
            //     let CEData2 = await API.graphql({ query: listComputeExecutions, variables: { limit: 30, nextToken }});
            //     lcedata = {}
            //     lcedata = CEData2.data.listComputeExecutions
            //     CEItems = CEItems.concat(lcedata.items)
            //     // console.log({CEItems})
            // }
            // console.log({CEItems})

            
            // console.log(CEItems)
            // let filteredCEItems = [...CEItems.filter( CEItems => CEItems.computeTemplate === this.props.selectedTemplateID )]
            let filteredCEItems
            if(this.state.navPills === 1){
                console.log('apply filter for Recent Jobs')
                let recentTimeout = new Date() ;
                filteredCEItems = [...CEItems.filter( (item) => new Date(item.createdAt).toString().split(' ')[3] == recentTimeout.toString().split(' ')[3] )]
                filteredCEItems = [...filteredCEItems.filter( (item) => new Date(item.createdAt).toString().split(' ')[1] == recentTimeout.toString().split(' ')[1] )]
                filteredCEItems = [...filteredCEItems.filter( (item) => new Date(item.createdAt).toString().split(' ')[2] == recentTimeout.toString().split(' ')[2] )]
            } else if(this.state.navPills === 2){
                console.log('apply filter for Active Jobs')
                filteredCEItems = [...CEItems.filter( CEItems => CEItems.status !== 'Completed' )]
                filteredCEItems = [...filteredCEItems.filter( CEItems => CEItems.status !== 'Failed' )]
                filteredCEItems = [...filteredCEItems.filter( CEItems => CEItems.status !== 'TimedOut' )]
            } else if(this.state.navPills === 3) {
                console.log('apply filter for Coimpleted Jobs')
                filteredCEItems = [...CEItems.filter( CEItems => CEItems.status === 'Completed' )]
            } else if(this.state.navPills === 4) {
                console.log('apply filter for Failed Jobs')
                filteredCEItems = [...CEItems.filter( CEItems => CEItems.status === 'Failed'  )]
                filteredCEItems = filteredCEItems.concat([...filteredCEItems.filter( CEItems => CEItems.status === 'TimedOut' )])
                
            }
            // console.log(this.props.selectedTemplateID)
            // console.log(filteredCEItems)
            // let reqs = [...ReqItems.filter( ReqItems => ReqItems.deleted === false )]
            // console.log(this.state.selectedQueryID)
            let CEs = [ ...filteredCEItems.sort( function (a,b){ return new Date(b.createdAt) - new Date(a.createdAt)})]

            this.setState({ ComputeExecutions: CEs})

            console.log({CEs})
            this.setState({
                listComputeExecutions: CEs.map((ct, index) => (
                        <tr key={ct.id ? ct.id : index}
                            aria-selected={this.state.reqNavPills === index}
                            className={classnames("table-hover mb-sm-3 mb-md-0", {
                                active: this.state.reqNavPills === index
                            })}
                            role="tab"
                            >
                            <td>{ct.createdAt}</td>
                            <td>{this.getComputeTemplate(ct.computeTemplate)}</td>
                            <td>{ct.status}</td>
                            <td><Button className="btn btn-outline-info" onClick = {e => this.setSelected(ct, index)} >Details</Button></td>
                            <td>{ this.logfileDownloadButton(ct) }</td>
                        </tr>
                ))
            });
        } catch (err) {
            console.log(err);
        }
    }

    downloadComputeTemplates = async() => {
        let CTData = await API.graphql({ query: listComputeTemplates, variables: { limit: 100,  }});
        let CTItems = CTData.data.listComputeTemplates.items;
        let CTdata = CTData.data.listComputeTemplates
        while(CTdata.nextToken != null ){
            let nextToken = CTdata.nextToken;
            let CTData2 = await API.graphql({ query: listComputeTemplates, variables: { limit: 100, nextToken }});
            CTdata = {}
            CTdata = CTData2.data.listComputeTemplates
            CTItems = CTItems.concat(CTdata.items)
        }
        this.setState({computeTemplates: CTItems})
    }

    getComputeTemplate = (ctid) => {
        let computetemplates = this.state.computeTemplates
        // console.log({ctid})
        let data = computetemplates.filter( ct => { return ct.id == ctid} )
        // console.log({data})
        let name = ''

        if(data.length > 0){
            name = data[0]['name']
        }
        return(name)
    }

    openExecutionModal = () => {
        this.setState({executionmodal: true});
        // this.setState({arguments: this.state.selectedTemplate['arguments']})
    }

    closeExecutionModalButton = () => {
        // console.log('Close Modal')
        // this.getModal(false);
        this.setState({editMode: false})
        this.setState({executionmodal: false});
    }


    setSelected = async(ct, index) => {
        console.log(ct)
        // this.getComputeType(ct.computeTemplate)
        this.updateExecutionModalContent(ct)
        this.setState({selectedTemplate: ct})
        this.setState({reqNavPills: index});
        // this.props.updateSelectedTemplate(ct)
        // this.setState({arguments: ct['arguments']})
        this.displayList()
        this.openExecutionModal()
        // console.log(response)
    }

    updateExecutionModalContent = (ct) => {
        // const computeTypes = this.state.ComputeTypes
        // const computeType = [...computeTypes.filter( computeType => computeType.id === ct.computeType )][0]
        // console.log({computeType})

        this.setState({ executionTemplateModal: (<>
            
                
            <Row>
                <Col md='9'>
                    <h3>Compute Execution Profile Details</h3>
                </Col>
            </Row>
            <Row>    
                <ul>
                    <li>Name: {this.getComputeTemplate(ct.computeTemplate)}</li>
                    <li>Created At: {ct.createdAt}</li>
                    <li>Status: {ct.status}</li>
                    <li>Arguments: {ct.arguments}</li>
                    <li>ParentExId: {ct.parentExId}</li>
                    <li>ParentType: {ct.parentType}</li>
                </ul>
            </Row>

        </>)})
        // this.setState({ displayRunButton: (<><Button className="btn btn-outline-danger" onClick={ e => this.openRunModal(  ) }>Run</Button></>)})
        this.setState({ ModalLogfileDownloadButton: this.logfileDownloadButton(ct)  })
    }




render  () {
    // console.log(this.props.selectedTemplateID)
        return( 
            <>
            <Modal isOpen={this.state.executionmodal} className="modal-xl modal-dialog">
                <ModalHeader>Compute Execution</ModalHeader>
                <ModalBody>
                    {this.state.executionTemplateModal}
                    {/* <Input placeholder="Script, Args" type="text" value={this.state.arguments} onChange={this.handleRunArgsChange} />  */}
                </ModalBody>
                <ModalFooter>
                    {this.state.ModalLogfileDownloadButton}
                    <Button color="secondary" onClick={ e => this.closeExecutionModalButton(  ) }>Close</Button>
                </ModalFooter>
            </Modal>
            <Nav className="nav-fill flex-column flex-sm-row" id="tabs-text" pills role="tablist">
                <NavItem>
                    <NavLink
                    aria-selected={this.state.navPills === 1}
                    className={classnames("mb-sm-3 mb-md-0", {
                        active: this.state.navPills === 1
                    })}
                    onClick={e => this.toggleNavPills(e, "navPills", 1)}
                    href="#pablo"
                    role="tab">
                    Recent
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                    aria-selected={this.state.navPills === 2}
                    className={classnames("mb-sm-3 mb-md-0", {
                        active: this.state.navPills === 2
                    })}
                    onClick={e => this.toggleNavPills(e, "navPills", 2)}
                    href="#pablo"
                    role="tab">
                    Active
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                    aria-selected={this.state.navPills === 3}
                    className={classnames("mb-sm-3 mb-md-0", {
                        active: this.state.navPills === 3
                    })}
                    onClick={e => this.toggleNavPills(e, "navPills", 3)}
                    href="#pablo"
                    role="tab">
                    Completed
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                    aria-selected={this.state.navPills === 4}
                    className={classnames("mb-sm-3 mb-md-0", {
                        active: this.state.navPills === 4
                    })}
                    onClick={e => this.toggleNavPills(e, "navPills", 4)}
                    href="#pablo"
                    role="tab">
                    Failed
                    </NavLink>
                </NavItem>
            </Nav>
            <Table className="align-items-center" responsive>
                <thead className="thead-light">
                    <tr>
                        <th scope="col">created</th>
                        <th scope="col">Compute Profile</th>
                        <th scope="col">Status</th>
                        <th scope="col">Details</th>
                        <th scope="col">logfile</th>
                    </tr>
                </thead>
                <tbody>

                    {this.state.listComputeExecutions}

                </tbody>
            </Table>
            </>
        )
    }
    }
// };



const mapStateToProps = state => {
    console.log({state})
    return {
        selectedTemplate: state.selectedTemplate
    };
}


export default connect( mapStateToProps)(ListComputeExecutionsTable);
