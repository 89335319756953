import React from 'react'
// import ReactDOM from 'react-dom';
// import { listDataVersions, getDataVersion } from '../../graphql/queries.js'
// import { updateDataVersion, CreateDataVersion } from '../../graphql/mutations.js'
// import App from '../../App'
// import { NewComputeTemplateForm } from './index'
import classnames from "classnames";
import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Collapse,
    NavItem,
    NavLink,
    Nav,
  } from "reactstrap";
import NewLaunchTemplateForm from './launchTemplateForm'
import NewInstanceForm from './InstanceForm'
import ListComputeTable from './listComputeTable'


class ComputeResource extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            openedCollapses: [""],
            navPills: 0,
            displayForm: ''
        }
    }

    toggleNavs = (e, state, index) => {
        e.preventDefault();
        this.setState({[state]: index});
        this.setSelected(e,index)
        // this.displayList()
    };

    toggleNavPills = (e, state, index) => {
        e.preventDefault();
        this.setState({[state]: index});
        if(index === 1){
            this.setState({ displayForm: <NewLaunchTemplateForm /> })
        } else if(index === 2){
            this.setState({ displayForm: '' })
        } else if(index === 3){
            this.setState({ displayForm: <NewInstanceForm /> })
        }


    };


    setInput(key, value) {
        this.setState([key], value)
    }


    componentDidMount() {
        // this.displayList();
    }


    


    isCurrent(value){
        if(value){
            return('true')
        } else {
            return('false')
        }
    }


    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: []
            });
        } else {
            this.setState({
                openedCollapses: [collapse]
            });
            
            
        }
    };





    render  () {


        return( 
            <Card>
                <CardHeader  role="tab"
                onClick={() => this.collapsesToggle("snsReactor")}
                aria-expanded={this.state.openedCollapses.includes("snsReactor")}>
                    
                    <Row>
                        <Col className='col-10'>
                            <CardTitle className="h2 mb-0">Compute</CardTitle>
                        </Col>
                        <Col className='col-2'>
                            <span className="custom-toggle">
                                <input type="checkbox"  checked={this.state.openedCollapses.includes("snsReactor")} />
                                <span className="custom-toggle-slider rounded-circle"  />
                            </span>
                        </Col>
                    </Row>
                </CardHeader>
                <Collapse role="tabpanel" isOpen={this.state.openedCollapses.includes("snsReactor")}>
                    <CardBody>
                        <Row>
                            <Nav
                                className="nav-fill flex-column flex-sm-row"
                                id="tabs-text"
                                pills
                                role="tablist"
                                >
                                <NavItem>
                                    <NavLink
                                    aria-selected={this.state.navPills === 1}
                                    className={classnames("mb-sm-3 mb-md-0", {
                                        active: this.state.navPills === 1
                                    })}
                                    onClick={e => this.toggleNavPills(e, "navPills", 1)}
                                    href="#pablo"
                                    role="tab"
                                    >
                                    Launch Template
                                    </NavLink>
                                </NavItem>
                                {/* <NavItem>
                                    <NavLink
                                    aria-selected={this.state.navPills === 2}
                                    className={classnames("mb-sm-3 mb-md-0", {
                                        active: this.state.navPills === 2
                                    })}
                                    onClick={e => this.toggleNavPills(e, "navPills", 2)}
                                    href="#pablo"
                                    role="tab"
                                    >
                                    AutoScaling Group
                                    </NavLink>
                                </NavItem> */}
                                <NavItem>
                                    <NavLink
                                    aria-selected={this.state.navPills === 3}
                                    className={classnames("mb-sm-3 mb-md-0", {
                                        active: this.state.navPills === 3
                                    })}
                                    onClick={e => this.toggleNavPills(e, "navPills", 3)}
                                    href="#pablo"
                                    role="tab"
                                    >
                                    Instance
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Row>
                        <Row>
                            {this.state.displayForm}
                        </Row>
                        <hr></hr>
                        <Row>
                            <ListComputeTable />
                        </Row>
                    </CardBody>
                </Collapse>
            </Card>
        
        )
    }
};

// const mapStateToProps = state => {
//     return {
//         currentQueryID: state.selectedQueryID,


//         // UsersQuerys: state.UsersQuerys, // replacing reqs 
//     };
// }


// export { ListQueryRunHistory };

// export default withAuthenticator(connect(mapStateToProps)(Admin));

export default ComputeResource