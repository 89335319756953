import React from 'react'
// import ReactDOM from 'react-dom';
import { listComputeTemplates, getComputeType, listComputeTypes, getComputeTemplate } from '../graphql/queries'
import { createComputeExecution, updateComputeTemplate, deleteComputeTemplate } from '../graphql/mutations'
import { API, graphqlOperation, Auth } from 'aws-amplify'
import { connect } from 'react-redux'
import ListComputeExecutionsTableForTemplate from './listComputeExecutionsForTemplate.js'
import EditComputeTemplateForm from './editExecutionProfilesForm.js'
import * as subscriptions from '../graphql/subscriptions';
// import { withAuthenticator } from '@aws-amplify/ui-react'
import classnames from "classnames";
import { 
    ListGroup,
    ListGroupItem,
    Card, 
    CardBody, 
    CardTitle, 
    CardHeader,
    Row,
    Col,
    Table,
    Button, 
    Modal,
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Input,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";



class ListComputeTemplates extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            reqNavPills: -1,
            listUsersReqs: [],
            selectedTemplate: [],
            setActive: false,
            radio: -1,
            selectedQueryID: '',
            selectedQueryName: '',
            ComputeTemplates: [],
            cleanedComputeTemplateList: [],
            arguments: '',
            runmodal: false,
            modalbody: '',
            modalArgs: '',
            executionTemplateModal: '',
            ComputeTypes: [],
            SelectedType: 'Select Compute Template',
            editMode: false
        }
        // this.getComputeTemplateList()
        this.listComputeTypes()
        // this.getModal(false)

        const onUpdateComputeTemplate = API.graphql(
            graphqlOperation(subscriptions.onUpdateComputeTemplate)
        ).subscribe({
            next: ({ provider, value }) => {
                // this.getComputeTemplateList()
                this.displayList()
                this.updateSelectedComputeTemplateModal()
                console.log({ provider, value })},
            error: error => console.warn(error)
        });

        const onCreateComputeTemplate = API.graphql(
            graphqlOperation(subscriptions.onCreateComputeTemplate)
        ).subscribe({
            next: ({ provider, value }) => {
                // this.getComputeTemplateList()
                this.displayList()
                console.log({ provider, value })},
            error: error => console.warn(error)
        });

        const onDeleteComputeTemplate = API.graphql(
            graphqlOperation(subscriptions.onDeleteComputeTemplate)
        ).subscribe({
            next: ({ provider, value }) => {
                // this.getComputeTemplateList()
                this.displayList()
                console.log({ provider, value })},
            error: error => console.warn(error)
        });

    }
    toggleNavs = (e, state, index) => {
        e.preventDefault();
        this.setState({[state]: index});
        this.setSelected(e,index)
        this.displayList()
    };

    setInput(key, value) {
        this.setState([key], value)
    }

    componentDidMount() {
        // this.getComputeTemplateList()
        this.displayList();
    }

    setSelected = async(ct, index) => {
        console.log(ct)
        // this.getComputeType(ct.computeTemplate)
        this.updateTemplateModalContent(ct)
        this.setState({selectedTemplate: ct})
        this.setState({reqNavPills: index});
        this.props.updateSelectedTemplate(ct)
        this.setState({arguments: ct['arguments']})
        this.displayList()
        this.openTemplateModal()
        // console.log(response)
    }

    updateSelectedComputeTemplateModal = async() => {
        const selectedCT = this.state.selectedTemplate
        console.log('SelectedCT', selectedCT)
        let computeTemplateDataresp = await API.graphql({ query: getComputeTemplate, variables: { id: selectedCT.id }})
        const computeTemplateData = computeTemplateDataresp.data
        const computeTemplate = computeTemplateData.getComputeTemplate
        console.log(computeTemplate)
        this.updateTemplateModalContent(computeTemplate)
    }

    listComputeTypes = async() => {
        let CTData
        try {
            CTData = await API.graphql(graphqlOperation(listComputeTypes));
        } catch (err) {
            console.log(err);
        }
        // console.log(CTData)
        let CTItems = CTData.data.listComputeTypes.items;
        this.setState({ComputeTypes: CTItems})
        // console.log({CTItems})
    }

    displayTypeTemplates = (type) => {
        this.setState({SelectedType: type })
        this.displayList()
    }

    selectType = (types) => {
        this.setState({ typeSelect: types.map((name, index) => (
            <DropdownItem 
                className={classnames("dropdown-item" , { active: this.state.sortBy === '' })}
                onClick={(e) => this.displayTypeTemplates(name)} >
                    {name}
            </DropdownItem>
        ))})}

    displayList = async() => {
        try {
            let CTData = await API.graphql({ query: listComputeTemplates, variables: { limit: 100,  }});
            // console.log(CTData)
            let CTItems = CTData.data.listComputeTemplates.items;
            let CTdata = CTData.data.listComputeTemplates
            console.log({CTdata})
            // let ctlist = CTItems.data.listComputeTemplates
            // let CEItems = ctlist.items;
            console.log(CTItems.length)
            console.log({CTItems})
            while(CTdata.nextToken != null ){
                let nextToken = CTdata.nextToken;
                let CTData2 = await API.graphql({ query: listComputeTemplates, variables: { limit: 100, nextToken }});
                CTdata = {}
                CTdata = CTData2.data.listComputeTemplates
                CTItems = CTItems.concat(CTdata.items)
                console.log(CTdata.items.length)
            }
            console.log(CTItems.length)
            console.log({CTItems})
            // console.log(CTItems)
            // let reqs = [...ReqItems.filter( ReqItems => ReqItems.deleted === false )]
            // console.log(this.state.selectedQueryID)
            const types = ['All', 'None', ...new Set(CTItems.map(item => item.type))];
            if (this.state.SelectedType == 'None' | this.state.SelectedType == 'Select Compute Template'){
                this.setState({ ComputeTemplates: []})
            } 
            if (this.state.SelectedType == 'All'){
                this.setState({ ComputeTemplates: [ ...CTItems.sort( function (a,b){ return new Date(b.createdAt) - new Date(a.createdAt)})]})
            } else {
                this.setState({ ComputeTemplates: [ ...CTItems.filter( CTItems => CTItems.type === this.state.SelectedType )].sort( function (a,b){ return new Date(b.createdAt) - new Date(a.createdAt)})})
            }
            let CTs = [ ...this.state.ComputeTemplates ]
            // console.log({CTs})
            // console.log({types})
            this.selectType(types)
            this.setState({ SelectTypeComponent: (
                <UncontrolledButtonDropdown>
                    <DropdownToggle color="primary" caret>
                        {this.state.SelectedType }
                    </DropdownToggle>
                    <DropdownMenu>
                        {this.state.typeSelect}
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
                )})
            this.setState({
                listComputeTemplates: CTs.map((ct, index) => (
                    <tr key={ct.id ? ct.id : index}
                        aria-selected={this.state.reqNavPills === index}
                        className={classnames("tr", {
                            active: this.state.reqNavPills === index
                        })}
                        onClick={e => this.setSelected(ct, index)}
                        role="tab">
                        
                        <td>{ct.name}</td>

                        {/* <td>{ct.s3key}</td> */}

                        {/* <td>{ct.computeTypeName}</td> */}
                        {/* <td>{this.getComputeType(ct.computeType)}</td> */}
                        {/* </a> */}
                    </tr>
                ))
            });
        } catch (err) {
            console.log(err);
        }
    }

    onButtonClick = () => {

        this.submitToGQL()
        
    }

    openTemplateModal = () => {
        this.setState({templatemodal: true});
        // this.setState({arguments: this.state.selectedTemplate['arguments']})
    }

    closeTemplateModalButton = () => {
        // console.log('Close Modal')
        // this.getModal(false);
        this.setState({editMode: false})
        this.setState({templatemodal: false});
    }

    updateTemplateModalContent = (ct) => {
        const computeTypes = this.state.ComputeTypes
        const computeType = [...computeTypes.filter( computeType => computeType.id === ct.computeType )][0]
        // console.log({computeType})
        this.setState({ executionTemplateModal: (<>
            <Row>
                <Col>
                    <Row>
                        <Col  md='9'>
                            <h3>Compute Execution Profile Details</h3>
                        </Col>
                        <Col>
                            <Button onDoubleClick={e => this.toggleEditComputeExecutionProfile()}><i class="fas fa-edit"></i></Button>
                        </Col>
                    </Row>
                    <ul>
                        <li>Name: {ct.name}</li>
                        <li>Runtime: {ct.maxRunTime}</li>
                        <li>Default Arguments: {ct.arguments}</li>
                        <li>S3 Key: {ct.s3key}</li>
                        <li>Type: {ct.type}</li>
                        <li>Tags: {ct.tags}</li>
                    </ul>
                    <h3>Compute Resource Details</h3>
                    <ul>
                        {/* <li>ComputeType: {ct.computeType}</li> */}
                        <li>ComputeType: {computeType.name}</li>
                        <li>ComputeSize: {computeType.computeSize}</li>
                        <li>ComputeResource: {computeType.computeResource}</li>
                        <li>SqsResource: {computeType.sqsResource}</li>
                        <li>SnsResource: {computeType.snsResource}</li>
                        <li>Type: {computeType.type}</li>
                        {/* <li>ComputeType: {computeType.tags}</li> */}
                    </ul>
                </Col>
                <Col>
                    <h3>Executions</h3>
                    <ListComputeExecutionsTableForTemplate />
                </Col>
            </Row>
        </>)})

        this.setState({ displayRunButton: (<><Button className="btn btn-outline-danger" onClick={ e => this.openRunModal(  ) }>Run</Button></>)})
        this.setState({ displayDeleteButton: ''})

    }

        toggleEditComputeExecutionProfile = () => {
            console.log(this.state.editMode)
            this.setState({editMode: !this.state.editMode})
            this.updateTemplateModalContent(this.state.selectedTemplate)
            const ct = this.state.selectedTemplate
            console.log(ct)
            const computeTypes = this.state.ComputeTypes
            const computeType = [...computeTypes.filter( computeType => computeType.id === ct.computeType )][0]
            if(!this.state.editMode){
                this.setState({ executionTemplateModal: (<>
                    <Row>
                        <Col>
                            <Row>
                                <Col md='9'>
                                    <h3>Compute Execution Profile Details</h3>
                                </Col>
                                <Col>
                                    <Button onDoubleClick={e => this.toggleEditComputeExecutionProfile()}><i class="fas fa-times"></i></Button>
                                </Col>
                            </Row>
                            <EditComputeTemplateForm />
                        </Col>
                        <Col>
                            <h3>Executions</h3>
                            <ListComputeExecutionsTableForTemplate />
                        </Col>
                    </Row>
                </>)})
                this.setState({ displayRunButton: ''})
                this.setState({ displayDeleteButton: (<><Button className="btn btn-outline-danger" onClick={ e => this.openDeleteModal(  ) }>Delete</Button></>)})
            } else {
                this.setState({ executionTemplateModal: (<>
                    <Row>
                        <Col>
                            <Row>
                                <Col md='9'>
                                    <h3>Compute Execution Profile Details</h3>
                                </Col>
                                <Col>
                                    <Button onDoubleClick={e => this.toggleEditComputeExecutionProfile()}><i class="fas fa-edit"></i></Button>
                                </Col>
                            </Row>
                            <ul>
                                <li>Name: {ct.name}</li>
                                <li>Runtime: {ct.maxRunTime}</li>
                                <li>Default Arguments: {ct.arguments}</li>
                                <li>S3 Key: {ct.s3key}</li>
                                <li>Type: {ct.type}</li>
                                <li>Tags: {ct.tags}</li>
                            </ul>
                            <h3>Compute Resource Details</h3>
                            <ul>
                                {/* <li>ComputeType: {ct.computeType}</li> */}
                                <li>ComputeType: {computeType.name}</li>
                                <li>ComputeSize: {computeType.computeSize}</li>
                                <li>ComputeResource: {computeType.computeResource}</li>
                                <li>SqsResource: {computeType.sqsResource}</li>
                                <li>SnsResource: {computeType.snsResource}</li>
                                <li>Type: {computeType.type}</li>
                                {/* <li>ComputeType: {computeType.tags}</li> */}
                            </ul>
                        </Col>
                        <Col>
                            <h3>Executions</h3>
                            <ListComputeExecutionsTableForTemplate />
                        </Col>
                    </Row>
                </>)})
                this.setState({ displayRunButton: (<><Button className="btn btn-outline-danger" onClick={ e => this.openRunModal(  ) }>Run</Button></>)})
                this.setState({ displayDeleteButton: ''})
            } 
            
        }



    submitRunToGQL = async() => {
        const token = await Auth.currentAuthenticatedUser()
        const username = token.username
        const values = {
            computeTemplate: this.state.selectedTemplate['id'],
            status: 'Fresh',
            arguments: this.state.arguments,
            parentType: 'Website',
            parentExId: username
        }
        console.log('Submitted Values', values)
        await API.graphql(graphqlOperation(createComputeExecution, {input: values}))
        this.setState({runmodal: false});
        // window.location.reload(false);
    }

    openRunModal = () => {
        this.setState({runmodal: true});
        this.setState({arguments: this.state.selectedTemplate['arguments']})
    }

    cancelRunButton = () => {
        // console.log('Close Modal')
        // this.getModal(false);
        this.setState({runmodal: false});
    }

    handleRunArgsChange = event => {
        this.setState({arguments: event.target.value})
        console.log(event.target.value)
    }

    openDeleteModal = () => {
        this.setState({deletemodal: true});
    }

    cancelDeleteButton = () => {
        this.setState({deletemodal: false});
    }

    submitDeleteToGQL = async() => {
        this.setState({deletemodal: false});
        this.setState({templatemodal: false});
        const values = {
            id: this.state.selectedTemplate['id'],
        }
        console.log('Submitted Values', values)
        await API.graphql(graphqlOperation(deleteComputeTemplate, {input: values}))
        // window.location.reload(false);
    }

    render  () {
        return( <>
            <Modal backdrop="static" isOpen={this.state.runmodal}>
                <ModalHeader close={<button className="close" onClick={e => this.cancelRunButton()}>×</button>}>Confirm</ModalHeader>
                <ModalBody>
                    <Input placeholder="Script, Args" type="text" value={this.state.arguments} onChange={this.handleRunArgsChange} /> 
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onDoubleClick={e => this.submitRunToGQL()}>Run</Button>{' '}
                    <Button color="secondary" onClick={ e => this.cancelRunButton(  ) }>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Modal backdrop="static" isOpen={this.state.deletemodal}>
                <ModalHeader close={<button className="close" onClick={e => this.cancelDeleteButton()}>×</button>}>Confirm Delete</ModalHeader>
                <ModalBody>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onDoubleClick={e => this.submitDeleteToGQL()}>Delete</Button>{' '}
                    <Button color="secondary" onClick={ e => this.cancelDeleteButton(  ) }>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Modal backdrop="static" isOpen={this.state.templatemodal} className="modal-xl modal-dialog">
                <ModalHeader close={<button className="close" onClick={e => this.closeTemplateModalButton()}>×</button>} >Compute Execution Profile</ModalHeader>
                <ModalBody>
                    {this.state.executionTemplateModal}
                </ModalBody>
                <ModalFooter>
                    {this.state.displayDeleteButton}
                    {this.state.displayRunButton}
                    <Button color="secondary" onClick={ e => this.closeTemplateModalButton(  ) }>Close</Button>
                </ModalFooter>
            </Modal>
            {/* <CardHeader> */}
                <Row>
                    {/* <Col className='col-6 d-none d-xs-none d-sm-none d-md-none d-lg-block'>
                        <CardTitle className="h2 mb-0">Template Type:</CardTitle>
                    </Col> */}
                    <Col className='col-6'>
                        {this.state.SelectTypeComponent}
                    </Col>
                </Row>
                <br />
            {/* </CardHeader>
            <CardBody> */}
                <Table className="align-items-center table" responsive>
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Name</th>
                            {/* <th scope="col">S3 Key</th> */}
                            {/* <th scope="col">computeType</th> */}
                            {/* <th scope="col" /> */}
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.listComputeTemplates}
                    </tbody>
                </Table>
            {/* </CardBody> */}
        </>)
    }
};



const mapStateToProps = state => {
    // console.log({state})
    return {
        // UsersQuerys: state.UsersQuerys, // replacing reqs 
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateSelectedTemplate: (event) => {
            console.log('updateSelectedTemplate to props event: ', event)
            dispatch({type: 'UPDATELISTQUERYITEMS', 
                selectedTemplate: event,
            })
        },
//         UpdateSelectedQueryLRData: (event) => {
//             console.log('UPDATESELECTEDQUERYLASTRUNDATA: ', event)
//             dispatch({type: 'UPDATESELECTEDQUERYLASTRUNDATA', 
//                 selectedQueryID: event.id, 
//                 selectedQueryName: event.name,
//                 selectedQueryStayCurrent: event.current,
//                 selectedQueryMarkets: event.Markets,
//                 selectedQueryConsumers: event.Consumers,
//                 selectedQueryLastRun: event.lastRun,
//                 selectedQueryRunStatus: event.lastRunstatus,
//                 selectedQueryRunResultsS3key: event.resultsS3key,
//                 selectedQueryRuntPIDcount: event.tPIDcount,
//                 selectedQueryRunCreatedAt: event.createdAt,
//                 selectedQuerylistRunItems: event.runHistory,
//                 selectedQueryLastRunStatus: event.lastRunstatus,
//                 selectedQueryLastRunDataVersion: event.dataVersion,
//                 selectedQuerydescription: event.description,
//                 selectedQueryincludeMapscore: event.includeMapscore,
//                 selectedQueryincludeRCAP: event.includeRCAP,
//                 currentDataVersion: event.currentVersion,
//                 runBtn: event.runBtn,
//             })
            
//         }
        
        
    }
}

export default connect( mapStateToProps, mapDispatchToProps)(ListComputeTemplates);
