import React from 'react'
// import ReactDOM from 'react-dom';
import { API, graphqlOperation } from 'aws-amplify'
// import { connect } from 'react-redux'
import classnames from "classnames";
// import { onCreateReqHistory } from './../../graphql/subscriptions'
import { listResources } from '../graphql/queries';
import { createComputeType, createResource } from '../graphql/mutations'

import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Container,
    Button,
    Input,
    Col,
    Collapse,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";


class NewComputeTypeForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            launchTemplateid: '',
            name: '',
            sqsurl: '',
            snsarn: '',
            sqsresourceNavPills: '',
            sqsresource: {name: 'Select SQS Queue'},
            snsresourceNavPills: '',
            snsresource: {name: 'Select SNS Topic'},
            computedropdownOpen: false,
            snsdropdownOpen: false,
            computeresourceNavPills: '',
            computeresource: {name: 'Select Compute Resource'},
            userData: '',
            visTimeout: '',
            maxAttempts: '',
            instanceSize: '',
            type: ''
        }
        this.getResources()
        this.setState({sqsresource: this.state.sqsresource.name})
        this.setState({snsresource: this.state.snsresource.name})
    }

    handleNameChange = event => {
        this.setState({name: event.target.value})
        // console.log(event.target.value)
    }

    handleLaunchTemplateChange = event => {
        this.setState({launchTemplateid: event.target.value})
        // console.log(event.target.value)
    }

    handleUserDataChange = event => {
        this.setState({userData: event.target.value})
        // console.log(event.target.value)
    }

    onButtonClick = async() => {
        const snsresponse = await API.graphql(graphqlOperation(createResource, {input: {
            name: 'mooserun-' + this.state.name,
            status: 'New',
            type: 'SNS',
        }}))
        const snsid = snsresponse.data.createResource.id
        console.log(snsresponse)

        // create sqs queue for this type
        // get resource id 

        const sqsvalues = {
            name: 'mooserun-' + this.state.name,
            status: 'New',
            type: 'SQS',
            subtype: 'Primary',
            sqsmaxAttempts: this.state.maxAttempts,
            sqsVisibilityTimeout: this.state.visTimeout,
        }
        
        console.log(sqsvalues)
        const sqsresponse = await API.graphql(graphqlOperation(createResource, {input: sqsvalues}))
        const sqsid = sqsresponse.data.createResource.id



        // create compute type 
        const values = {
            name: this.state.name,
            computeResource: this.state.computeresource.id,
            sqsResource: sqsid,
            snsResource: snsid,
            computeSize: this.state.instanceSize,
            type: this.state.type
        }
        
        console.log('Submitted Values', values)
        const ctResponse = await API.graphql(graphqlOperation(createComputeType, {input: values}))
        console.log(ctResponse)


    }

    

    getResources = async() => {
        let CEData = await API.graphql(graphqlOperation(listResources));

        let CEItems = CEData.data.listResources.items;
        
        // console.log(CEItems)
        let filteredSQSResources = [...CEItems.filter( CEItems => CEItems.type == 'SQS' )]
        // console.log(filteredSQSResources)
        let sqsresources = [...filteredSQSResources.filter( filteredSQSResources => filteredSQSResources.status !== 'Deleted' )]
        // console.log(sqsresources)


        this.setState({
            SQSResources: sqsresources.map((resource, index) => (
                        <DropdownItem key={resource.id ? resource.id : index}
                            aria-selected={this.state.resourceNavPills === index}
                            className={classnames("mb-sm-3 mb-md-0", {
                                active: this.state.snsresourceNavPills === index
                            })}
                            onClick={e => this.setSelectedSQS(resource, index)}
                            role="tab"
                            >
                            {resource.name}
                            {/* </a> */}
                        </DropdownItem>
                ))
            });


        let filteredSNSResources = [...CEItems.filter( CEItems => CEItems.type == 'SNS' )]
        // console.log(filteredSQSResources)
        let snsresources = [...filteredSNSResources.filter( filteredSNSResources => filteredSNSResources.status !== 'Deleted' )]
        // console.log(snsresources)


        this.setState({
            SNSResources: snsresources.map((resource, index) => (
                        <DropdownItem key={resource.id ? resource.id : index}
                            aria-selected={this.state.resourceNavPills === index}
                            className={classnames("mb-sm-3 mb-md-0", {
                                active: this.state.snsresourceNavPills === index
                            })}
                            onClick={e => this.setSelectedSNS(resource, index)}
                            role="tab"
                            >
                            {resource.name}
                            {/* </a> */}
                        </DropdownItem>
                ))
            });
        
        let filteredComputeResources = [...CEItems.filter( CEItems => CEItems.type == 'Compute' )]
        let computeResources = [...filteredComputeResources.filter( filteredComputeResources => filteredComputeResources.status !== 'Deleted' )]


        this.setState({
            ComputeResources: computeResources.map((resource, index) => (
                        <DropdownItem key={resource.id ? resource.id : index}
                            aria-selected={this.state.computeresourceNavPills === index}
                            className={classnames("mb-sm-3 mb-md-0", {
                                active: this.state.computeresourceNavPills === index
                            })}
                            onClick={e => this.setSelectedCompute(resource, index)}
                            role="tab"
                            >
                            {resource.name}
                            {/* </a> */}
                        </DropdownItem>
                ))
            });
    }

    setSelectedSQS = async(resource, index) => {
        // console.log(computeType)
        this.setState({sqsresourceNavPills: index});
        this.setState({sqsresource: resource});
        this.getResources()
        this.setState({sqsurl: resource.url})
        // console.log(resource.url)
    }

    setSelectedCompute = async(resource, index) => {
        // console.log(computeType)
        this.setState({computeresourceNavPills: index});
        this.setState({computeresource: resource});
        this.getResources()
        // console.log(resource.url)
    }

    setSelectedSNS = async(resource, index) => {
        console.log(resource)
        this.setState({snsresourceNavPills: index});
        this.setState({snsresource: resource});
        this.getResources()
        this.setState({snsarn: resource.arn})
        // console.log(resource.arn)
    }

    toggleSQSResourcesDropdown = () => {
        if(this.state.sqsdropdownOpen){
            this.setState({sqsdropdownOpen: false})
        }
        else{
            this.setState({sqsdropdownOpen: true})
        }
        
    }

    toggleSNSResourcesDropdown = () => {
        if(this.state.snsdropdownOpen){
            this.setState({snsdropdownOpen: false})
        }
        else{
            this.setState({snsdropdownOpen: true})
        }
        
    }

    togglecomputeResourcesDropdown = () => {
        if(this.state.computedropdownOpen){
            this.setState({computedropdownOpen: false})
        }
        else{
            this.setState({computedropdownOpen: true})
        }
        
    }
    handleMaxAttemptsChange = event => {
        this.setState({maxAttempts: event.target.value})
        // console.log(event.target.value)
    }

    handlevisTimeoutChange = event => {
        this.setState({visTimeout: event.target.value})
        // console.log(event.target.value)
    }
    handleInstanceSizeChange = event => {
        this.setState({instanceSize: event.target.value})
        console.log(event.target.value)
    }

    handleTypeChange = event => {
        this.setState({type: event.target.value})
        console.log(event.target.value)
    }

    
    
    render() {
        return(
            <>
                <Container>
                    <Row>
                        <Input placeholder="Name" type="text" required="true" value={this.state.name} onChange={this.handleNameChange} /> 
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <h3>Compute</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Dropdown isOpen={this.state.computedropdownOpen} toggle={this.togglecomputeResourcesDropdown}>
                                <DropdownToggle caret>
                                    {this.state.computeresource.name}
                                </DropdownToggle>
                                <DropdownMenu right>
                                    {this.state.ComputeResources}
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                        <Col>
                            <Input placeholder="Instance Size (ex: t3.micro)" type="text" value={this.state.instanceSize} onChange={this.handleInstanceSizeChange} /> 
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <h3>SQS</h3>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col>
                            <Input placeholder="Max Attempts" required="true" type="number" value={this.state.maxAttempts} onChange={this.handleMaxAttemptsChange} /> 
                        </Col>
                        <Col>
                            <Input placeholder="Visibility Timeout (Seconds)" required="true" type="number" value={this.state.visTimeout} onChange={this.handlevisTimeoutChange} /> 
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Input placeholder="Server Template Type" type="text" value={this.state.type} onChange={this.handleTypeChange} /> 
                        </Col>
                    </Row>
                    {/* <Row>
                        <Input placeholder="User Data" type="textarea" value={this.state.userData} onChange={this.handleUserDataChange} /> 
                    </Row> */}
                    

                    {/* <Row>
                        <Col>
                            <Dropdown isOpen={this.state.snsdropdownOpen} toggle={this.toggleSNSResourcesDropdown}>
                                <DropdownToggle caret>
                                    {this.state.snsresource.name}
                                </DropdownToggle>
                                <DropdownMenu right>
                                    {this.state.SNSResources}
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                    </Row> */}
                    {/* <Row>
                        <Input placeholder="SNS ARN" type="text" value={this.state.snsarn} onChange={this.handleSNSARNChange} /> 
                    </Row> */}
                    <br />
                    <Row>
                        <Button className="btn btn-outline-danger" onClick={ e => this.onButtonClick(  ) }>Submit</Button>
                    </Row>
                </Container>
            </>
        )
    }
    

}

// export default RunRequestBtn;





// const mapStateToProps = state => {
//     return {
//         currentQueryID: state.selectedQueryID,
//         currentQueryName: state.selectedQueryName,
//         currentQueryCurrent: state.selectedQueryStayCurrent,
//         currentQueryMarkets: state.selectedQueryMarkets,
//         currentQueryConsumers: state.selectedQueryConsumers,
//         currentQueryStatus: state.selectedQueryStatus,
//         // currentQueryLastRunDataVersion: state.selectedQueryLastRunDataVersion,
//         currentDataVersion: state.currentDataVersion
//         // UsersQuerys: state.UsersQuerys, // replacing reqs 
//     };
// }

// const mapDispatchToProps = dispatch => {
    
//     return {
//         onUpdateReq: (event) => {
//             dispatch({type: 'UPDATESELECTEDQUERYSTATUS', 
//                 currentQueryStatus: 'Active', 
//             })
//         }
        
        
//     }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(RunRequestBtn);
export default NewComputeTypeForm