import React from 'react'
// import ReactDOM from 'react-dom';
import { API, graphqlOperation } from 'aws-amplify'
// import { connect } from 'react-redux'
// import classnames from "classnames";
// import { onCreateReqHistory } from './../../graphql/subscriptions'
import { createResource } from '../graphql/mutations'

import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Container,
    Button,
    Input,
    Col,
    Collapse
} from "reactstrap";


class NewInstanceForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            Instanceid: '',
            name: '',
            region: ''
        }
        
    }

    handleNameChange = event => {
        this.setState({name: event.target.value})
        console.log(event.target.value)
    }

    handleInstanceidChange = event => {
        this.setState({Instanceid: event.target.value})
        console.log(event.target.value)
    }

    handleRegionChange = event => {
        this.setState({region: event.target.value})
        console.log(event.target.value)
    }

    onButtonClick = async() => {
        let tags = {}
        tags['region'] = this.state.region

        const response = await API.graphql(graphqlOperation(createResource, {input: { name: this.state.name, status: 'New', type: 'Compute', subtype: 'Instance', computeTypeid: this.state.Instanceid, tags: [JSON.stringify(tags),]}}))
        console.log(response)
    }

    render() {
        return(
            <>
                <Container>
                    <Row>
                        <Col>
                            <Input placeholder="Name" type="text" required="true" value={this.state.name} onChange={this.handleNameChange} /> 
                        </Col>
                        <Col>
                            <Input placeholder="Instance ID" required="true" type="text" value={this.state.Instanceid} onChange={this.handleInstanceidChange} /> 
                        </Col>
                        <Col>
                            <Input placeholder="Region" required="true" type="text" value={this.state.region} onChange={this.handleRegionChange} /> 
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button className="btn btn-outline-danger" onClick={ e => this.onButtonClick(  ) }>Submit</Button>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}


export default NewInstanceForm



