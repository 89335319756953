import React from 'react'
// import ReactDOM from 'react-dom';
import { listPipelineExecutions, listComputeTemplates, listPipelines, listComputeExecutions } from '../graphql/queries'
import { updatePipelineExecution, createPipelineExecutionHistorical } from '../graphql/mutations'
import * as subscriptions from '../graphql/subscriptions';

import { API, graphqlOperation, Auth, Storage } from 'aws-amplify'
// import { connect } from 'react-redux'
// import { withAuthenticator } from '@aws-amplify/ui-react'
import classnames from "classnames";
import { 
    Col,
    Row,
    Card,
    ListGroup,
    CardHeader,
    CardTitle,
    Collapse,
    CardBody,
    ListGroupItem,
    Nav,
    NavItem,
    NavLink,
    Button,

} from "reactstrap";



class PipelineExecutions extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            ctNavPills: -1,
            listUsersReqs: [],
            selectedTemplate: [],
            setActive: false,
            radio: -1,
            selectedQueryID: '',
            selectedList: '',
            ComputeTemplates: [],
            navPills: 1,
            pipelineList: '',
            templateNavPills: -1,
            SelectedTemplateTasks: '',
            selectedTasks: [],
            selectedExecutionInfo: '',
            openedCollapses: [""],
            taskLists: [],
            selectedExecutionTaskInfo: {},
            selectedPipelineTasks: [],
            TaskData: '',
            selectedTask: {},
            TaskNavPills: -1,
            pipelineData: '',
            ComputeExecutions: [],
            TaskComputeData: '',

        }
        this.getAllComputeTemplates()
        this.getPipelinesData()
        this.displayList()
        this.getComputeExecution()


        const onCreatePipelineExecution = API.graphql(
            graphqlOperation(subscriptions.onCreatePipelineExecution)
        ).subscribe({
            next: ({ provider, value }) => {
                this.getAllComputeTemplates()
                this.getPipelinesData()
                this.displayList()
                this.getComputeExecution()
                console.log({ provider, value })},
            error: error => console.warn(error)
        });
        const onUpdatePipelineExecution = API.graphql(
            graphqlOperation(subscriptions.onUpdatePipelineExecution)
        ).subscribe({
            next: ({ provider, value }) => {
                this.getAllComputeTemplates()
                this.getPipelinesData()
                this.displayList()
                this.getComputeExecution()
                console.log({ provider, value })},
            error: error => console.warn(error)
        });
        const onDeletePipelineExecution = API.graphql(
            graphqlOperation(subscriptions.onDeletePipelineExecution)
        ).subscribe({
            next: ({ provider, value }) => {
                this.getAllComputeTemplates()
                this.getPipelinesData()
                this.displayList()
                this.getComputeExecution()
                console.log({ provider, value })},
            error: error => console.warn(error)
        });
    }
    toggleNavs = (e, state, index) => {
        e.preventDefault();
        this.setState({[state]: index});
        this.setSelected(e,index)
        // this.displayList()
    };

    toggleNavPills = (e, state, index) => {
        e.preventDefault();
        this.setState({[state]: index});
        this.displayList()
    };

    setInput(key, value) {
        this.setState([key], value)
    }



    componentDidMount() {
        this.displayList();
        
    }
    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: []
            });
        } else {
            this.setState({
                openedCollapses: [collapse]
            });
            
            
        }
    };



    setSelected = async(ct, index) => {
        console.log({ct})
        this.setState({selectedExecutionInfo: ct})
        this.setState({ctNavPills: index});
        // const taskData =this.getTaskDataFromID(ct.taskListid)
        this.setState({selectedPipelineTasks: ct.taskStatus})
        this.displaySelectedPipelineTasks(ct.taskStatus)
        this.displayList()
        this.getComputeExecution()
        this.setSelectedPipeline(ct)
        // console.log(response)
    }


    
    displayphase = (tasks, phase) => {
        console.log({tasks})
        let allTasks = []

        tasks.forEach((t, index) => {
            // console.log({t})
            allTasks.push(JSON.parse(t))
        })
        return allTasks.map((task, index) => (
            <ListGroupItem key={task['id'] ? task['id'] : index}
                aria-selected={this.state.TaskNavPills === phase + '-' + index}
                className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.TaskNavPills === phase + '-' + index
                })}
                onClick={e => this.setSelectedTask(task, phase + '-' + index)}
                role="tab"
                >
                {task.name}
            </ListGroupItem>
        ))
    }

    displaySelectedPipelineTasks = (pipelineTasks) => {
        let allTasks = []
        console.log({pipelineTasks})
        this.setState({listTaskLists: pipelineTasks.map((pt, index) => (
                <div class="card" key={index}>
                    <div class="card-header" id={'heading-' + index} >
                        <h5 class="mb-0">
                            Phase {index}
                        </h5>
                    </div>
                    <div class="card-body">
                        {this.displayphase(pt, index)}
                    </div>
                </div>
        ))})
    }

    getComputeExecution = async() => {
        let CEData = await API.graphql(graphqlOperation(listComputeExecutions));
        // console.log(CTData)
        let CEItems = CEData.data.listComputeExecutions.items;
        this.setState({ ComputeExecutions: [ ...CEItems.sort( function (a,b){ return new Date(b.createdAt) - new Date(a.createdAt)})]})
    }

    setSelectedTask = async(task, index) => {
        console.log(task)
        this.setState({selectedTask: task})
        this.setState({TaskNavPills: index});


        if("computeExecutionID" in task){
            this.setSelectedTaskCompute(task.computeExecutionID)
        } else{
            this.setState({ TaskComputeData: ''})
        }
        console.log(task.Status)
        console.log(task.Status == 'Failed')
        if( task.Status == 'Failed'){
            this.setState({ TaskData: (<>
                <h3>Pipeline Execution Data</h3>
                <ul>
                    <li>Name: {task.name}</li>
                    <li>Phase: {task.phasenum}</li>
                    <li>TaskNum: {task.taskNum}</li>
                    <li>Status: {task.Status}</li>
                    <li>Runtime: {task.maxRunTime}</li>
                    <li>Arguments: {task.arguments}</li>
                    <li>computeExecution: {task.computeExecutionID}</li>
                    <Button className="btn btn-outline-success" onClick={ e => this.recoverPipeline(task) } >Restart</Button>
                    {/* <li>{task.tags}</li> */}
                </ul>
    
            </>)})
        } else if( task.Status == 'TimedOut'){
            this.setState({ TaskData: (<>
                <h3>Pipeline Execution Data</h3>
                <ul>
                    <li>Name: {task.name}</li>
                    <li>Phase: {task.phasenum}</li>
                    <li>TaskNum: {task.taskNum}</li>
                    <li>Status: {task.Status}</li>
                    <li>Runtime: {task.maxRunTime}</li>
                    <li>Arguments: {task.arguments}</li>
                    <li>computeExecution: {task.computeExecutionID}</li>
                    <Button className="btn btn-outline-success" onClick={ e => this.recoverPipeline(task) } >Restart</Button>
                    {/* <li>{task.type}</li>
                    <li>{task.tags}</li> */}
                </ul>
    
            </>)})
        } else{

        this.setState({ TaskData: (<>
            <h3>Pipeline Execution Data</h3>
            <ul>
                <li>Name: {task.name}</li>
                <li>Phase: {task.phasenum}</li>
                <li>TaskNum: {task.taskNum}</li>
                <li>Status: {task.Status}</li>
                <li>Runtime: {task.maxRunTime}</li>
                <li>Arguments: {task.arguments}</li>
                <li>computeExecution: {task.computeExecutionID}</li>
                {/* <li>{task.type}</li>
                <li>{task.tags}</li> */}
            </ul>

        </>)})}}
        
    recoverPipeline = async(taskdata) => {
        let pipelineData = this.state.selectedExecutionInfo

        const phase = taskdata.phasenum
        const task = taskdata.taskNum
        let updatedpipeline = {}
        updatedpipeline['id'] = pipelineData['id']
        updatedpipeline['status'] = 'Recovering'
        updatedpipeline['taskStatus'] = pipelineData['taskStatus']
        let taskDict = JSON.parse(updatedpipeline['taskStatus'][phase][task])
        taskDict['Status'] = 'Restarting'
        updatedpipeline['taskStatus'][phase][task] = JSON.stringify(taskDict)
        // console.log(JSON.parse(pipelineData['taskStatus'][phase][task]))
        console.log({updatedpipeline})
        const updatedpipelineresponse = await API.graphql({ query: updatePipelineExecution, variables: {input: updatedpipeline}});
        console.log({updatedpipelineresponse})

        let execution = updatedpipelineresponse.data.updatePipelineExecution
        // console.log({execution})
        execution['pipelineExid'] = execution['id']
        delete execution['id']
        delete execution['createdAt']
        delete execution['updatedAt']
        delete execution['owner']
        // console.log({execution})
        const cpehresponse = await API.graphql(graphqlOperation(createPipelineExecutionHistorical, {input: execution}))
        // console.log({cpehresponse})
    }



    setSelectedTaskCompute = (computeExecutionID) => {
        const computeExecutions = this.state.ComputeExecutions
        const computeExecution = [...computeExecutions.filter( computeExecution => computeExecution.id === computeExecutionID  )]
        console.log({computeExecution})
        if(computeExecution.length === 1){
            this.setState({ TaskComputeData: (<>
                <h3>Compute Execution Data</h3>
                <ul>
                    <li>Status: {computeExecution[0]['status']}</li>
                    <li>Arguments: {computeExecution[0]['arguments']}</li>
                    {/* <li>Logfile: {computeExecution[0]['logfile']}</li> */}
                    <li>{this.logfileDownloadButton(computeExecution[0])}</li>
                </ul>
            </>)})
        } 
        else{
            this.setState({ TaskComputeData: ''})
        }
    }

    setSelectedPipeline = async(pipeline) => {
        console.log(pipeline)
        if( pipeline.status == 'Recovered'){
            this.setState({ pipelineData: (<>
                <ul>
                    <li>Name: {pipeline.name}</li>
                    <li>Phase: {pipeline.currentTask}</li>
                    <li>Status: {pipeline.status}</li>
                    <li><Button className="btn btn-outline-success" onClick={ e => this.setPipelineToRunning(pipeline) } >Start From Next Phase</Button></li>
                    {/* <li>Runtime: {pipeline.tags}</li> */}
                    {/* <li>Arguments: {pipeline.arguments}</li> */}
                </ul>
            </>)})
        } else {
            this.setState({ pipelineData: (<>
                <ul>
                    <li>Name: {pipeline.name}</li>
                    <li>Phase: {pipeline.currentTask}</li>
                    <li>Status: {pipeline.status}</li>
                    {/* <li>Runtime: {pipeline.tags}</li> */}
                    {/* <li>Arguments: {pipeline.arguments}</li> */}
                </ul>
            </>)})
        }
    }

    setPipelineToRunning = async(pipeline) => {
        // pipeline['status'] = 'Running'
        // console.log({pipeline})

        let updatedpipeline = {}
        updatedpipeline['id'] = pipeline['id']
        updatedpipeline['status'] = 'Running'
        console.log({updatedpipeline})
        const updatedpipelineresponse = await API.graphql({ query: updatePipelineExecution, variables: {input: updatedpipeline}});
        console.log({updatedpipelineresponse})
        let execution = updatedpipelineresponse.data.updatePipelineExecution
        // console.log({execution})
        execution['pipelineExid'] = execution['id']
        delete execution['id']
        delete execution['createdAt']
        delete execution['updatedAt']
        delete execution['owner']
        // console.log({execution})
        const cpehresponse = await API.graphql(graphqlOperation(createPipelineExecutionHistorical, {input: execution}))
        // console.log({cpehresponse})

    }




    getPipelinesData=async()=>{
        try {
            let Data = await API.graphql(graphqlOperation(listPipelines));
            let Items = Data.data.listPipelines.items;
            this.setState({pipelines: Items})
            // console.log(Items)
        } catch (err) {
            console.log(err);
        }
    }

    getPipelineFromID=(id)=>{
        const pipelines = this.state.pipelines
        const pipeline = [...pipelines.filter( pipelines => pipelines.id === id )]
        console.log(pipeline)
        return(pipeline)
    }

    getAllComputeTemplates = async() => {
        let CEData = await API.graphql(graphqlOperation(listComputeTemplates));
        let CEItems = CEData.data.listComputeTemplates.items;
        this.setState({allComputeTemplates: CEItems})
    }


    displayList = async() => {
        try {
            let CEData = await API.graphql(graphqlOperation(listPipelineExecutions));
            let CEItems = CEData.data.listPipelineExecutions.items;
            let CEdata1 = CEData.data.listPipelineExecutions
            // console.log({CEdata1})
            // console.log(CEItems.length)
            // console.log({CEItems})
            while(CEdata1.nextToken != null ){
                let nextToken = CEdata1.nextToken;
                let CEData = await API.graphql({ query: listPipelineExecutions, variables: { limit: 100, nextToken }});
                let CEdata2 = {}
                CEdata2 = CEData.data.listPipelineExecutions
                CEItems = CEItems.concat(CEdata2.items)
            }
            // console.log(CEItems.length)
            // console.log({CEItems})


            
            // console.log(CEItems)
            // let filteredCEItems = [...CEItems.filter( CEItems => CEItems.computeTemplate === this.props.selectedTemplateID )]
            let filteredCEItems
            if(this.state.navPills === 2) {
                // console.log('apply filter for Coimpleted Jobs')
                filteredCEItems = [...CEItems.filter( CEItems => CEItems.status === 'Completed' )]
            } else if(this.state.navPills === 3) {
                // console.log('apply filter for Coimpleted Jobs')
                filteredCEItems = [...CEItems.filter( CEItems => CEItems.status === 'Failed' )]
            } else if(this.state.navPills === 1) {
                // console.log('apply filter for Coimpleted Jobs')
                filteredCEItems = [...CEItems.filter( CEItems => CEItems.status !== 'Completed' )]
                filteredCEItems = [...filteredCEItems.filter( CEItems => CEItems.status !== 'Failed' )]
            }
            // console.log(filteredCEItems)
            // let reqs = [...ReqItems.filter( ReqItems => ReqItems.deleted === false )]
            // console.log(this.state.selectedQueryID)
            this.setState({ ComputeExecutions: [ ...filteredCEItems.sort( function (a,b){ return new Date(b.createdAt) - new Date(a.createdAt)})]})
            let CEs = [ ...this.state.ComputeExecutions ]
            // console.log(CEs)
            this.setState({pipelineList: filteredCEItems.map((ct, index) => (
                    <ListGroupItem key={ct.id ? ct.id : index}
                        aria-selected={this.state.ctNavPills === index}
                        className={classnames("table-hover mb-sm-3 mb-md-0", {
                            active: this.state.ctNavPills === index
                        })}
                        onClick={e => this.setSelected(ct, index)}
                        role="tab"
                        >
                        {ct.createdAt}:  {ct.name}                        
                    </ListGroupItem>    
                ))
            });
        } catch (err) {
            console.log(err);
        }
    }

    downloadBlob(blob, filename) {
        console.log(blob)
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        console.log(url)
        a.download = filename || 'download';
        const clickHandler = () => {
            setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
            }, 150);
        };
        a.addEventListener('click', clickHandler, false);
        a.click();
        return a;
    }

    getSignedURL = async( logfile ) => {
        let filename = 'server-output.log'
        console.log(logfile)
        await Storage.get(logfile , { download: true }).then(res => this.downloadBlob(res.Body, filename))
    }

    logfileDownloadButton = (ce) => {
        console.log(ce)
        if(ce.status === 'Completed'){
            return(
                <Button className="btn btn-outline-success" onClick = { e => this.getSignedURL( ce['logfile'] + 'server-output.log' ) } >Download</Button>
            )
        } else if(ce.status === 'Failed'){
            return(
                <Button className="btn btn-outline-danger" onClick = { e => this.getSignedURL( ce['logfile'] + 'server-output.log' ) } >Download</Button>
            )
        }    
    }


    render  () {
            return( <>
                <Card>
                    <CardHeader  role="tab" onClick={() => this.collapsesToggle("PipelineList")} aria-expanded={this.state.openedCollapses.includes("PipelineList")}>
                        <Row>
                            <Col className='col-10'>
                                <CardTitle className="h2 mb-0">Running Pipelines</CardTitle>
                            </Col>
                            <Col className='col-2'>
                                <span className="custom-toggle">
                                    <input type="checkbox"  checked={this.state.openedCollapses.includes("PipelineList")} />
                                    <span className="custom-toggle-slider rounded-circle"  />
                                </span>
                            </Col>
                        </Row>
                    </CardHeader>
                    <Collapse role="tabpanel" isOpen={this.state.openedCollapses.includes("PipelineList")}>
                        <CardBody>
                            <Row>
                                <Col>
                                    <Nav className="nav-fill flex-column flex-sm-row" id="tabs-text" pills role="tablist">
                                        <NavItem>
                                            <NavLink aria-selected={this.state.navPills === 1} className={classnames("mb-sm-3 mb-md-0", { active: this.state.navPills === 1 })} onClick={e => this.toggleNavPills(e, "navPills", 1)} href="#pablo" role="tab">
                                                Active
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink aria-selected={this.state.navPills === 2} className={classnames("mb-sm-3 mb-md-0", { active: this.state.navPills === 2 })} onClick={e => this.toggleNavPills(e, "navPills", 2)} href="#pablo" role="tab">
                                                Completed
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink aria-selected={this.state.navPills === 3} className={classnames("mb-sm-3 mb-md-0", { active: this.state.navPills === 3 })} onClick={e => this.toggleNavPills(e, "navPills", 3)} href="#pablo" role="tab">
                                                Failed
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col className='col-4'>
                                    <ListGroup>
                                        {this.state.pipelineList}
                                    </ListGroup>
                                </Col>
                                <Col className='col-4'>
                                    {this.state.pipelineData}
                                    <hr />
                                    {this.state.listTaskLists}
                                </Col>
                                <Col className='col-4'>
                                    {this.state.TaskData}
                                    <hr />
                                    {this.state.TaskComputeData}
                                </Col>
                            </Row>
                        </CardBody>
                    </Collapse>
                </Card>
            </>
        )
    }
}


export default (PipelineExecutions);
