import React from 'react'
// import ReactDOM from 'react-dom';
import { API, graphqlOperation } from 'aws-amplify'
// import { connect } from 'react-redux'
import classnames from "classnames";
// import { onCreateReqHistory } from './../../graphql/subscriptions'
import { listComputeTemplates, listPipelines } from '../graphql/queries';
import { createScheduledEvent, } from '../graphql/mutations'

import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Container,
    Button,
    Input,
    Col,
    Collapse,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Form,
    FormGroup,
    Label,
    UncontrolledButtonDropdown,
    ListGroupItem,
    ListGroup
} from "reactstrap";



class NewScheduleForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            name: '',
            PrimaryType: 'Select Primary Schedule Type',
            selectPrimaryTypeData: '',
            SecondaryType: 'Select Secondary Schedule Type',
            TertiaryType:  'Select Tertiary Schedule Type',
            SecondaryTypeOpen: false,
            TertiaryTypeOpen: false,
            displaySecondaryTypeSelect: '',
            selectSecondaryTypeData: '',
            selectedPrimaryTypeData: [],
            SecondaryTypeArr: [],
            TertiaryTypeArr: [],
            // selectedSecondaryTypeData: 0,
            // selectedTertiaryTypeData: 0,
            ComputeTemplates: '',
            listTaskLists: '',
            SelectedType: 'Select Compute Template',
            SelectedPipelineType: 'Select Pipeline',
            SelectedTaskType: 'Select Task Type',
            taskTypeSelect: [],
            SecondaryTypeSelect: '',
            TimeList: '',
            OneTimeNextRunDateTime: {},
            SettmpTimeTimeofDay: '',

        }
    }

    handleNameChange = event => {
        this.setState({name: event.target.value})
        // console.log(event.target.value)
    }




    setSelectedPrimaryType = (type) => {
        // console.log(computeType)
        // this.togglePrimaryTypeDropdown()
        this.setState({PrimaryTypeOpen: false})
        this.setState({PrimaryType: type});
        this.setState({ selectedPrimaryTypeData: [] });
        this.displayPrimaryTypeData(type);

        
        // console.log(resource.url)
    }


    togglePrimaryTypeDropdown = () => {
        if(this.state.PrimaryTypeOpen){
            this.setState({PrimaryTypeOpen: false})
        }
        else{
            this.setState({PrimaryTypeOpen: true})
        }
    }


    onChangeSetDateOneTime = (event) => {
        // console.log(event.target.value)
        const dateArr = event.target.value.toString().split('-')
        // console.log({dateArr})
        let NextRunDateTime = this.state.OneTimeNextRunDateTime
        NextRunDateTime['Year'] = dateArr[0]
        NextRunDateTime['MonthOfTheYear'] = dateArr[1]
        NextRunDateTime['DayOfTheMonth'] = dateArr[2]
        // console.log({NextRunDateTime})
        this.setState({OneTimeNextRunDateTime: NextRunDateTime })
    }


    onChangeSetTimeOneTime = (event) => {
        console.log(event.target.value)
        const timeArr = event.target.value.toString().split(':')
        // console.log({timeArr})
        let NextRunDateTime = this.state.OneTimeNextRunDateTime
        NextRunDateTime['HourOfTheDay'] = timeArr[0]
        NextRunDateTime['MinuteOfTheHour'] = timeArr[1]
        // console.log({NextRunDateTime})
        this.setState({OneTimeNextRunDateTime: NextRunDateTime })
    }


    displayPrimaryTypeData = (type) => {
        // this.setState({selectPrimaryType: {type}})
        // this.displaySecondaryTypeSelect(type)
        if(type == ''){
            const type = this.state.PrimaryType
        }
        if(type === 'One Time' ){
            this.setState({selectPrimaryTypeData: <>
                    <Row>
                        <Col>
                            <Label for="Date">
                                Date
                            </Label>
                            <Input id="Date" name="date" required placeholder="date placeholder" onChange={e => {this.onChangeSetDateOneTime(e)}} type="date" />
                            <Label for="Time">
                            Time
                            </Label>
                            <Input id="Time" name="time" required placeholder="time placeholder" onChange={e => {this.onChangeSetTimeOneTime(e)}} type="time" />
                        </Col>
                    </Row>
                </>})
        } else if(type === 'Days Of the Month' ){
            // display list of days that can select multiple
            this.setState({selectPrimaryTypeData: <>
                        <Input id="monthDaySelect" multiple name="select" required type="select"  onChange={e => {this.onChangeMulti(e)}}>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                            <option value='6'>6</option>
                            <option value='7'>7</option>
                            <option value='8'>8</option>
                            <option value='9'>9</option>
                            <option value='10'>10</option>
                            <option value='11'>11</option>
                            <option value='12'>12</option>
                            <option value='13'>13</option>
                            <option value='14'>14</option>
                            <option value='15'>15</option>
                            <option value='16'>16</option>
                            <option value='17'>17</option>
                            <option value='18'>18</option>
                            <option value='19'>19</option>
                            <option value='20'>20</option>
                            <option value='21'>21</option>
                            <option value='22'>22</option>
                            <option value='23'>23</option>
                            <option value='24'>24</option>
                            <option value='25'>25</option>
                            <option value='26'>26</option>
                            <option value='27'>27</option>
                            <option value='28'>28</option>
                            <option value='29'>29</option>
                            <option value='30'>30</option>
                            <option value='31'>31</option>
                        </Input>
            </>})
            this.setState({displaySecondaryTypeSelect: <>
                <DropdownItem key='Times Of Day'
                    aria-selected={this.state.SecondaryType === 'Times Of Day'}
                    className={classnames("mb-sm-3 mb-md-0", {
                        active: this.state.SecondaryType === 'Times Of Day'
                    })}
                    onClick={e => this.setSelectedSecondaryType('Times Of Day')}
                    role="tab"
                    >
                    Times Of Day
                </DropdownItem>
                <DropdownItem key='Every X Minutes'
                    aria-selected={this.state.SecondaryType === 'Every X Minutes'}
                    className={classnames("mb-sm-3 mb-md-0", {
                        active: this.state.SecondaryType === 'Every X Minutes'
                    })}
                    onClick={e => this.setSelectedSecondaryType('Every X Minutes')}
                    role="tab"
                    >
                    Every X Minutes
                </DropdownItem>
            </>})
        } else if(type === 'Days Of the Week' ){
            // display list of days of the week that can select multiple
            this.setState({selectPrimaryTypeData: <>
                <Input type="select" name="selectMulti" id="exampleSelect" multiple required onChange={e => {this.onChangeMulti(e)}}>
                    <option value='Sunday'>Sunday</option>
                    <option value='Monday'>Monday</option>
                    <option value='Tuesday'>Tuesday</option>
                    <option value='Wednesday'>Wednesday</option>
                    <option value='Thursday'>Thursday</option>
                    <option value='Friday'>Friday</option>
                    <option value='Saturday'>Saturday</option>
                </Input>
                </>})
            this.setState({displaySecondaryTypeSelect: <>
                <DropdownItem key='Times Of Day'
                    aria-selected={this.state.SecondaryType === 'Times Of Day'}
                    className={classnames("mb-sm-3 mb-md-0", {
                        active: this.state.SecondaryType === 'Times Of Day'
                    })}
                    onClick={e => this.setSelectedSecondaryType('Times Of Day')}
                    role="tab"
                    >
                    Times Of Day
                </DropdownItem>
                <DropdownItem key='Every X Minutes'
                    aria-selected={this.state.SecondaryType === 'Every X Minutes'}
                    className={classnames("mb-sm-3 mb-md-0", {
                        active: this.state.SecondaryType === 'Every X Minutes'
                    })}
                    onClick={e => this.setSelectedSecondaryType('Every X Minutes')}
                    role="tab"
                    >
                    Every X Minutes
                </DropdownItem>
            </>})
        } else if(type === 'Weeks Of the Month' ){
            // display week number 1-5 that can select multiple
            this.setState({selectPrimaryTypeData: <>
                <Input type="select" name="selectMulti" id="exampleSelect" multiple required onChange={e => {this.onChangeMulti(e)}}>
                    <option value='1'>1</option>
                    <option value='2'>2</option>
                    <option value='3'>3</option>
                    <option value='4'>4</option>
                    <option value='5'>5</option>
                </Input>
                </>})

            this.setState({displaySecondaryTypeSelect: <>
                <DropdownItem key='Days of Week'
                    aria-selected={this.state.SecondaryType === 'Days of Week'}
                    className={classnames("mb-sm-3 mb-md-0", { active: this.state.SecondaryType === 'Days of Week'})}
                    onClick={e => this.setSelectedSecondaryType('Days of Week')}
                    role="tab">
                    Days of the Week
                </DropdownItem>
                <DropdownItem key='Times Of Day'
                    aria-selected={this.state.SecondaryType === 'Times Of Day'}
                    className={classnames("mb-sm-3 mb-md-0", { active: this.state.SecondaryType === 'Times Of Day' })}
                    onClick={e => this.setSelectedSecondaryType('Times Of Day')}
                    role="tab">
                    Times Of Day
                </DropdownItem>
                <DropdownItem key='Every X Minutes'
                    aria-selected={this.state.SecondaryType === 'Every X Minutes'}
                    className={classnames("mb-sm-3 mb-md-0", { active: this.state.SecondaryType === 'Every X Minutes' })}
                    onClick={e => this.setSelectedSecondaryType('Every X Minutes')}
                    role="tab">
                    Every X Minutes
                </DropdownItem>
            </>})
        } else if(type === 'Daily' ){
            //     // this is un-needed
            this.setState({selectPrimaryTypeData: <></>})
            this.setState({displaySecondaryTypeSelect: <>
                <DropdownItem key='Times Of Day'
                    aria-selected={this.state.SecondaryType === 'Times Of Day'}
                    className={classnames("mb-sm-3 mb-md-0", {
                        active: this.state.SecondaryType === 'Times Of Day'
                    })}
                    onClick={e => this.setSelectedSecondaryType('Times Of Day')}
                    role="tab"
                    >
                    Times Of Day
                </DropdownItem>
                <DropdownItem key='Every X Minutes'
                    aria-selected={this.state.SecondaryType === 'Every X Minutes'}
                    className={classnames("mb-sm-3 mb-md-0", {
                        active: this.state.SecondaryType === 'Every X Minutes'
                    })}
                    onClick={e => this.setSelectedSecondaryType('Every X Minutes')}
                    role="tab"
                    >
                    Every X Minutes
                </DropdownItem>
            </>})
        }

        
        this.showSecondaryType();

    }

    onChangeMulti = (event) => {
        // console.log({event})
        let opts = [], opt;
        for (let i = 0, len = event.target.options.length; i < len; i++) {
            opt = event.target.options[i]
            if (opt.selected) {
                opts.push(opt.value)
            }
        }
        // console.log({opts})
        this.setState({ selectedPrimaryTypeData: opts })
    }

    onChangeMultiSecondary = (event) => {
        // console.log({event})
        let opts = [], opt;
        for (let i = 0, len = event.target.options.length; i < len; i++) {
            opt = event.target.options[i]
            if (opt.selected) {
                opts.push(opt.value)
            }
        }
        // console.log({opts})
        this.setState({ selectedSecondaryTypeData: opts })
    }

    onChangeSettmpTimeTimesofDay = (event) => {
        const time = event.target.value.toString().split(':')
        // console.log(event.target.value.split(':'))
        this.setState({SettmpTimeTimeofDay: time })
    }

    showSecondaryType = () => {
        if(this.state.PrimaryType === 'One Time'){
            this.setState({SecondaryTypeSelect: <></>})
        } else if(this.state.PrimaryType === ''){
            this.setState({SecondaryTypeSelect: <></>})
        } else{
            this.setState({SecondaryTypeSelect: <>
                <Row>
                    <Col>
                        <h3>Secondary Type</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Dropdown isOpen={this.state.SecondaryTypeOpen} toggle={this.toggleSecondaryTypeDropdown}>
                            <DropdownToggle caret>
                                {this.state.SecondaryType}
                            </DropdownToggle>
                            <DropdownMenu right>
                                {this.state.displaySecondaryTypeSelect}
                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                    <Col>
                        <h3>Secondary Type Data</h3>
                        {this.state.selectSecondaryTypeData}
                    </Col>
                </Row>
            </>})
        }
    }

    addTimeToSecondaryTypeData = () => {
        let tmparr = this.state.selectedSecondaryTypeData
        let timearr = this.state.SettmpTimeTimeofDay
        tmparr.push(JSON.stringify({hour: timearr[0], minute: timearr[1]}))
        // this.setState({SettmpTimeTimeofDay: '00:00'})
        this.setState({selectedSecondaryTypeData: tmparr })
        this.SecondarydisplayTimeList()
    }

    SecondarydisplayTimeList = () => {
        this.setState({SecondaryTimeList: this.state.selectedSecondaryTypeData.map((time, index)=> (
            <ListGroupItem key={index} className="mb-sm-3 mb-md-0" role="tab">
                {this.timeString(time)}
            </ListGroupItem>
        ))})
    }

    displaySecondaryTimesOfDay = () => {
        this.setState({selectSecondaryTypeData: <>
            <Row>
                <Label for="AddTime"> Time </Label>
                <Input id="AddTime" name="time" required placeholder="time placeholder" onChange={e => {this.onChangeSettmpTimeTimesofDay(e)}} type="time" />
                <Col>
                    <Button onClick={e => this.addTimeToSecondaryTypeData()}>Add Time</Button>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col>
                    <ListGroup>
                        {this.state.SecondaryTimeList}
                    </ListGroup>
                </Col>
            </Row>
        </>})
        // this.showSecondaryType()
    }



    
    setSelectedSecondaryType = (type) => {

        if(type == ''){
            const type = this.state.SecondaryType
        }
        this.setState({SecondaryTypeOpen: false})
        this.setState({SecondaryType: type})
        this.setState({ selectedSecondaryTypeData: [] })
        if(type == 'Every X Minutes'){
            this.setState({selectSecondaryTypeData: <>
                {/* <Input placeholder="Run Intervole Minutes" required="true" type="number" value={this.state.selectedSecondaryTypeData} onChange={this.updateSecondaryTypeArr} />  */}
                <Input placeholder="Run Intervole Minutes" required="true" type="number" required onChange={this.updateSecondaryTypeArr} /> 
            </>})
        } else if(type == 'Times Of Day'){
            this.displaySecondaryTimesOfDay()
        } else if(type == 'Days of Week'){
            this.setState({selectSecondaryTypeData: <>
                <Input type="select" name="selectMultiday2" id="secondarydayselect" multiple onChange={e => {this.onChangeMultiSecondary(e)}}>
                    <option value='Sunday'>Sunday</option>
                    <option value='Monday'>Monday</option>
                    <option value='Tuesday'>Tuesday</option>
                    <option value='Wednesday'>Wednesday</option>
                    <option value='Thursday'>Thursday</option>
                    <option value='Friday'>Friday</option>
                    <option value='Saturday'>Saturday</option>
                </Input>
            </>})
            this.setState({displayTertiaryTypeSelect: <>
                <DropdownItem key='Times Of Day'
                    aria-selected={this.state.TertiaryType === 'Times Of Day'}
                    className={classnames("mb-sm-3 mb-md-0", {
                        active: this.state.TertiaryType === 'Times Of Day'
                    })}
                    onClick={e => this.setSelectedTertiaryType('Times Of Day')}
                    role="tab"
                    >
                    Times Of Day
                </DropdownItem>
                <DropdownItem key='Every X Minutes'
                    aria-selected={this.state.TertiaryType === 'Every X Minutes'}
                    className={classnames("mb-sm-3 mb-md-0", {
                        active: this.state.TertiaryType === 'Every X Minutes'
                    })}
                    onClick={e => this.setSelectedTertiaryType('Every X Minutes')}
                    role="tab"
                    >
                    Every X Minutes
                </DropdownItem>
            </>})
        }
    }

    updateTaskType = (name) => {
        this.setState({SelectedTaskType: name})
        console.log(name)
        if(name == 'ComputeTemplates'){
            this.setState({SelectedType: 'Select Compute Template'})
            this.displayTemplateList()
        } else if (name == 'Pipelines'){
            this.setState({SelectedType: 'Select Pipeline'})
            this.displaypipelineList()
        }
    }



    displayTypeTemplates = (type, SelectedTaskType) => {
        this.setState({SelectedType: type })
        // this.displayTemplateList()
        // this.displaypipelineList()
        if(SelectedTaskType == 'ComputeTemplates'){
            this.displayTemplateList()
        } else if(SelectedTaskType == 'Pipelines'){
            this.displaypipelineList()
        }
    }

    selectType = (types) => {
        this.setState({ typeSelect: types.map((name, index) => (
                <DropdownItem className={classnames("dropdown-item" , { active: this.state.SelectedType === '' })} onClick={(e) => this.displayTypeTemplates(name, this.state.SelectedTaskType)} >
                        {name}
                </DropdownItem>
        ))})}

    displayTemplateList = async() => {
        try {
            let CTData = await API.graphql({ query: listComputeTemplates, variables: { limit: 100,  }});
            let CTItems = CTData.data.listComputeTemplates.items;
            let CTdata = CTData.data.listComputeTemplates
            while(CTdata.nextToken != null ){
                let nextToken = CTdata.nextToken;
                let CTData2 = await API.graphql({ query: listComputeTemplates, variables: { limit: 100, nextToken }});
                CTdata = {}
                CTdata = CTData2.data.listComputeTemplates
                CTItems = CTItems.concat(CTdata.items)
            }
            const types = ['All', 'None', ...new Set(CTItems.map(item => item.type))];
            if (this.state.SelectedType == 'None' | this.state.SelectedType == 'Select Compute Template'){
                this.setState({ ComputeTemplates: []})
            } 
            if (this.state.SelectedType == 'All'){
                this.setState({ ComputeTemplates: [ ...CTItems.sort( function (a,b){ return new Date(b.createdAt) - new Date(a.createdAt)})]})
            } else {
                this.setState({ ComputeTemplates: [ ...CTItems.filter( CTItems => CTItems.type === this.state.SelectedType )].sort( function (a,b){ return new Date(b.createdAt) - new Date(a.createdAt)})})
            }
            this.selectType(types)
            this.setState({ SelectTypeComponent: (
                <UncontrolledButtonDropdown>
                    <DropdownToggle color="primary" caret>
                        {this.state.SelectedType }
                    </DropdownToggle>
                    <DropdownMenu>
                        {this.state.typeSelect}
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
                )})
            if (this.state.SelectedType != 'All'){
                this.setState({ ComputeTemplates: [ ...CTItems.filter( CTItems => CTItems.type === this.state.SelectedType )].sort( function (a,b){ return new Date(b.createdAt) - new Date(a.createdAt)})})
            } else {
                this.setState({ ComputeTemplates: [ ...CTItems.sort( function (a,b){ return new Date(b.createdAt) - new Date(a.createdAt)})]})
            }
            let CTs = [ ...this.state.ComputeTemplates ]
            console.log({CTs})
            this.setState({ listTaskLists: CTs.map((ct, index) => (
                <ListGroupItem key={ct.id ? ct.id : index}
                    aria-selected={this.state.ctNavPills === index}
                    className={classnames("mb-sm-3 mb-md-0", {
                        active: this.state.ctNavPills === index
                    })}
                    onClick={e => this.setSelected(ct, index, this.state.SelectedTaskType)}
                    role="tab">
                    {ct.name}
                </ListGroupItem>
            ))
            });
        } catch (err) {
            console.log(err);
        }
    }


    displaypipelineList = async() => {
        try {
            let CTData = await API.graphql({ query: listPipelines, variables: { limit: 100,  }});
            let CTItems = CTData.data.listPipelines.items;
            let CTdata = CTData.data.listPipelines
            while(CTdata.nextToken != null ){
                let nextToken = CTdata.nextToken;
                let CTData2 = await API.graphql({ query: listPipelines, variables: { limit: 100, nextToken }});
                CTdata = {}
                CTdata = CTData2.data.listPipelines
                CTItems = CTItems.concat(CTdata.items)
            }
            const types = ['All', 'None', ...new Set(CTItems.map(item => item.type))];
            if (this.state.SelectedType == 'None' | this.state.SelectedPipelineType == 'Select Pipeline'){
                this.setState({ ComputeTemplates: []})
            } 
            if (this.state.SelectedType == 'All'){
                this.setState({ ComputeTemplates: [ ...CTItems.sort( function (a,b){ return new Date(b.createdAt) - new Date(a.createdAt)})]})
            } else {
                this.setState({ ComputeTemplates: [ ...CTItems.filter( CTItems => CTItems.type === this.state.SelectedPipelineType )].sort( function (a,b){ return new Date(b.createdAt) - new Date(a.createdAt)})})
            }
            this.selectType(types)
            this.setState({ SelectTypeComponent: (
                <UncontrolledButtonDropdown>
                    <DropdownToggle color="primary" caret>
                        {this.state.SelectedType }
                    </DropdownToggle>
                    <DropdownMenu>
                        {this.state.typeSelect}
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
                )})
            if (this.state.SelectedType != 'All'){
                this.setState({ ComputeTemplates: [ ...CTItems.filter( CTItems => CTItems.type === this.state.SelectedPipelineType )].sort( function (a,b){ return new Date(b.createdAt) - new Date(a.createdAt)})})
            } else {
                this.setState({ ComputeTemplates: [ ...CTItems.sort( function (a,b){ return new Date(b.createdAt) - new Date(a.createdAt)})]})
            }
            let CTs = [ ...this.state.ComputeTemplates ]
            console.log({CTs})
            this.setState({ listTaskLists: CTs.map((ct, index) => (
                <ListGroupItem key={ct.id ? ct.id : index}
                    aria-selected={this.state.ctNavPills === index}
                    className={classnames("mb-sm-3 mb-md-0", {
                        active: this.state.ctNavPills === index
                    })}
                    onClick={e => this.setSelected(ct, index, this.state.SelectedTaskType)}
                    role="tab">
                    {ct.name}
                </ListGroupItem>
            ))
            });
        } catch (err) {
            console.log(err);
        }
    }


    setSelectedTertiaryType = (type) => {
        this.setState({TertiaryTypeOpen: false})
        this.setState({TertiaryType: type})
        this.setState({ selectedTertiaryTypeData: [] })
        if(type == 'Every X Minutes'){
            this.setState({selectTertiaryTypeData: <>
                <Input placeholder="Run Intervole Minutes" required="true" type="number" value={this.state.SelectedTertiaryTypeData} onChange={this.updateTertiaryTypeArr} /> 
            </>})
        } else if(type == 'Times Of Day'){
            this.displayTimeToTertiaryTypeData()
        }
    }

    addTimeToTertiaryTypeData = () => {
        let tmparr = this.state.TertiaryTypeArr
        let timearr = this.state.SettmpTimeTimeofDay
        console.log({tmparr})

        tmparr.push(JSON.stringify({hour: timearr[0], minute: timearr[1]}))
        // this.setState({SettmpTimeTimeofDay: '00:00'})
        this.setState({TertiaryTypeArr: tmparr })
        console.log(tmparr)
        // this.setState({TimeList: tmparr.map((time, index)=> (
        //     <ListGroupItem key={index} className="mb-sm-3 mb-md-0" role="tab">
        //         {this.timeString(time)}
        //     </ListGroupItem>
        // ))})
        this.TertiarydisplayTimeList()
        // this.displayTimeToTertiaryTypeData()
    }

    TertiarydisplayTimeList = () => {
        this.setState({TertiaryTypeArr: this.state.TertiaryTypeArr.map((time, index)=> (
            <ListGroupItem key={index} className="mb-sm-3 mb-md-0" role="tab">
                {this.timeString(time)}
            </ListGroupItem>
        ))})
        this.displayTimeToTertiaryTypeData()
    }

    displayTimeToTertiaryTypeData = () => {
        this.setState({selectTertiaryTypeData: <>
            <Row>
                <Col>
                    <Label for="AddTime"> Time </Label>
                    <Input id="AddTime" name="time" required placeholder="time placeholder" value={this.state.tmptimevalue}  onChange={e => {this.onChangeSettmpTimeTimesofDay(e)}} type="time" />
                    </Col>
                <Col>
                    <Button onClick={e => this.addTimeToTertiaryTypeData()}>Add Time</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ListGroup>
                        {this.state.TertiaryTypeArr}
                    </ListGroup>
                </Col>
            </Row>
        </>})
        
    }


    timeString = (time) => {
        const timestrD = JSON.parse(time)
        console.log({time})
        console.log({timestrD})
        return(timestrD['hour'] + ':' + timestrD['minute'])
    }


    toggleSecondaryTypeDropdown = () => {
        // console.log(!this.state.SecondaryTypeOpen)
        this.setState({SecondaryTypeOpen: !this.state.SecondaryTypeOpen})
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log('Prev state', prevState.SecondaryTypeOpen); // Before update
        // console.log('New state', this.state.SecondaryTypeOpen); // After update 
        if(prevState.PrimaryType !== this.state.PrimaryType){
            this.displayPrimaryTypeData(this.state.PrimaryType)
        } 
        if(prevState.SecondaryTypeOpen !== this.state.SecondaryTypeOpen){
            this.showSecondaryType()
        } 
        if(prevState.SecondaryType !== this.state.SecondaryType){
            this.setSelectedSecondaryType(this.state.SecondaryType)
            this.showTertiaryType(this.state.SecondaryType)
        } 
        if(prevState.TertiaryTypeOpen !== this.state.TertiaryTypeOpen){
            this.showTertiaryType(this.state.SecondaryType)
        } 
        if(prevState.SecondaryTimeList !== this.state.SecondaryTimeList){
            this.displaySecondaryTimesOfDay()
        }
        if(prevState.selectSecondaryTypeData !== this.state.selectSecondaryTypeData){
            this.showSecondaryType()
        }
        if(prevState.TertiaryTimeList !== this.state.TertiaryTimeList){
            this.displayTimeToTertiaryTypeData()
        }
        if(prevState.TertiaryTypeArr !== this.state.TertiaryTypeArr){
            this.showTertiaryType()
        }
    }



    showTertiaryType = () => {
        if( this.state.SecondaryType !== 'Days of Week'){ 
            this.setState({TertiaryTypeSelect: <></>}) 
        } else {
            this.setState({TertiaryTypeSelect : <>
                <Row>
                    <Col>
                        <h3>Tertiary Type</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Dropdown isOpen={this.state.TertiaryTypeOpen} toggle={this.toggleTertiaryTypeDropdown}>
                            <DropdownToggle caret>
                                {this.state.TertiaryType}
                            </DropdownToggle>
                            <DropdownMenu right>
                                {this.state.displayTertiaryTypeSelect}
                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                    <Col>
                        <h3>Tertiary Type Data</h3>
                        {this.state.selectTertiaryTypeData}
                    </Col>
                </Row>
            </>})
        }
    }

    toggleTertiaryTypeDropdown = () => {
        // console.log(this.state.TertiaryTypeOpen)
        console.log(this.state.SecondaryType)

        if(this.state.TertiaryTypeOpen){
            this.setState({TertiaryTypeOpen: false})
        }
        else{
            this.setState({TertiaryTypeOpen: true})
        }
        this.showTertiaryType(this.state.SecondaryType)
    }

    updateSecondaryTypeArr = event => {
        this.setState({selectedSecondaryTypeData: event.target.value})
        console.log(event.target.value)
    }

    updateTertiaryTypeArr = event => {
        this.setState({selectedTertiaryTypeData: event.target.value})
        console.log(event.target.value)
    }


    setSelected = (req, index, SelectedTaskType) => {
        console.log(req)
        // console.log(index)
        this.setState({ctNavPills: index});
        // this.displayTemplateList()
        if(SelectedTaskType == 'ComputeTemplates'){
            this.displayTemplateList()
        } else if(SelectedTaskType == 'Pipelines'){
            this.displaypipelineList()
        }
        // this.getDataVersion()
        this.setState({selectedTemplate: req})
    }

    onButtonClick = async() =>{
        let primaryType = this.state.PrimaryType
        let secondaryType = this.state.SecondaryType
        let tertiaryType  = this.state.TertiaryType

        let SecondaryTypeValue = []
        if(secondaryType == 'Every X Minutes'){
            SecondaryTypeValue = [this.state.selectedSecondaryTypeData]
        } else{
            SecondaryTypeValue = this.state.selectedSecondaryTypeData
        }

        let TertiaryTypeValue = []
        if(tertiaryType == 'Every X Minutes'){
            TertiaryTypeValue = [this.state.selectedTertiaryTypeData]
        } else{
            TertiaryTypeValue = this.state.TertiaryTypeArr
        }
        let executionType = ''
        if(this.state.SelectedTaskType == 'ComputeTemplates'){
            executionType = 'ComputeTemplate'
        } else if(this.state.SelectedTaskType == 'Pipelines'){
            executionType = 'Pipeline'
        }
        
        let input = {
            name: this.state.name,
            status: 'Paused',
            primaryType: this.state.PrimaryType,
            primaryTypeArr: this.state.selectedPrimaryTypeData,
            secondaryType: this.state.SecondaryType,
            secondaryTypeArr: SecondaryTypeValue,
            tertiaryType: tertiaryType,
            tertiaryTypeArr: TertiaryTypeValue,
            executionType: executionType,
            executiontemplateid: this.state.selectedTemplate['id'],
        }
        if(input['secondaryType'] == "Select Secondary Schedule Type"){
            console.log("Select Secondary Schedule Type")
            input['secondaryType'] = ''
        }
        if(input['tertiaryType'] == "Select Tertiary Schedule Type"){
            console.log("Select Tertiary Schedule Type")
            input['tertiaryType'] = ''
        }
        if(primaryType == 'One Time'){
            input['nextRuntime'] = JSON.stringify(this.state.OneTimeNextRunDateTime)
        }
        const createScheduledEventValuesResponse = await API.graphql(graphqlOperation(createScheduledEvent, {input: input}));
        console.log({createScheduledEventValuesResponse})

    }

    render() {
        return(
            <>
                <Container>
                    <Row>
                        <Input placeholder="Name" type="text" required="true" value={this.state.name} onChange={this.handleNameChange} /> 
                    </Row>
                    <br />
                    <Row>
                        <Col className='col-4'>
                            <UncontrolledButtonDropdown>
                                <DropdownToggle color="primary" caret>
                                    {this.state.SelectedTaskType }
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem className={classnames("dropdown-item" , { active: this.state.SelectedTaskType === 'ComputeTemplates' })} onClick={(e) => this.updateTaskType('ComputeTemplates')} >
                                        ComputeTemplates
                                    </DropdownItem>
                                    <DropdownItem className={classnames("dropdown-item" , { active: this.state.SelectedTaskType === 'Pipelines' })} onClick={(e) => this.updateTaskType('Pipelines')} >
                                        Pipelines
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                            <br />
                            <br />
                            {this.state.SelectTypeComponent}
                            <br />
                            <br />
                            <ListGroup>
                                {this.state.listTaskLists}
                            </ListGroup>
                        </Col>
                        <Col className='col-8'>
                            <Row>
                                <Col>
                                    <h3>Primary Type</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Dropdown isOpen={this.state.PrimaryTypeOpen} toggle={this.togglePrimaryTypeDropdown}>
                                        <DropdownToggle caret>
                                            {this.state.PrimaryType}
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <DropdownItem key='One Time'
                                                aria-selected={this.state.PrimaryType === 'One Time'}
                                                className={classnames("mb-sm-3 mb-md-0", {
                                                    active: this.state.PrimaryType === 'One Time'
                                                })}
                                                onClick={e => this.setSelectedPrimaryType('One Time')}
                                                role="tab"
                                                >
                                                One Time
                                            </DropdownItem>
                                            <DropdownItem key='Days Of the Month'
                                                aria-selected={this.state.PrimaryType === 'Days Of the Month'}
                                                className={classnames("mb-sm-3 mb-md-0", {
                                                    active: this.state.PrimaryType === 'Days Of the Month'
                                                })}
                                                onClick={e => this.setSelectedPrimaryType('Days Of the Month')}
                                                role="tab"
                                                >
                                                Days Of the Month
                                            </DropdownItem>
                                            <DropdownItem key='Days Of the Week'
                                                aria-selected={this.state.PrimaryType === 'Days Of the Week'}
                                                className={classnames("mb-sm-3 mb-md-0", {
                                                    active: this.state.PrimaryType === 'Days Of the Week'
                                                })}
                                                onClick={e => this.setSelectedPrimaryType('Days Of the Week')}
                                                role="tab"
                                                >
                                                Days Of the Week
                                            </DropdownItem>
                                            <DropdownItem key='Weeks Of the Month'
                                                aria-selected={this.state.PrimaryType === 'Weeks Of the Month'}
                                                className={classnames("mb-sm-3 mb-md-0", {
                                                    active: this.state.PrimaryType === 'Weeks Of the Month'
                                                })}
                                                onClick={e => this.setSelectedPrimaryType('Weeks Of the Month')}
                                                role="tab"
                                                >
                                                Weeks Of the Month
                                            </DropdownItem>
                                            <DropdownItem key='Daily'
                                                aria-selected={this.state.PrimaryType === 'Daily'}
                                                className={classnames("mb-sm-3 mb-md-0", {
                                                    active: this.state.PrimaryType === 'Daily'
                                                })}
                                                onClick={e => this.setSelectedPrimaryType('Daily')}
                                                role="tab"
                                                >
                                                Daily
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </Col>
                                <Col>
                                    <h3>Primary Type Data</h3>
                                    {this.state.selectPrimaryTypeData}
                                </Col>
                            </Row>
                            {this.state.SecondaryTypeSelect}
                            {/* <hr />
                            <Row>
                                <Col>
                                    <h3>Secondary Type</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Dropdown isOpen={this.state.SecondaryTypeOpen} toggle={this.toggleSecondaryTypeDropdown}>
                                        <DropdownToggle caret>
                                            {this.state.SecondaryType}
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            {this.state.displaySecondaryTypeSelect}
                                        </DropdownMenu>
                                    </Dropdown>
                                </Col>
                                <Col>
                                    <h3>Secondary Type Data</h3>
                                    {this.state.selectSecondaryTypeData}
                                </Col>
                            </Row> */}
                            {this.state.TertiaryTypeSelect}
                            {/* <hr />

                            <Row>
                                <Col>
                                    <h3>Tertiary Type</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Dropdown isOpen={this.state.TertiaryTypeOpen} toggle={this.toggleTertiaryTypeDropdown}>
                                        <DropdownToggle caret>
                                            {this.state.TertiaryType}
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            {this.state.displayTertiaryTypeSelect}
                                        </DropdownMenu>
                                    </Dropdown>
                                </Col>
                                <Col>
                                    <h3>Tertiary Type Data</h3>
                                    {this.state.selectTertiaryTypeData}
                                </Col>
                            </Row> */}
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Button className="btn btn-outline-danger" onClick={ e => this.onButtonClick(  ) }>Submit</Button>
                    </Row>
                </Container>
            </>
        )
    }
    

}

// export default RunRequestBtn;





// const mapStateToProps = state => {
//     return {
//         currentQueryID: state.selectedQueryID,
//         currentQueryName: state.selectedQueryName,
//         currentQueryCurrent: state.selectedQueryStayCurrent,
//         currentQueryMarkets: state.selectedQueryMarkets,
//         currentQueryConsumers: state.selectedQueryConsumers,
//         currentQueryStatus: state.selectedQueryStatus,
//         // currentQueryLastRunDataVersion: state.selectedQueryLastRunDataVersion,
//         currentDataVersion: state.currentDataVersion
//         // UsersQuerys: state.UsersQuerys, // replacing reqs 
//     };
// }

// const mapDispatchToProps = dispatch => {
    
//     return {
//         onUpdateReq: (event) => {
//             dispatch({type: 'UPDATESELECTEDQUERYSTATUS', 
//                 currentQueryStatus: 'Active', 
//             })
//         }
        
        
//     }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(RunRequestBtn);
export default NewScheduleForm