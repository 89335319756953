import React from 'react'
// import ReactDOM from 'react-dom';
import { listResources,  } from '../graphql/queries'
import { updateResource } from '../graphql/mutations'
import { API, graphqlOperation, Auth } from 'aws-amplify'
// import { connect } from 'react-redux'
// import { withAuthenticator } from '@aws-amplify/ui-react'
import classnames from "classnames";
import { 
    Table,
    Button,
    NavItem,
    NavLink,
    Nav,
} from "reactstrap";



class ListComputeTable extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            reqNavPills: -1,
            listUsersReqs: [],
            selectedTemplate: [],
            setActive: false,
            radio: -1,
            selectedQueryID: '',
            selectedQueryName: '',
            ComputeTemplates: [],
            navPills: 1,
            listResources: ''
        }

    }
    toggleNavs = (e, state, index) => {
        e.preventDefault();
        this.setState({[state]: index});
        this.setSelected(e,index)
        // this.displayList()
    };

    toggleNavPills = (e, state, index) => {
        e.preventDefault();
        this.setState({[state]: index});
        this.displayList()
    };

    setInput(key, value) {
        this.setState([key], value)
    }



    componentDidMount() {
        this.displayList();
    }



    setSelected = async(ct, index) => {
        console.log(ct)
        this.setState({selectedTemplate: ct})
        this.setState({reqNavPills: index});
        this.props.updateSelectedTemplate(ct['id'])
        this.displayList()
        // console.log(response)
    }



    getComputeTypeElements = (ct) => {
        // const type = ct
        this.displayList()
        // console.log(type)
    }


    updateButton = (ct) => {
        if(ct.status == 'Active'){
            return(
                <>
                    <Button className="btn btn-outline-danger" onClick={ e => this.deleteSNSQueue( ct ) }>Delete</Button>
                </>
            )
            }
        else if(ct.status == 'Deleted'){
            return(
                <>
                    <Button className="btn btn-outline-success" onClick={ e => this.activateSNSQueue( ct ) }>Activate</Button>
                </>
            )
        }
    }

    deleteSNSQueue = async(ct) => {
        const values = {
            id: ct.id,
            status: 'Deleted'
        }
        // console.log(values)
        const updatedQueue = await API.graphql({ query: updateResource, variables: {input: values}});
        // console.log(updatedQueue)
        this.displayList()
    }

    activateSNSQueue = async(ct) => {
        const values = {
            id: ct.id,
            status: 'New'
        }
        const updatedQueue = await API.graphql({ query: updateResource, variables: {input: values}});
        // console.log(updatedQueue)
        this.displayList()
    }



    displayList = async() => {
        try {

            let CEData = await API.graphql({ query: listResources,  variables: { limit: 100,  } });
            let lcedata = CEData.data.listResources
            let CEItems = lcedata.items;
            while(lcedata.nextToken != null ){
                let nextToken = lcedata.nextToken;
                let CEData2 = await API.graphql({ query: listResources, variables: { limit: 100, nextToken }});
                lcedata = {}
                lcedata = CEData2.data.listResources
                CEItems = CEItems.concat(lcedata.items)
            }
            
            console.log(CEItems)
            // let filteredCEItems = [...CEItems.filter( CEItems => CEItems.computeTemplate === this.props.selectedTemplateID )]
            let filteredSNSType = [...CEItems.filter( CEItems => CEItems.type == 'Compute' )]
            console.log(filteredSNSType)
            let filteredCEItems
            if(this.state.navPills === 1){
                // console.log('apply filter for Active Queues')
                filteredCEItems = [...filteredSNSType.filter( CEItems => CEItems.status !== 'Deleted' )]



            } else if(this.state.navPills === 2) {
                // console.log('apply filter for Deleted Queues')
                filteredCEItems = [...filteredSNSType.filter( CEItems => CEItems.status === 'Deleted' )]



            }
            console.log(filteredCEItems)
            // let reqs = [...ReqItems.filter( ReqItems => ReqItems.deleted === false )]
            // console.log(this.state.selectedQueryID)
            this.setState({ listSNS: [ ...filteredCEItems.sort( function (a,b){ return new Date(b.createdAt) - new Date(a.createdAt)})]})

            let CEs = [ ...this.state.listSNS ]
            
            this.setState({
                listSNSQueues: CEs.map((ct, index) => (
                        <tr key={ct.id ? ct.id : index}
                            aria-selected={this.state.reqNavPills === index}
                            className={classnames("table-hover mb-sm-3 mb-md-0", {
                                active: this.state.reqNavPills === index
                            })}
                            role="tab"
                            >
                            
                            {/* <td>{ct.id}</td> */}
                            <td>{ct.name}</td>
                            <td>{ct.type}</td>
                            <td>{ct.status}</td>
                            <td>{this.updateButton(ct)}</td>
                            {/* </a> */}
                        </tr>
                ))
            });
        } catch (err) {
            console.log(err);
        }
    }





    render  () {
        // console.log(this.props.selectedTemplateID)
        if( this.props.selectedTemplateID === ''){
            return(
                <h3> No query has been selected.</h3>
            )

        }
        else{
            return( 
                <>
                <Nav
                    className="nav-fill flex-column flex-sm-row"
                    id="tabs-text"
                    pills
                    role="tablist"
                    >
                    <NavItem>
                        <NavLink
                        aria-selected={this.state.navPills === 1}
                        className={classnames("mb-sm-3 mb-md-0", {
                            active: this.state.navPills === 1
                        })}
                        onClick={e => this.toggleNavPills(e, "navPills", 1)}
                        href="#pablo"
                        role="tab"
                        >
                        Active
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                        aria-selected={this.state.navPills === 2}
                        className={classnames("mb-sm-3 mb-md-0", {
                            active: this.state.navPills === 2
                        })}
                        onClick={e => this.toggleNavPills(e, "navPills", 2)}
                        href="#pablo"
                        role="tab"
                        >
                        Deleted
                        </NavLink>
                    </NavItem>
                </Nav>
                <hr></hr>
                <Table className="align-items-center" responsive>
                    <thead className="thead-light">
                        <tr>
                            {/* <th scope="col">ID</th> */}
                            <th scope="col">Name</th>
                            <th scope="col">Type</th>
                            <th scope="col">Status</th>
                            <th scope="col">Toggle</th>
                        </tr>
                    </thead>
                    <tbody>

                        {this.state.listSNSQueues}

                    </tbody>
                </Table>
                </>
            )
        }
    }
};


export default (ListComputeTable);
