import React from 'react'
// import ReactDOM from 'react-dom';
import { API, graphqlOperation } from 'aws-amplify'
// import { connect } from 'react-redux'
// import classnames from "classnames";
// import { onCreateReqHistory } from './../../graphql/subscriptions'
import { createResource } from '../graphql/mutations'

import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Container,
    Button,
    Input,
    Col,
    Collapse
} from "reactstrap";


class NewLaunchTemplateForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            launchTemplateid: '',
            name: '',
            userData: ''
        }
        
    }

    handleNameChange = event => {
        this.setState({name: event.target.value})
        console.log(event.target.value)
    }

    handleLTChange = event => {
        this.setState({launchTemplateid: event.target.value})
        console.log(event.target.value)
    }

    handleUserDataChange = event => {
        this.setState({userData: event.target.value})
        console.log(event.target.value)
    }

    onButtonClick = async() => {
        const response = await API.graphql(graphqlOperation(createResource, {input: { name: this.state.name, status: 'New', type: 'Compute', subtype: 'LaunchTemplate', computeTypeid: this.state.launchTemplateid, userData: this.state.userData}}))
        console.log(response)
    }

    render() {
        return(
            <>
                <Container>
                    <Row>
                        <Col>
                            <Input placeholder="Name" type="text" required="true" value={this.state.name} onChange={this.handleNameChange} /> 
                        </Col>
                        <Col>
                            <Input placeholder="LaunchTemplate ID" required="true" type="text" value={this.state.launchTemplateid} onChange={this.handleLTChange} /> 
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Input placeholder="User Data" type="textarea" value={this.state.userData} onChange={this.handleUserDataChange} /> 
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button className="btn btn-outline-danger" onClick={ e => this.onButtonClick(  ) }>Submit</Button>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}


export default NewLaunchTemplateForm



