import React from 'react'
// import ReactDOM from 'react-dom';
import { API, graphqlOperation } from 'aws-amplify'
// import { connect } from 'react-redux'
// import classnames from "classnames";
// import { onCreateReqHistory } from './../../graphql/subscriptions'
import { createResource } from '../graphql/mutations'

import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Container,
    Button,
    Input,
    Col,
    Collapse
} from "reactstrap";


class NewSNSForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            launchTemplateid: '',
            name: '',
        }
        
    }

    handleNameChange = event => {
        this.setState({name: event.target.value})
        console.log(event.target.value)
    }

    onButtonClick = async() => {
        // const values = {
        //     name: this.state.name,
        //     status: 'New',
        //     type: 'SNS',
        //     url: '',
        //     arn: ''
        // }
        // console.log(values)
        const response = await API.graphql(graphqlOperation(createResource, {input: {
            name: this.state.name,
            status: 'New',
            type: 'SNS',
            url: '',
            arn: ''
        }}))
        console.log(response)
    }

    render() {
        return(
            <>
                <Container>
                    <Row>
                        <Col>
                            <Input placeholder="Name" required="true" type="text" value={this.state.name} onChange={this.handleNameChange} /> 
                        </Col>
                        <Col>
                            <Button className="btn btn-outline-danger" onClick={ e => this.onButtonClick(  ) }>Submit</Button>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}


export default NewSNSForm



