import React from 'react'
// import ReactDOM from 'react-dom';
import { listComputeExecutions,  } from '../graphql/queries'
import * as subscriptions from '../graphql/subscriptions';

// import { createComputeExecution } from '../graphql/mutations'
import { API, graphqlOperation, Auth, Storage } from 'aws-amplify'
import { connect } from 'react-redux'
// import { withAuthenticator } from '@aws-amplify/ui-react'
import classnames from "classnames";
import { 
    ListGroup,
    ListGroupItem,
    Card, 
    CardBody, 
    CardTitle, 
    CardHeader,
    Row,
    Col,
    Table,
    Collapse,
    Button,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane
} from "reactstrap";



class ListComputeExecutionsTableForTemplate extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            reqNavPills: -1,
            listUsersReqs: [],
            selectedTemplate: [],
            setActive: false,
            radio: -1,
            selectedQueryID: '',
            selectedQueryName: '',
            ComputeTemplates: [],
            navPills: 1,
        }
        this.displayList()
        const onUpdateComputeExecution = API.graphql(
            graphqlOperation(subscriptions.onUpdateComputeExecution)
        ).subscribe({
            next: ({ provider, value }) => {
                this.displayList()
                console.log({ provider, value })},
            error: error => console.warn(error)
        });

        const onCreateComputeExecution = API.graphql(
            graphqlOperation(subscriptions.onCreateComputeExecution)
        ).subscribe({
            next: ({ provider, value }) => {
                this.displayList()
                console.log({ provider, value })},
            error: error => console.warn(error)
        });
    }
    toggleNavs = (e, state, index) => {
        e.preventDefault();
        this.setState({[state]: index});
        this.setSelected(e,index)
        // this.displayList()
    };

    toggleNavPills = (e, state, index) => {
        e.preventDefault();
        this.setState({[state]: index});
        this.displayList()
    };

    setInput(key, value) {
        this.setState([key], value)
    }



    componentDidMount() {
        this.displayList();
    }



    setSelected = async(ct, index) => {
        console.log(ct)
        this.setState({selectedTemplate: ct})
        this.setState({reqNavPills: index});
        this.props.updateSelectedComputeExecution(ct)
        this.displayList()
        // console.log(response)
    }



    getComputeTypeElements = (ct) => {
        // const type = ct
        this.displayList()
        // console.log(type)
    }


    downloadBlob(blob, filename) {
        console.log(blob)
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        console.log(url)
        a.download = filename || 'download';
        const clickHandler = () => {
            setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
            }, 150);
        };
        a.addEventListener('click', clickHandler, false);
        a.click();
        return a;
    }

    getSignedURL = async( logfile ) => {
        let filename = 'server-output.log'
        console.log(logfile)
        await Storage.get(logfile, { download: true }).then(res => this.downloadBlob(res.Body, filename))
    }

    logfileDownloadButton = (ce) => {
        console.log(ce)
        if(ce.status === 'Completed'){
            return(
                <Button className="btn btn-outline-success" onClick = { e => this.getSignedURL( ce.logfile + 'server-output.log' ) } >Download</Button>
            )
        } else if(ce.status === 'Failed'){
            return(
                <Button className="btn btn-outline-danger" onClick = { e => this.getSignedURL( ce.logfile + 'server-output.log' ) } >Download</Button>
            )
        } else if(ce.status === 'TimedOut'){
            return(
                <Button className="btn btn-outline-danger" onClick = { e => this.getSignedURL( ce.logfile + 'server-output.log' ) } >Download</Button>
            )
        }    
    }




    displayList = async() => {
        try {
            let CEData = await API.graphql({ query: listComputeExecutions,  variables: { limit: 100,  } });
            let lcedata = CEData.data.listComputeExecutions
            let CEItems = lcedata.items;
            while(lcedata.nextToken != null ){
                let nextToken = lcedata.nextToken;
                let CEData2 = await API.graphql({ query: listComputeExecutions, variables: { limit: 100, nextToken }});
                lcedata = {}
                lcedata = CEData2.data.listComputeExecutions
                CEItems = CEItems.concat(lcedata.items)
            }
            console.log({CEItems})

            console.log(this.props.selectedTemplate['selectedTemplate'])
            let filteredCEItems = [...CEItems.filter( CEItems => CEItems.computeTemplate === this.props.selectedTemplate['selectedTemplate']['id'] )]
            // let filteredCEItems
            console.log({filteredCEItems})

            if(this.state.navPills === 1){
                console.log('apply filter for Recent Jobs')
                let recentTimeout = new Date() ;
                filteredCEItems = [...filteredCEItems.filter( (item) => new Date(item.createdAt).toString().split(' ')[3] == recentTimeout.toString().split(' ')[3] )]
                filteredCEItems = [...filteredCEItems.filter( (item) => new Date(item.createdAt).toString().split(' ')[1] == recentTimeout.toString().split(' ')[1] )]
                filteredCEItems = [...filteredCEItems.filter( (item) => new Date(item.createdAt).toString().split(' ')[2] == recentTimeout.toString().split(' ')[2] )]
            } else if(this.state.navPills === 2){
                console.log('apply filter for Active Jobs')
                filteredCEItems = [...filteredCEItems.filter( CEItems => CEItems.status !== 'Completed' )]
                filteredCEItems = [...filteredCEItems.filter( CEItems => CEItems.status !== 'Failed' )]
                filteredCEItems = [...filteredCEItems.filter( CEItems => CEItems.status !== 'TimedOut' )]
            } else if(this.state.navPills === 3) {
                console.log('apply filter for Coimpleted Jobs')
                filteredCEItems = [...filteredCEItems.filter( CEItems => CEItems.status === 'Completed' )]
            } else if(this.state.navPills === 4) {
                console.log('apply filter for Failed Jobs')
                filteredCEItems = [...filteredCEItems.filter( CEItems => CEItems.status === 'Failed'  )]
                filteredCEItems = filteredCEItems.concat([...filteredCEItems.filter( CEItems => CEItems.status === 'TimedOut' )])
            }
            // console.log(this.props.selectedTemplateID)
            // let reqs = [...ReqItems.filter( ReqItems => ReqItems.deleted === false )]
            // console.log(this.state.selectedQueryID)
            filteredCEItems =[ ...filteredCEItems.sort( function (a,b){ return new Date(b.createdAt) - new Date(a.createdAt)})]
            this.setState({ ComputeExecutions: filteredCEItems})

            // let CEs = [ ...this.state.ComputeExecutions ]
            
            this.setState({
                listComputeExecutions: filteredCEItems.map((ct, index) => (
                        <tr key={ct.id ? ct.id : index}
                            aria-selected={this.state.reqNavPills === index}
                            className={classnames("table-hover mb-sm-3 mb-md-0", {
                                active: this.state.reqNavPills === index
                            })}
                            role="tab"
                            >
                            
                            <td>{ct.createdAt}</td>

                            <td>{ct.status}</td>

                            {/* <td>{ct.computeType}</td> */}
                            {/* <td>{ct.logfile}</td> */}
                            <td>{ this.logfileDownloadButton(ct) }</td>
                            {/* </a> */}
                        </tr>
                ))
            });
        } catch (err) {
            console.log(err);
        }
    }





render  () {
    // console.log(this.props.selectedTemplateID)
        return( 
            <>
            <Nav className="nav-fill flex-column flex-sm-row" id="tabs-text" pills role="tablist">
                <NavItem>
                    <NavLink
                    aria-selected={this.state.navPills === 1}
                    className={classnames("mb-sm-3 mb-md-0", {
                        active: this.state.navPills === 1
                    })}
                    onClick={e => this.toggleNavPills(e, "navPills", 1)}
                    href="#pablo"
                    role="tab"
                    >
                    Recent
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                    aria-selected={this.state.navPills === 2}
                    className={classnames("mb-sm-3 mb-md-0", {
                        active: this.state.navPills === 2
                    })}
                    onClick={e => this.toggleNavPills(e, "navPills", 2)}
                    href="#pablo"
                    role="tab"
                    >
                    Active
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                    aria-selected={this.state.navPills === 3}
                    className={classnames("mb-sm-3 mb-md-0", {
                        active: this.state.navPills === 3
                    })}
                    onClick={e => this.toggleNavPills(e, "navPills", 3)}
                    href="#pablo"
                    role="tab"
                    >
                    Completed
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                    aria-selected={this.state.navPills === 4}
                    className={classnames("mb-sm-3 mb-md-0", {
                        active: this.state.navPills === 4
                    })}
                    onClick={e => this.toggleNavPills(e, "navPills", 4)}
                    href="#pablo"
                    role="tab"
                    >
                    Failed
                    </NavLink>
                </NavItem>
            </Nav>
            <Table className="align-items-center" responsive>
                <thead className="thead-light">
                    <tr>
                        <th scope="col">Created</th>
                        <th scope="col">Status</th>
                        {/* <th scope="col">computeType</th> */}
                        <th scope="col">logfile</th>
                    </tr>
                </thead>
                <tbody>

                    {this.state.listComputeExecutions}

                </tbody>
            </Table>
            </>
        )
    }
    }
// };



const mapStateToProps = state => {
    console.log({state})
    return {
        selectedTemplate: state.selectedTemplate
    };
}
const mapDispatchToProps = dispatch => {
    
    return {
        updateSelectedComputeExecution: (event) => {
            console.log('updateSelectedTemplate to props event: ', event)
            dispatch({type: 'UPDATELISTQUERYITEMS', 
                selectedComputeExecutionFromTemplate: event.selectedTemplate,
            })
            
        },


    }
}

export default connect( mapStateToProps)(ListComputeExecutionsTableForTemplate);
