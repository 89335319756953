/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createComputeType = /* GraphQL */ `
  mutation CreateComputeType(
    $input: CreateComputeTypeInput!
    $condition: ModelComputeTypeConditionInput
  ) {
    createComputeType(input: $input, condition: $condition) {
      id
      name
      type
      computeResource
      computeSize
      sqsResource
      snsResource
      tags
      createdAt
      updatedAt
    }
  }
`;
export const updateComputeType = /* GraphQL */ `
  mutation UpdateComputeType(
    $input: UpdateComputeTypeInput!
    $condition: ModelComputeTypeConditionInput
  ) {
    updateComputeType(input: $input, condition: $condition) {
      id
      name
      type
      computeResource
      computeSize
      sqsResource
      snsResource
      tags
      createdAt
      updatedAt
    }
  }
`;
export const deleteComputeType = /* GraphQL */ `
  mutation DeleteComputeType(
    $input: DeleteComputeTypeInput!
    $condition: ModelComputeTypeConditionInput
  ) {
    deleteComputeType(input: $input, condition: $condition) {
      id
      name
      type
      computeResource
      computeSize
      sqsResource
      snsResource
      tags
      createdAt
      updatedAt
    }
  }
`;
export const createComputeTemplate = /* GraphQL */ `
  mutation CreateComputeTemplate(
    $input: CreateComputeTemplateInput!
    $condition: ModelComputeTemplateConditionInput
  ) {
    createComputeTemplate(input: $input, condition: $condition) {
      id
      name
      computeType
      s3key
      arguments
      maxRunTime
      type
      tags
      createdAt
      updatedAt
    }
  }
`;
export const updateComputeTemplate = /* GraphQL */ `
  mutation UpdateComputeTemplate(
    $input: UpdateComputeTemplateInput!
    $condition: ModelComputeTemplateConditionInput
  ) {
    updateComputeTemplate(input: $input, condition: $condition) {
      id
      name
      computeType
      s3key
      arguments
      maxRunTime
      type
      tags
      createdAt
      updatedAt
    }
  }
`;
export const deleteComputeTemplate = /* GraphQL */ `
  mutation DeleteComputeTemplate(
    $input: DeleteComputeTemplateInput!
    $condition: ModelComputeTemplateConditionInput
  ) {
    deleteComputeTemplate(input: $input, condition: $condition) {
      id
      name
      computeType
      s3key
      arguments
      maxRunTime
      type
      tags
      createdAt
      updatedAt
    }
  }
`;
export const createComputeExecution = /* GraphQL */ `
  mutation CreateComputeExecution(
    $input: CreateComputeExecutionInput!
    $condition: ModelComputeExecutionConditionInput
  ) {
    createComputeExecution(input: $input, condition: $condition) {
      id
      computeTemplate
      status
      type
      arguments
      parentType
      parentExId
      taskStr
      logfile
      tags
      createdAt
      updatedAt
    }
  }
`;
export const updateComputeExecution = /* GraphQL */ `
  mutation UpdateComputeExecution(
    $input: UpdateComputeExecutionInput!
    $condition: ModelComputeExecutionConditionInput
  ) {
    updateComputeExecution(input: $input, condition: $condition) {
      id
      computeTemplate
      status
      type
      arguments
      parentType
      parentExId
      taskStr
      logfile
      tags
      createdAt
      updatedAt
    }
  }
`;
export const deleteComputeExecution = /* GraphQL */ `
  mutation DeleteComputeExecution(
    $input: DeleteComputeExecutionInput!
    $condition: ModelComputeExecutionConditionInput
  ) {
    deleteComputeExecution(input: $input, condition: $condition) {
      id
      computeTemplate
      status
      type
      arguments
      parentType
      parentExId
      taskStr
      logfile
      tags
      createdAt
      updatedAt
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelpostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      subjectTable
      subjectid
      docType
      status
      title
      content
      tags
      createdAt
      updatedAt
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelpostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      subjectTable
      subjectid
      docType
      status
      title
      content
      tags
      createdAt
      updatedAt
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelpostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      subjectTable
      subjectid
      docType
      status
      title
      content
      tags
      createdAt
      updatedAt
    }
  }
`;
export const createResource = /* GraphQL */ `
  mutation CreateResource(
    $input: CreateResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    createResource(input: $input, condition: $condition) {
      id
      name
      status
      computeTypeid
      type
      subtype
      sqsurl
      sqsmaxAttempts
      sqsVisibilityTimeout
      sqsdeadLetterurl
      userData
      arn
      tags
      createdAt
      updatedAt
    }
  }
`;
export const updateResource = /* GraphQL */ `
  mutation UpdateResource(
    $input: UpdateResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    updateResource(input: $input, condition: $condition) {
      id
      name
      status
      computeTypeid
      type
      subtype
      sqsurl
      sqsmaxAttempts
      sqsVisibilityTimeout
      sqsdeadLetterurl
      userData
      arn
      tags
      createdAt
      updatedAt
    }
  }
`;
export const deleteResource = /* GraphQL */ `
  mutation DeleteResource(
    $input: DeleteResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    deleteResource(input: $input, condition: $condition) {
      id
      name
      status
      computeTypeid
      type
      subtype
      sqsurl
      sqsmaxAttempts
      sqsVisibilityTimeout
      sqsdeadLetterurl
      userData
      arn
      tags
      createdAt
      updatedAt
    }
  }
`;
export const createPipeline = /* GraphQL */ `
  mutation CreatePipeline(
    $input: CreatePipelineInput!
    $condition: ModelpipelineConditionInput
  ) {
    createPipeline(input: $input, condition: $condition) {
      id
      name
      status
      tasks
      arguments
      tags
      createdAt
      updatedAt
    }
  }
`;
export const updatePipeline = /* GraphQL */ `
  mutation UpdatePipeline(
    $input: UpdatePipelineInput!
    $condition: ModelpipelineConditionInput
  ) {
    updatePipeline(input: $input, condition: $condition) {
      id
      name
      status
      tasks
      arguments
      tags
      createdAt
      updatedAt
    }
  }
`;
export const deletePipeline = /* GraphQL */ `
  mutation DeletePipeline(
    $input: DeletePipelineInput!
    $condition: ModelpipelineConditionInput
  ) {
    deletePipeline(input: $input, condition: $condition) {
      id
      name
      status
      tasks
      arguments
      tags
      createdAt
      updatedAt
    }
  }
`;
export const createPipelineExecution = /* GraphQL */ `
  mutation CreatePipelineExecution(
    $input: CreatePipelineExecutionInput!
    $condition: ModelpipelineExecutionConditionInput
  ) {
    createPipelineExecution(input: $input, condition: $condition) {
      id
      pipelineid
      name
      status
      parentType
      parentExId
      currentTask
      arguments
      taskStatus
      tags
      createdAt
      updatedAt
    }
  }
`;
export const updatePipelineExecution = /* GraphQL */ `
  mutation UpdatePipelineExecution(
    $input: UpdatePipelineExecutionInput!
    $condition: ModelpipelineExecutionConditionInput
  ) {
    updatePipelineExecution(input: $input, condition: $condition) {
      id
      pipelineid
      name
      status
      parentType
      parentExId
      currentTask
      arguments
      taskStatus
      tags
      createdAt
      updatedAt
    }
  }
`;
export const deletePipelineExecution = /* GraphQL */ `
  mutation DeletePipelineExecution(
    $input: DeletePipelineExecutionInput!
    $condition: ModelpipelineExecutionConditionInput
  ) {
    deletePipelineExecution(input: $input, condition: $condition) {
      id
      pipelineid
      name
      status
      parentType
      parentExId
      currentTask
      arguments
      taskStatus
      tags
      createdAt
      updatedAt
    }
  }
`;
export const createPipelineExecutionHistorical = /* GraphQL */ `
  mutation CreatePipelineExecutionHistorical(
    $input: CreatePipelineExecutionHistoricalInput!
    $condition: ModelpipelineExecutionHistoricalConditionInput
  ) {
    createPipelineExecutionHistorical(input: $input, condition: $condition) {
      id
      pipelineExid
      pipelineid
      name
      status
      parentType
      parentExId
      currentTask
      arguments
      taskStatus
      tags
      createdAt
      updatedAt
    }
  }
`;
export const updatePipelineExecutionHistorical = /* GraphQL */ `
  mutation UpdatePipelineExecutionHistorical(
    $input: UpdatePipelineExecutionHistoricalInput!
    $condition: ModelpipelineExecutionHistoricalConditionInput
  ) {
    updatePipelineExecutionHistorical(input: $input, condition: $condition) {
      id
      pipelineExid
      pipelineid
      name
      status
      parentType
      parentExId
      currentTask
      arguments
      taskStatus
      tags
      createdAt
      updatedAt
    }
  }
`;
export const deletePipelineExecutionHistorical = /* GraphQL */ `
  mutation DeletePipelineExecutionHistorical(
    $input: DeletePipelineExecutionHistoricalInput!
    $condition: ModelpipelineExecutionHistoricalConditionInput
  ) {
    deletePipelineExecutionHistorical(input: $input, condition: $condition) {
      id
      pipelineExid
      pipelineid
      name
      status
      parentType
      parentExId
      currentTask
      arguments
      taskStatus
      tags
      createdAt
      updatedAt
    }
  }
`;
export const createScheduledEvent = /* GraphQL */ `
  mutation CreateScheduledEvent(
    $input: CreateScheduledEventInput!
    $condition: ModelscheduledEventConditionInput
  ) {
    createScheduledEvent(input: $input, condition: $condition) {
      id
      name
      status
      primaryType
      primaryTypeArr
      secondaryType
      secondaryTypeArr
      tertiaryType
      tertiaryTypeArr
      executionType
      executiontemplateid
      arguments
      lastRuntime
      nextRuntime
      tags
      createdAt
      updatedAt
    }
  }
`;
export const updateScheduledEvent = /* GraphQL */ `
  mutation UpdateScheduledEvent(
    $input: UpdateScheduledEventInput!
    $condition: ModelscheduledEventConditionInput
  ) {
    updateScheduledEvent(input: $input, condition: $condition) {
      id
      name
      status
      primaryType
      primaryTypeArr
      secondaryType
      secondaryTypeArr
      tertiaryType
      tertiaryTypeArr
      executionType
      executiontemplateid
      arguments
      lastRuntime
      nextRuntime
      tags
      createdAt
      updatedAt
    }
  }
`;
export const deleteScheduledEvent = /* GraphQL */ `
  mutation DeleteScheduledEvent(
    $input: DeleteScheduledEventInput!
    $condition: ModelscheduledEventConditionInput
  ) {
    deleteScheduledEvent(input: $input, condition: $condition) {
      id
      name
      status
      primaryType
      primaryTypeArr
      secondaryType
      secondaryTypeArr
      tertiaryType
      tertiaryTypeArr
      executionType
      executiontemplateid
      arguments
      lastRuntime
      nextRuntime
      tags
      createdAt
      updatedAt
    }
  }
`;
