import React from 'react'
// import ReactDOM from 'react-dom';
import { API, graphqlOperation } from 'aws-amplify'
// import { connect } from 'react-redux'
import classnames from "classnames";
import { connect } from 'react-redux'

// import { onCreateReqHistory } from './../../graphql/subscriptions'
import { updateComputeTemplate } from '../graphql/mutations'
import { listComputeTypes } from '../graphql/queries'
import { 
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Container,
    Button,
    Input,
    Col,
    Collapse,
    Dropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Table,
} from "reactstrap";


class EditComputeTemplateForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            id: '',
            name: '',
            dropdownOpen: false,
            computeTypes: '',
            computeTypeNavPills: -1,
            s3key: '',
            arguments: '',
            computeType: {name: 'Select Compute Type'},
            maxruntime: '',
            instanceSize: '',
            firstload: true
        }
        this.getComputeTypes()



        
    }

    toggleTypeDropdown = () => {
        if(this.state.dropdownOpen){
            this.setState({dropdownOpen: false})
        }
        else{
            this.setState({dropdownOpen: true})
        }
        
    }

    handleNameChange = event => {
        this.setState({name: event.target.value})
        console.log(event)
    }

    handleS3keyChange = event => {
        this.setState({s3key: event.target.value})
        console.log(event.target.value)
    }

    handleArgsChange = event => {
        this.setState({arguments: event.target.value})
        console.log(event.target.value)
    }

    handleTypeChange = event => {
        this.setState({type: event.target.value})
        console.log(event.target.value)
    }

    handleMaxRTChange = event => {
        this.setState({maxruntime: event.target.value})
        console.log(event.target.value)
    }



    onButtonClick = async() => {
        
        const values = {
            id: this.state.id,
            name: this.state.name,
            computeType: this.state.computeType['id'],
            s3key: this.state.s3key,
            arguments: this.state.arguments,
            type: this.state.type,
            maxRunTime: this.state.maxruntime
        }
        
        console.log('Submitted Values', values)
        console.log(this.state)
        const results = await API.graphql(graphqlOperation(updateComputeTemplate, {input: values}))
    }

    getComputeTypes = async() => {
        let ComputeTypes = await API.graphql(graphqlOperation(listComputeTypes))
        let computeTypes = ComputeTypes.data.listComputeTypes.items
        // console.log(computeTypes)

        if(this.state.firstload){
            let count = 0
            computeTypes.forEach( ct => {
                // console.log(ct['id'])
                if(ct['id'] === this.props.selectedTemplate['selectedTemplate']['computeType']){
                    this.setState({reqNavPills: count});
                    this.setState({computeType: ct});
                }
                count = count + 1
            });
            this.setState({firstload: false})
        }

        this.setState({id: this.props.selectedTemplate['selectedTemplate']['id']})
        this.setState({name: this.props.selectedTemplate['selectedTemplate']['name']})
        this.setState({computeTypeid: this.props.selectedTemplate['selectedTemplate']['computeType']})
        this.setState({s3key: this.props.selectedTemplate['selectedTemplate']['s3key']})
        this.setState({arguments: this.props.selectedTemplate['selectedTemplate']['arguments']})
        this.setState({instanceSize: this.props.selectedTemplate['selectedTemplate']['instanceSize']})
        this.setState({type: this.props.selectedTemplate['selectedTemplate']['type']})
        this.setState({maxruntime: parseInt(this.props.selectedTemplate['selectedTemplate']['maxRunTime'])})
        

        this.setState({
            computeTypes: computeTypes.map((computeType, index) => (
                        <DropdownItem key={computeType.id ? computeType.id : index}
                            aria-selected={this.state.computeTypeNavPills === index}
                            className={classnames("mb-sm-3 mb-md-0", {
                                active: this.state.reqNavPills === index
                            })}
                            onClick={e => this.setSelected(computeType, index)}
                            role="tab"
                            >
                            {computeType.name}
                            {/* </a> */}
                        </DropdownItem>
                ))
            });

        
        // let computeType = [...computeTypes.filter( dataVers => dataVers.status === 'Active' )]
        // console.log(computeTypes)



        // let computeType= = [...computeTypes.filter( dataVers => dataVers.status === 'Active' )]
        // this.setState({reqNavPills: CTindex});

        // const selectedTemplate = this.props.selectedTemplate
        // console.log(selectedTemplate['selectedTemplate']['id'])

    }

    setSelected = async(computeType, index) => {
        // console.log(computeType)
        this.setState({reqNavPills: index})
        this.setState({computeType: computeType})
        this.getComputeTypes()

        // console.log(response)
    }

    // componentDidMount = () => {

    //     console.log(this.props.selectedTemplate)
    // }


    
    
    render() {
        // console.log(this.state)
        return(
            <>
                <Container>
                    
                    <Row>
                        <Col>
                            <Input placeholder="Name" required="true" type="text" value={this.state.name} onChange={this.handleNameChange} /> 
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Input placeholder="Script S3 Key" type="text" value={this.state.s3key} onChange={this.handleS3keyChange} /> 
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Input placeholder="Script, Args" type="text" value={this.state.arguments} onChange={this.handleArgsChange} /> 
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Input placeholder="Script Type" type="text" value={this.state.type} onChange={this.handleTypeChange} /> 
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleTypeDropdown}>
                                <DropdownToggle caret>
                                    {this.state.computeType.name}
                                </DropdownToggle>
                                <DropdownMenu right>
                                    {this.state.computeTypes}
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                        <Col>
                            <Input placeholder="Max Runtime (Minutes)" required="true" type="number" value={this.state.maxruntime} onChange={this.handleMaxRTChange} /> 
                        </Col>

                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Button className="btn btn-outline-danger" onClick={ e => this.onButtonClick(  ) }>Submit</Button>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
    

}

// export default RunRequestBtn;





const mapStateToProps = state => {
    return {
        selectedTemplate: state.selectedTemplate
    };
}

// const mapDispatchToProps = dispatch => {
    
//     return {
//         onUpdateReq: (event) => {
//             dispatch({type: 'UPDATESELECTEDQUERYSTATUS', 
//                 currentQueryStatus: 'Active', 
//             })
//         }
        
        
//     }
// }

export default connect(mapStateToProps)(EditComputeTemplateForm);
// export default EditComputeTemplateForm