/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://3gmkdx4nvng4lcbjzhhstxxjwm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-hsglq7t6tvf55mahoenk5hn2k4",
    "aws_cognito_identity_pool_id": "us-east-1:6c2d5996-6005-4d76-aa54-7716e21b3d4e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_6SIdJR8oo",
    "aws_user_pools_web_client_id": "6sk3us8sr0hem57l98dovl4vcj",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "mooserun-storage143931-livethree",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
