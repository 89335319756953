/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateComputeType = /* GraphQL */ `
  subscription OnCreateComputeType {
    onCreateComputeType {
      id
      name
      type
      computeResource
      computeSize
      sqsResource
      snsResource
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateComputeType = /* GraphQL */ `
  subscription OnUpdateComputeType {
    onUpdateComputeType {
      id
      name
      type
      computeResource
      computeSize
      sqsResource
      snsResource
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteComputeType = /* GraphQL */ `
  subscription OnDeleteComputeType {
    onDeleteComputeType {
      id
      name
      type
      computeResource
      computeSize
      sqsResource
      snsResource
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onCreateComputeTemplate = /* GraphQL */ `
  subscription OnCreateComputeTemplate {
    onCreateComputeTemplate {
      id
      name
      computeType
      s3key
      arguments
      maxRunTime
      type
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateComputeTemplate = /* GraphQL */ `
  subscription OnUpdateComputeTemplate {
    onUpdateComputeTemplate {
      id
      name
      computeType
      s3key
      arguments
      maxRunTime
      type
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteComputeTemplate = /* GraphQL */ `
  subscription OnDeleteComputeTemplate {
    onDeleteComputeTemplate {
      id
      name
      computeType
      s3key
      arguments
      maxRunTime
      type
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onCreateComputeExecution = /* GraphQL */ `
  subscription OnCreateComputeExecution {
    onCreateComputeExecution {
      id
      computeTemplate
      status
      type
      arguments
      parentType
      parentExId
      taskStr
      logfile
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateComputeExecution = /* GraphQL */ `
  subscription OnUpdateComputeExecution {
    onUpdateComputeExecution {
      id
      computeTemplate
      status
      type
      arguments
      parentType
      parentExId
      taskStr
      logfile
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteComputeExecution = /* GraphQL */ `
  subscription OnDeleteComputeExecution {
    onDeleteComputeExecution {
      id
      computeTemplate
      status
      type
      arguments
      parentType
      parentExId
      taskStr
      logfile
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePost = /* GraphQL */ `
  subscription OnCreatePost {
    onCreatePost {
      id
      subjectTable
      subjectid
      docType
      status
      title
      content
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePost = /* GraphQL */ `
  subscription OnUpdatePost {
    onUpdatePost {
      id
      subjectTable
      subjectid
      docType
      status
      title
      content
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePost = /* GraphQL */ `
  subscription OnDeletePost {
    onDeletePost {
      id
      subjectTable
      subjectid
      docType
      status
      title
      content
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onCreateResource = /* GraphQL */ `
  subscription OnCreateResource {
    onCreateResource {
      id
      name
      status
      computeTypeid
      type
      subtype
      sqsurl
      sqsmaxAttempts
      sqsVisibilityTimeout
      sqsdeadLetterurl
      userData
      arn
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateResource = /* GraphQL */ `
  subscription OnUpdateResource {
    onUpdateResource {
      id
      name
      status
      computeTypeid
      type
      subtype
      sqsurl
      sqsmaxAttempts
      sqsVisibilityTimeout
      sqsdeadLetterurl
      userData
      arn
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteResource = /* GraphQL */ `
  subscription OnDeleteResource {
    onDeleteResource {
      id
      name
      status
      computeTypeid
      type
      subtype
      sqsurl
      sqsmaxAttempts
      sqsVisibilityTimeout
      sqsdeadLetterurl
      userData
      arn
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePipeline = /* GraphQL */ `
  subscription OnCreatePipeline {
    onCreatePipeline {
      id
      name
      status
      tasks
      arguments
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePipeline = /* GraphQL */ `
  subscription OnUpdatePipeline {
    onUpdatePipeline {
      id
      name
      status
      tasks
      arguments
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePipeline = /* GraphQL */ `
  subscription OnDeletePipeline {
    onDeletePipeline {
      id
      name
      status
      tasks
      arguments
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePipelineExecution = /* GraphQL */ `
  subscription OnCreatePipelineExecution {
    onCreatePipelineExecution {
      id
      pipelineid
      name
      status
      parentType
      parentExId
      currentTask
      arguments
      taskStatus
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePipelineExecution = /* GraphQL */ `
  subscription OnUpdatePipelineExecution {
    onUpdatePipelineExecution {
      id
      pipelineid
      name
      status
      parentType
      parentExId
      currentTask
      arguments
      taskStatus
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePipelineExecution = /* GraphQL */ `
  subscription OnDeletePipelineExecution {
    onDeletePipelineExecution {
      id
      pipelineid
      name
      status
      parentType
      parentExId
      currentTask
      arguments
      taskStatus
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePipelineExecutionHistorical = /* GraphQL */ `
  subscription OnCreatePipelineExecutionHistorical {
    onCreatePipelineExecutionHistorical {
      id
      pipelineExid
      pipelineid
      name
      status
      parentType
      parentExId
      currentTask
      arguments
      taskStatus
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePipelineExecutionHistorical = /* GraphQL */ `
  subscription OnUpdatePipelineExecutionHistorical {
    onUpdatePipelineExecutionHistorical {
      id
      pipelineExid
      pipelineid
      name
      status
      parentType
      parentExId
      currentTask
      arguments
      taskStatus
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePipelineExecutionHistorical = /* GraphQL */ `
  subscription OnDeletePipelineExecutionHistorical {
    onDeletePipelineExecutionHistorical {
      id
      pipelineExid
      pipelineid
      name
      status
      parentType
      parentExId
      currentTask
      arguments
      taskStatus
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onCreateScheduledEvent = /* GraphQL */ `
  subscription OnCreateScheduledEvent {
    onCreateScheduledEvent {
      id
      name
      status
      primaryType
      primaryTypeArr
      secondaryType
      secondaryTypeArr
      tertiaryType
      tertiaryTypeArr
      executionType
      executiontemplateid
      arguments
      lastRuntime
      nextRuntime
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateScheduledEvent = /* GraphQL */ `
  subscription OnUpdateScheduledEvent {
    onUpdateScheduledEvent {
      id
      name
      status
      primaryType
      primaryTypeArr
      secondaryType
      secondaryTypeArr
      tertiaryType
      tertiaryTypeArr
      executionType
      executiontemplateid
      arguments
      lastRuntime
      nextRuntime
      tags
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteScheduledEvent = /* GraphQL */ `
  subscription OnDeleteScheduledEvent {
    onDeleteScheduledEvent {
      id
      name
      status
      primaryType
      primaryTypeArr
      secondaryType
      secondaryTypeArr
      tertiaryType
      tertiaryTypeArr
      executionType
      executiontemplateid
      arguments
      lastRuntime
      nextRuntime
      tags
      createdAt
      updatedAt
    }
  }
`;
