const initialState = {
    selectedTemplate: '',
    selectedPipeline: ''
}
const reducer = ( state = initialState , action) => {
    if(action.type === 'UPDATELISTQUERYITEMS'){
        console.log('updating store with UPDATELISTQUERYITEMS')
        return{
            selectedTemplate: action,
        }
    }

    if(action.type === 'UPDATESELECTEDPIPELINE'){
        console.log('updating store with UPDATELISTQUERYITEMS')
        return{
            selectedPipeline: action,
        }
    }


    return state;
}

export default reducer;