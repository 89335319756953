import React from 'react'
// import ReactDOM from 'react-dom';
import { listComputeTemplates, listPipelines, getPipeline  } from '../graphql/queries'
import { createPipelineExecution, createPipelineExecutionHistorical, deletePipeline } from '../graphql/mutations'
import * as subscriptions from '../graphql/subscriptions';
import { API, graphqlOperation, Auth } from 'aws-amplify'
import EditPipelineForm from './editPipeline'
// import { connect } from 'react-redux'
// import { withAuthenticator } from '@aws-amplify/ui-react'
import { connect } from 'react-redux'

import classnames from "classnames";
import { 
    Col,
    Row,
    Input,
    ListGroup,
    Button,
    ListGroupItem,
    Container,
    Collapse,
    Card,
    CardBody,
    ModalFooter,
    ModalBody,
    ModalHeader,
    Modal
} from "reactstrap";



class ListPipelines extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            cttasks: [],
            PipelineNavPills: -1,
            TaskNavPills: -1,
            listUsersReqs: [],
            selectedTemplate: [],
            setActive: false,
            radio: -1,
            selectedQueryID: '',
            selectedPipeline: '',
            ComputeTemplates: [],
            navPills: 1,
            ListPipelines: '',
            templateNavPills: -1,
            SelectedTemplateTasks: '',
            selectedTasks: [],
            selectedTask: [],
            TaskData: '',
            arguments: '',
            buttons: '',
            pipelinemodal: false,
            deletemodal: false,
            runmodal: false,
            displayDeleteButton: ''
        }
        this.getAllComputeTemplates()

        const onCreatePipelinesub = API.graphql(
            graphqlOperation(subscriptions.onCreatePipeline)
        ).subscribe({
            next: ({ provider, value }) => {
                this.displayList()
                console.log({ provider, value })},
            error: error => console.warn(error)
        });

        const onUpdatePipelinesub = API.graphql(
            graphqlOperation(subscriptions.onUpdatePipeline)
        ).subscribe({
            next: ({ provider, value }) => {
                this.updateSelectedPipeline()
                this.setState({ pipelinemodal: false  })
                this.setState({ deletemodal: false  })
                this.setState({ runmodal: false  })
                console.log({ provider, value })},
            error: error => console.warn(error)
        });

        const onDeletePipelinesub = API.graphql(
            graphqlOperation(subscriptions.onDeletePipeline)
        ).subscribe({
            next: ({ provider, value }) => {
                this.displayList()
                console.log({ provider, value })},
            error: error => console.warn(error)
        });
    }
    toggleNavs = (e, state, index) => {
        e.preventDefault();
        this.setState({[state]: index});
        this.setSelected(e,index)
        // this.displayList()
    };

    toggleNavPills = (e, state, index) => {
        e.preventDefault();
        this.setState({[state]: index});
        this.displayList()
    };

    setInput(key, value) {
        this.setState([key], value)
    }

    componentDidMount() {
        this.displayList();
    }

    updateSelectedPipeline = async() => {
        const selectedCT = this.state.selectedPipeline
        console.log('SelectedCT', selectedCT)
        let pipelineDataresp = await API.graphql({ query: getPipeline, variables: { id: selectedCT.id }})
        const pipelineData = pipelineDataresp.data
        const pipeline = pipelineData.getPipeline
        console.log(pipeline)
        this.setSelected(pipeline, this.state.PipelineNavPills)
    }


    setSelected = async(ct, index) => {
        // console.log(ct)
        this.props.updateSelectedPipeline(ct)
        this.setState({selectedPipeline: ct})
        this.setState({PipelineNavPills: index});
        this.displayList()
        this.setState({selectedPipelineTasks: ct.tasks})
        this.displaySelectedPipelineTasks(ct.tasks)
        this.setState({TaskData: ''});
        this.setState({ buttons: (<> <Button onDoubleClick={e => this.OpenEditPipelineModal()}><i class="fas fa-edit"></i></Button>
                                    <Button className="btn btn-outline-danger" onClick={ e => this.OpenRunPipelineModal(  ) }>Run</Button>
                                </>)})
        // console.log(response)
    }

    setSelectedTask = async(task, index) => {
        console.log(task)
        this.setState({selectedTask: task})
        this.setState({TaskNavPills: index});

        this.setState({ TaskData: (<>
            <ul>
                <li>Name: {task.name}</li>
                <li>Phase: {task.phasenum}</li>
                <li>TaskNum: {task.taskNum}</li>
                <li>Runtime: {task.maxRunTime}</li>
                <li>Arguments: {task.arguments}</li>
                <li>ComputeType: {task.computeType}</li>
                {/* <li>{task.type}</li>
                <li>{task.tags}</li> */}
            </ul>
        </>)})
        

    }

    handleArgsChange = event => {
        this.setState({arguments: event.target.value})
        console.log(event.target.value)
    }


    displayphase = (tasks, phase) => {
        console.log({tasks})
        let allTasks = []
        tasks.forEach((t, index) => { allTasks.push(JSON.parse(t)) })
        return allTasks.map((task, index) => (
            <ListGroupItem key={task['id'] ? task['id'] : index}
                aria-selected={this.state.TaskNavPills === phase + '-' + index}
                className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.TaskNavPills === phase + '-' + index
                })}
                onClick={e => this.setSelectedTask(task, phase + '-' + index)}
                role="tab"
                >
                {task.name}
            </ListGroupItem>
        ))
    }






    displaySelectedPipelineTasks = (pipelineTasks) => {
        let allTasks = []
        console.log({pipelineTasks})
        this.setState({listTaskLists: pipelineTasks.map((pt, index) => (
                <div class="card" key={index}>
                    <div class="card-header" id={'heading-' + index} >
                        <h5 class="mb-0">
                            Phase {index}
                        </h5>
                    </div>
                    <div class="card-body">
                        {this.displayphase(pt, index)}
                    </div>
                </div>
        ) )
    })
    }



    displayList = async() => {
        let CEData = await API.graphql(graphqlOperation(listPipelines));

        let CEItems = CEData.data.listPipelines.items;

        let CEdata1 = CEData.data.listPipelines
        // console.log({CEdata1})
        // console.log(CEItems.length)
        // console.log({CEItems})
        while(CEdata1.nextToken != null ){
            let nextToken = CEdata1.nextToken;
            let CEData = await API.graphql({ query: listPipelines, variables: { limit: 100, nextToken }});
            let CEdata2 = {}
            CEdata2 = CEData.data.listPipelines
            CEItems = CEItems.concat(CEdata2.items)
        }
        console.log(CEItems.length)
        console.log({CEItems})


        this.setState({ListPipelines: CEItems.map((ct, index) => (
                <ListGroupItem key={ct.id ? ct.id : index}
                    aria-selected={this.state.PipelineNavPills === index}
                    className={classnames("mb-sm-3 mb-md-0", {
                        active: this.state.PipelineNavPills === index
                    })}
                    onClick={e => this.setSelected(ct, index)}
                    role="tab"
                    >
                    {ct.name}
                </ListGroupItem>                        
            ))
        });
    }

    getAllComputeTemplates = async() => {
        let CEData = await API.graphql(graphqlOperation(listComputeTemplates));
        let CEItems = CEData.data.listComputeTemplates.items;
        this.setState({allComputeTemplates: CEItems})
    }

    updateTaskStatus = (pipelineTasks) => {
        let allphases = []
        pipelineTasks.forEach((phase, phaseindex) => {
            let allTasks = []
            phase.forEach( (t, index) => {
                let task = JSON.parse(t)
                task.Status = 'Waiting'
                allTasks.push(JSON.stringify(task))
            })
            allphases.push(allTasks)
        })
        return allphases
    }


    onRunButtonClick = async() => {
        const token = await Auth.currentAuthenticatedUser()
        const username = token.username
        console.log(this.state.selectedPipeline)
        this.setState({runmodal: false});


        const values = {
            name: this.state.selectedPipeline.name,
            pipelineid: this.state.selectedPipeline.id,
            status: 'New',
            taskStatus: this.updateTaskStatus(this.state.selectedPipeline.tasks),
            arguments: this.state.arguments,
            parentType: 'Website',
            parentExId: username

        }
        console.log(values)
        // this.updateTaskStatus(this.state.selectedPipeline.tasks)
        try {
        const cperesponse = await API.graphql(graphqlOperation(createPipelineExecution, {input: values}))
        // console.log({response})
        let execution = cperesponse.data.createPipelineExecution
        // console.log({execution})
        execution['pipelineExid'] = execution['id']
        delete execution['id']
        delete execution['createdAt']
        delete execution['updatedAt']
        delete execution['owner']
        // console.log({execution})
        const cpehresponse = await API.graphql(graphqlOperation(createPipelineExecutionHistorical, {input: execution}))
        // console.log({cpehresponse})
        } catch (err) {
            console.log(err);
        }
    }

    OpenEditPipelineModal = () => {
        this.setState({pipelinemodal: true});
        this.setState({ displayDeleteButton: (<><Button className="btn btn-outline-danger" onClick={ e => this.openDeleteModal(  ) }>Delete</Button></>)})
        // this.setState({arguments: this.state.selectedTemplate['arguments']})
    }

    CloseEditPipelineModal = () => {
        // console.log('Close Modal')
        // this.getModal(false);
        this.setState({pipelinemodal: false});
    }

    OpenRunPipelineModal = () => {
        this.setState({runmodal: true});
        this.setState({arguments: this.state.selectedTemplate['arguments']})

    }

    CloseRunPipelineModal = () => {
        // console.log('Close Modal')
        // this.getModal(false);
        this.setState({runmodal: false});
    }


    openDeleteModal = () => {
        this.setState({deletemodal: true});
    }

    cancelDeleteButton = () => {
        this.setState({deletemodal: false});
    }

    submitDeleteToGQL = async() => {
        this.setState({deletemodal: false});
        this.setState({pipelinemodal: false});
        const values = {
            id: this.state.selectedPipeline['id'],
        }
        console.log('Submitted Values', values)
        await API.graphql(graphqlOperation(deletePipeline, {input: values}))
        // window.location.reload(false);
    }

    render  () {
            return( <>
                <Modal backdrop="static" isOpen={this.state.runmodal}>
                    <ModalHeader close={<button className="close" onClick={e => this.CloseRunPipelineModal()}>×</button>}>Confirm</ModalHeader>
                    <ModalBody>
                        <Input placeholder="Script, Args" type="text" value={this.state.arguments} onChange={this.handleArgsChange} /> 
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn btn-outline-danger" onDoubleClick={ e => this.onRunButtonClick(  ) }>Run</Button>
                        <Button color="secondary" onClick={ e => this.CloseRunPipelineModal(  ) }>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.deletemodal}>
                    <ModalHeader close={<button className="close" onClick={e => this.cancelDeleteButton()}>×</button>}>Confirm Delete</ModalHeader>
                    <ModalBody>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onDoubleClick={e => this.submitDeleteToGQL()}>Delete</Button>{' '}
                        <Button color="secondary" onClick={ e => this.cancelDeleteButton(  ) }>Cancel</Button>
                    </ModalFooter>
                </Modal>

                <Modal backdrop="static" isOpen={this.state.pipelinemodal} className="modal-xl modal-dialog">
                    <ModalHeader close={<button className="close" onClick={e => this.CloseEditPipelineModal()}>×</button>}>Edit Pipeline</ModalHeader>
                    <ModalBody>
                        <EditPipelineForm />
                        {/* {this.state.executionTemplateModal} */}
                        {/* <Input placeholder="Script, Args" type="text" value={this.state.arguments} onChange={this.handleRunArgsChange} />  */}
                    </ModalBody>
                    <ModalFooter>
                        {this.state.displayDeleteButton}
                        {/* {this.state.displayRunButton} */}
                        <Button color="secondary" onClick={ e => this.CloseEditPipelineModal(  ) }>Close</Button>
                    </ModalFooter>
                </Modal>
                <Container>
                    <Row>
                        <Col md="3">
                            <ListGroup>
                                {this.state.ListPipelines}
                            </ListGroup>
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                {this.state.buttons}

                                    
                                </Col>
                                <Col>
                                    <h4>Name: {this.state.selectedPipeline.name}</h4>
                                </Col>
                                <Col>
                                    <h4>Status: {this.state.selectedPipeline.status}</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.state.listTaskLists}
                                </Col>
                                <Col>
                                    {this.state.TaskData}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}


// export default (ListPipelines);
const mapStateToProps = state => {
    // console.log({state})
    return {
        pipeline: state.selectedPipeline,
        // UsersQuerys: state.UsersQuerys, // replacing reqs 
    };
}

const mapDispatchToProps = dispatch => {
    
    return {
        updateSelectedPipeline: (event) => {
            console.log('updateSelectedPipeline to props event: ', event)
            dispatch({type: 'UPDATESELECTEDPIPELINE', 
                selectedPipeline: event,
            })
            
        },


        
        
    }
}

export default connect( mapStateToProps, mapDispatchToProps)(ListPipelines);